import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  ForwardedRef,
  RefObject,
} from "react";

import {
  convertToNumber,
  convertToString as _convertToString,
  validateIntegerValue,
} from "./number.utils";

interface IntegerInputProps {
  defaultValue: number | undefined;
  isNegative?: boolean;
  onChange: (value: number | undefined) => void;
  onBlur: (value: number | undefined) => void;
  min?: number;
  max?: number;
  className?: string;
  [key: string]: any;
}

function convertToNumberWithMin(value, separator, min) {
  const v = convertToNumber(value, separator);

  if (min != null && (v == null || v < min)) return min;

  return v;
}

const IntegerInput = forwardRef(
  (
    {
      defaultValue,
      onChange: _onChange = () => {},
      onBlur: _onBlur = () => {},
      max,
      min,
      isNegative = false,
      className,
      ...props
    }: IntegerInputProps,
    ref: ForwardedRef<any>
  ) => {
    const convertToString = React.useCallback((v: number | undefined) => {
      return _convertToString(v, undefined, true, 0);
    }, []);

    const [curValue, changeCurValue] = React.useState(
      convertToString(defaultValue)
    );

    const input = useRef() as RefObject<HTMLInputElement>;

    useImperativeHandle(ref, () => ({
      value: curValue,
      focus: () => input.current?.focus(),
      blur: () => input.current?.focus(),
    }));

    const onChange = React.useCallback(
      (e: any) => {
        const v = validateIntegerValue(e.target.value, isNegative);

        const intVal = convertToNumber(v, undefined);
        if (intVal != null && max != null && intVal > max) {
          changeCurValue(convertToString(max));
        } else {
          changeCurValue(v);
        }
      },
      [convertToString, isNegative, max]
    );

    return (
      <input
        className={["integer-input", className].filter((e) => e).join(" ")}
        type="text"
        value={curValue}
        onChange={(e) => {
          onChange(e);
          _onChange(convertToNumber(curValue, undefined));
        }}
        onBlur={() => {
          const tv = convertToNumberWithMin(curValue, undefined, min);

          _onChange(tv);
          changeCurValue(convertToString(tv));
          _onBlur(tv);
        }}
        ref={input}
        {...props}
      />
    );
  }
);

export default IntegerInput;
