import React, { RefObject } from "react";
import { ReactComponent as ArrowUpSVG } from "../../assets/arrow-up.svg";
import { ReactComponent as ArrowDownSVG } from "../../assets/arrow-down.svg";

import "./dropdown.scss";

export type DropdownOptions = {
  value: string | number | undefined;
  label: string | JSX.Element;
}[];

function Dropdown({
  value,
  onChange,
  data,
  disabled = false,
}: {
  value: any;
  onChange: Function;
  data: any[];
  disabled?: boolean;
}) {
  const [hasOpen, changeOpen] = React.useState(false);
  const dropdownRef = React.useRef() as RefObject<HTMLDivElement>;
  const containerRef = React.useRef() as RefObject<HTMLDivElement>;

  const currentValue = React.useMemo(
    () => data.find((d) => d.value === value) ?? data[0],
    [data, value]
  );

  const hideOutside = React.useCallback(
    (e: any) => {
      e.preventDefault();
      const node = dropdownRef.current;
      if (hasOpen && node && !node.contains(e.target)) {
        changeOpen(false);
      }
    },
    [hasOpen]
  );

  React.useEffect(() => {
    const scrollNode = dropdownRef.current?.closest(".scroll");
    const dropdownNode = dropdownRef.current;
    const containerNode = containerRef.current;

    if (hasOpen && containerNode && dropdownNode) {
      const dropdownCoords = dropdownNode.getBoundingClientRect();

      let postitionTop = dropdownCoords.top + dropdownNode.clientHeight + "px";

      if (
        dropdownCoords.top +
          dropdownNode.clientHeight +
          containerNode.clientHeight >
        window.innerHeight
      ) {
        postitionTop = dropdownCoords.top - containerNode.clientHeight + "px";
      }

      const postitionLeft = dropdownCoords.left + "px";

      containerNode.style.top = postitionTop;
      containerNode.style.left = postitionLeft;
      containerNode.style.width = dropdownNode.offsetWidth + "px";
      containerNode.style.visibility = "visible";
    }

    const scrollNodeListener = () => {
      changeOpen(false);
    };

    scrollNode?.addEventListener("scroll", scrollNodeListener);
    window.addEventListener("resize", scrollNodeListener);
    document.addEventListener("mouseup", hideOutside);

    return () => {
      document.removeEventListener("mouseup", hideOutside);
      scrollNode?.removeEventListener("scroll", scrollNodeListener);
      window.removeEventListener("resize", scrollNodeListener);
    };
  });

  if (!data) return null;

  return (
    <div className="dropdown" ref={dropdownRef}>
      <button
        type="button"
        className={`dropdown-value`}
        disabled={disabled}
        onClick={(e) => {
          e.preventDefault();
          if (!disabled) changeOpen(!hasOpen);
        }}
      >
        <div className="dropdown-text">
          {currentValue ? currentValue.label : null}
        </div>
        <div className="dropdown-arrow">
          <i>{hasOpen ? <ArrowUpSVG /> : <ArrowDownSVG />}</i>
        </div>
      </button>
      {hasOpen && (
        <div ref={containerRef} className="dropdown-container">
          {data.map((item: any, idx) => (
            <button
              key={idx}
              type="button"
              className={[
                "dropdown-item",
                value === item.value ? "active" : undefined,
              ]
                .filter((e) => e)
                .join(" ")}
              onClick={(e) => {
                e.preventDefault();
                onChange(item.value);
                changeOpen(false);
              }}
            >
              {item.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

export default Dropdown;
