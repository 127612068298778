import React from "react";
import usePolling from "../../../../../hooks/usePolling";
import HistoryChart from "./HistoryChart";
import calcApi from "../../../../../service/calcApi";
import WaterFilterInterface from "../../../../../store/interface/WaterFilterInterface";
import { useUserStore } from "../../../../../store/UserStore";

function HistoryLine({
  planId,
  waterFilter,
  active,
}: {
  planId: string | undefined;
  waterFilter: WaterFilterInterface;
  active: boolean;
}) {
  const userStore = useUserStore();
  const [history, changeHistory] = React.useState<any>([]);

  const fetchWaterFilterHistory = React.useMemo(
    () =>
      !waterFilter?.is_fill_properties && waterFilter?.serial_number != null
        ? async () => {
            if (waterFilter.serial_number == null) return undefined;

            const data = await calcApi.fetchWaterFilterHistory(
              planId,
              waterFilter.serial_number,
              userStore?.requestLogin
            );
            changeHistory(
              (data ?? []).map((d) => ({
                ...d,
                date: d.date ? +new Date(d.date) : undefined,
              }))
            );
          }
        : undefined,
    [
      planId,
      userStore?.requestLogin,
      waterFilter?.is_fill_properties,
      waterFilter.serial_number,
    ]
  );

  usePolling(fetchWaterFilterHistory, 60 * 1000);

  const pressureDropLimit = React.useMemo(() => {
    return waterFilter.pressure_drop_limit ?? 0.2;
  }, [waterFilter?.pressure_drop_limit]);

  return (
    <div className="history-line">
      <div className="separator" style={{ borderColor: "transparent" }} />
      <HistoryChart
        style={{ minHeight: 450 }}
        data={history}
        pressureDropLimit={pressureDropLimit}
      />
    </div>
  );
}

export default HistoryLine;
