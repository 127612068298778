import React from "react";
import PropertiesWithButton from "../PropertiesWithButton";
import PropertiesWithToggle from "../PropertiesWithToggle";
import { ControllerPropertiesContext } from "./ControllerPropertiesContext";
import { useIntl } from "react-intl";
import { DialogContext } from "../../../popup/dialog/DialogContext";

function InterruptForm({ disabled }: { disabled: boolean }) {
  const { showDialog } = React.useContext(DialogContext);

  const {
    activeController,
    changeActiveController,
    hasSensorAssignmentForm,
    toggleSensorAssignmentForm,
    saveHydrawiseSettings,
    circuitAssigned,
    locationName,
    noIrrigationTimeStart,
    noIrrigationTimeStop,
    noIrrigationDays,
    noIrrigationTemperatureLimit,
    noIrrigationTemperatureEnabled,
    noIrrigationWindSpeedLimit,
    noIrrigationWindSpeedEnabled,
  } = React.useContext(ControllerPropertiesContext);

  const { formatMessage } = useIntl();

  return (
    <>
      <PropertiesWithToggle
        disabled={disabled}
        className="properties-item"
        title={formatMessage({
          id: "controller.properties.interrupt.activate",
        })}
        value={activeController === "interrupt"}
        onChange={async (value: boolean) => {
          if (activeController === "plant-et") {
            const result = await showDialog(
              formatMessage({
                id: "controller.properties.controller_activation.popup.text",
              }),
              formatMessage({
                id: "controller.properties.controller_activation.popup.title",
              })
            );

            if (!result) return;
          }

          const interruptEnabled = value ? "interrupt" : undefined;
          changeActiveController(interruptEnabled);
          saveHydrawiseSettings(
            interruptEnabled,
            circuitAssigned,
            locationName,
            noIrrigationTimeStart,
            noIrrigationTimeStop,
            noIrrigationDays,
            noIrrigationTemperatureLimit,
            noIrrigationTemperatureEnabled,
            noIrrigationWindSpeedLimit,
            noIrrigationWindSpeedEnabled
          );
        }}
      />

      {hasSensorAssignmentForm ? (
        <PropertiesWithButton
          disabled={disabled}
          className="properties-item active"
          title={formatMessage({
            id: "controller.properties.interrupt.assignment.title",
          })}
          buttonTitle={formatMessage({
            id: "controller.properties.interrupt.assignment.active",
          })}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            toggleSensorAssignmentForm();
          }}
        />
      ) : (
        <PropertiesWithButton
          disabled={disabled}
          className="properties-item"
          title={formatMessage({
            id: "controller.properties.interrupt.assignment.title",
          })}
          buttonTitle={formatMessage({
            id: "controller.properties.interrupt.assignment.inactive",
          })}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            toggleSensorAssignmentForm();
          }}
        />
      )}
    </>
  );
}

export default InterruptForm;
