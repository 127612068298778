import React, { RefObject } from "react";
import { useIntl } from "react-intl";

import { ReactComponent as ArrowDownSVG } from "../../assets/arrow-down.svg";
import { ReactComponent as ArrowUpSVG } from "../../assets/arrow-up.svg";
import { ReactComponent as CheckedSVG } from "../../assets/galka.svg";

import "./multiselect-dropdown.scss";

export type MultiSelectDropdownOptions = {
  value: string | number | undefined;
  label: string | JSX.Element;
}[];

function MultiSelectDropdown({
  selectedValues,
  onChange,
  data,
  disabled = false,
}: {
  selectedValues: number[];
  onChange: Function;
  data: any[];
  disabled?: boolean;
}) {
  const { formatMessage } = useIntl();

  const [hasOpen, changeOpen] = React.useState(false);
  const dropdownRef = React.useRef() as RefObject<HTMLDivElement>;
  const containerRef = React.useRef() as RefObject<HTMLDivElement>;

  const currentValues = React.useMemo(() => {
    return data.filter((_, index) => selectedValues.includes(index)) ?? [];
  }, [data, selectedValues]);

  const hideOutside = React.useCallback(
    (e: any) => {
      e.preventDefault();
      const node = dropdownRef.current;
      if (hasOpen && node && !node.contains(e.target)) {
        changeOpen(false);
      }
    },
    [hasOpen]
  );

  React.useEffect(() => {
    const scrollNode = dropdownRef.current?.closest(".scroll");
    const dropdownNode = dropdownRef.current;
    const containerNode = containerRef.current;

    if (hasOpen && containerNode && dropdownNode) {
      const dropdownCoords = dropdownNode.getBoundingClientRect();

      let postitionTop = dropdownCoords.top + dropdownNode.clientHeight + "px";

      if (
        dropdownCoords.top +
          dropdownNode.clientHeight +
          containerNode.clientHeight >
        window.innerHeight
      ) {
        postitionTop = dropdownCoords.top - containerNode.clientHeight + "px";
      }

      containerNode.style.top = postitionTop;
      containerNode.style.width = dropdownNode.offsetWidth + "px";
      containerNode.style.visibility = "visible";
    }

    const scrollNodeListener = () => {
      changeOpen(false);
    };

    scrollNode?.addEventListener("scroll", scrollNodeListener);
    window.addEventListener("resize", scrollNodeListener);
    document.addEventListener("mouseup", hideOutside);

    return () => {
      document.removeEventListener("mouseup", hideOutside);
      scrollNode?.removeEventListener("scroll", scrollNodeListener);
      window.removeEventListener("resize", scrollNodeListener);
    };
  });

  if (!data) return null;

  return (
    <div className="multiselect-dropdown" ref={dropdownRef}>
      <button
        type="button"
        className={`multiselect-dropdown-value`}
        disabled={disabled}
        onClick={(e) => {
          e.preventDefault();
          if (!disabled) changeOpen(!hasOpen);
        }}
      >
        <div className="multiselect-dropdown-text">
          {currentValues && currentValues.length > 0
            ? currentValues.map((v) => v.label.slice(0, 3)).join(", ")
            : formatMessage({ id: "common.none" })}
        </div>
        <div className="multiselect-dropdown-arrow">
          <i>{hasOpen ? <ArrowUpSVG /> : <ArrowDownSVG />}</i>
        </div>
      </button>
      {hasOpen && (
        <div ref={containerRef} className="multiselect-dropdown-container">
          {data.map((item: any, idx) => {
            const itemSelected = selectedValues.includes(idx);
            return (
              <div key={idx} className="multiselect-dropdown-item">
                {item && <div>{item.label}</div>}
                <button
                  type="button"
                  onClick={() => {
                    const selectedIdx = selectedValues.findIndex(
                      (v) => v === idx
                    );
                    if (selectedIdx > -1) {
                      selectedValues.splice(selectedIdx, 1);
                    } else {
                      selectedValues.push(idx);
                    }

                    onChange(selectedValues);
                  }}
                  className={`checkbox ${itemSelected ? "checked" : ""} ${
                    disabled ? "disabled" : ""
                  }`}
                >
                  {itemSelected ? <CheckedSVG /> : ""}
                </button>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default MultiSelectDropdown;
