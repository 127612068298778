import React from "react";

import "./toggle.scss";

function Toggle({
  value = false,
  onChange = () => {},
  disabled = false,
}: {
  value?: boolean;
  onChange: Function;
  disabled?: boolean;
}) {
  return (
    <div
      className={[
        "toggle",
        value ? "selected" : undefined,
        disabled ? "disabled" : "",
      ]
        .filter((e) => e)
        .join(" ")}
      onClick={() => {
        if (disabled) return;
        onChange(!value);
      }}
    >
      <div className="toggle-button"></div>
    </div>
  );
}

export default Toggle;
