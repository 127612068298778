import React from "react";

type Tab = {
  label: string;
  Status?: React.ComponentType<any>;
  value: string;
  disabled?: boolean;
  Component: React.ComponentType<any>;
};
function TabsNavigation({
  disabled,
  data,
  value,
  onChange,
  tabComponentProps,
}: Readonly<{
  disabled: boolean;
  data: Tab[];
  value: string;
  onChange: (value: string) => void;
  tabComponentProps?: any;
}>) {
  const SelectedComponent = data.find((d) => d.value === value)?.Component;

  return (
    <div className="tabs-navigation">
      <div className="tabs-navigation-buttons">
        {data.map((item) => (
          <button
            key={`tab-${item.value}`}
            className={value === item.value ? "active" : undefined}
            disabled={disabled || item.disabled}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onChange(item.value);
            }}
          >
            {item.label}
            {item.Status && <item.Status />}
          </button>
        ))}
      </div>
      <div className="tabs-navigation-content">
        {SelectedComponent && (
          <SelectedComponent disabled={disabled} {...tabComponentProps} />
        )}
      </div>
    </div>
  );
}

export default TabsNavigation;
