import React from "react";

function useFetch(callback: Function | undefined) {
  const timerRef = React.useRef() as any;
  const [status, changeStatus] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    if (typeof callback === "function") {
      timerRef.current = setTimeout(async function () {
        changeStatus("pending");
        try {
          await callback();
          changeStatus("done");
        } catch (e) {
          console.error(e);
          changeStatus("error");
        }
      }, 0);
    }

    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback]);

  return status;
}

export default useFetch;
