import { ReactComponent as InformSVG } from "../../../../assets/inform.svg";
import Link from "../../../../components/link/Link";

function PropertiesWithButton({
  onClick,
  title,
  buttonTitle,
  className,
  disabled,
  informURL,
}: {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  title: string;
  buttonTitle: string;
  className?: string;
  disabled?: boolean;
  informURL?: string;
}) {
  return (
    <div className={[className, "with-button"].filter((e) => e).join(" ")}>
      <div
        className={["title", informURL ? "inform" : undefined]
          .filter((e) => e)
          .join(" ")}
      >
        <span>{title}</span>
        {informURL && (
          <Link href={informURL} target="__blank" rel="noopener noreferrer">
            <InformSVG />
          </Link>
        )}
      </div>
      <button disabled={disabled} onClick={onClick}>
        {buttonTitle}
      </button>
    </div>
  );
}

export default PropertiesWithButton;
