import { ReactComponent as CheckedSVG } from "../../assets/galka.svg";

import "./checkbox.scss";

function Checkbox({
  value = false,
  onChange = () => {},
  disabled = false,
  label = undefined,
}: {
  value?: boolean;
  onChange: Function;
  disabled?: boolean;
  label?: string;
}) {
  const handleChange = () => {
    if (!disabled) {
      onChange(!value);
    }
  };
  return (
    <div className="checkbox-wrap">
      <button
        type="button"
        onClick={handleChange}
        className={`checkbox ${value ? "checked" : ""} ${
          disabled ? "disabled" : ""
        }`}
      >
        {value ? <CheckedSVG /> : ""}
      </button>
      {label && <label onClick={handleChange}>{label}</label>}
    </div>
  );
}

export default Checkbox;
