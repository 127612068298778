import React from "react";

import GridTool from "./GridTool";
import CenterTool from "./CenterTool";
import HandTool from "./HandTool";
import ZoomTool from "./ZoomTool";

import { debounce } from "lodash";
import { useIntl } from "react-intl";
import PropertiesWithToggle from "../properties/PropertiesWithToggle";
import { useUserStore } from "../../../../store/UserStore";
import { usePlanStore } from "../../../../store/PlanStore";
import calcApi from "../../../../service/calcApi";
import user from "../../../../mock/user";

const Tools = () => {
  const userStore = useUserStore();
  const {
    planId,
    soilNotificationEnabled,
    changeSoilNotificationEnabledValue,
  } = usePlanStore();
  const { formatMessage, locale } = useIntl();

  const saveNotificationProperties = React.useCallback(
    async (
      planId: string,
      email: string | undefined,
      locale: string | undefined,
      active: boolean
    ) => {
      if (planId == null) {
        throw new Error(`Plan id is empty`);
      }
      await calcApi.saveNotificationProperties(
        planId,
        email,
        locale,
        active,
        userStore?.requestLogin
      );
      changeSoilNotificationEnabledValue((v) => !v);
    },
    [changeSoilNotificationEnabledValue, userStore?.requestLogin]
  );

  const saveSoilNotificationToogle = React.useMemo(
    () =>
      debounce((value) => {
        if (planId == null) return;

        saveNotificationProperties(
          planId,
          userStore?.user?.email,
          locale,
          value
        );
      }, 300),
    [locale, planId, saveNotificationProperties, userStore?.user?.email]
  );

  return (
    <div className="plan-tools">
      {[GridTool, CenterTool, HandTool].map(
        (ToolComponent: React.ComponentType<any>, idx: number) => (
          <React.Fragment key={idx}>
            {idx > 0 && <div className="tool-separator" />}
            <ToolComponent />
          </React.Fragment>
        )
      )}

      <ZoomTool />
      <div className="tool-separator" />
      <PropertiesWithToggle
        className="properties-item"
        title={formatMessage({
          id: "sensor.properties.soil_notification.title",
        })}
        tooltip={formatMessage({
          id: "sensor.properties.soil_notification.tooltip",
        })}
        value={soilNotificationEnabled} //soil_notification_enabled}
        onChange={saveSoilNotificationToogle} //changeSoilNotificationEnabled}
        disabled={user?.email == null}
        tooltipType="top"
      />
    </div>
  );
};

export default Tools;
