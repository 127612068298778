import React, { CSSProperties, MutableRefObject, RefObject } from "react";

import * as Highcharts from "highcharts/highstock";
import { useIntl } from "react-intl";
import {
  highchartAxisStyled,
  highchartStyled,
} from "../../../../../utils/highchart.styled";
import useFormatter from "../../../../../hooks/useFormatter";

interface DataItem {
  date: number;
  weight: number;
}

function displayHighchart(
  containerId: Highcharts.HTMLDOMElement,
  series: any,
  plotBands: { from: number; to: number; color: string }[],
  rangeButtonsTitle: {
    "1_week": string;
    "1_month": string;
    "3_month": string;
    "6_month": string;
  },
  pressureChartTitle: string,
  lang: any | undefined
) {
  Highcharts.setOptions({
    lang: {
      rangeSelectorFrom: "",
      rangeSelectorTo: ">",
      decimalPoint: lang?.decimalPoint,
    },
  });

  // create the chart
  return Highcharts.stockChart(containerId, {
    ...highchartStyled,
    accessibility: {
      ...highchartStyled.accessibility,
      enabled: false,
    },

    chart: {
      ...highchartStyled.chart,
      animation: true,
      marginLeft: 60,
      marginRight: 60,
    },
    time: {
      timezone: lang.timezone,
      useUTC: false,
    },
    rangeSelector: {
      ...highchartStyled.rangeSelector,
      buttons: [
        {
          type: "week",
          count: 1,
          text: rangeButtonsTitle["1_week"],
          dataGrouping: {
            forced: true,
            units: [
              ["minute", [1, 5, 10, 20, 30]],
              ["hour", [1, 2, 3, 4, 6, 8, 12]],
              ["day", [1]],
            ],
          },
        },
        {
          type: "month",
          count: 1,
          text: rangeButtonsTitle["1_month"],
          dataGrouping: {
            forced: true,
            units: [
              ["day", [1]],
              ["week", [1]],
            ],
          },
        },
        {
          type: "month",
          count: 3,
          text: rangeButtonsTitle["3_month"],
          dataGrouping: {
            forced: true,
            units: [["week", [1]]],
          },
        },
        {
          type: "month",
          count: 6,
          text: rangeButtonsTitle["6_month"],
          dataGrouping: {
            forced: true,
            units: [["month", [1]]],
          },
        },
      ],
      selected: 0,
      allButtonsEnabled: true,
      y: 0,
      inputPosition: {
        y: -12,
      },
      buttonPosition: {
        y: -12,
      },
    },
    xAxis: [
      {
        ...highchartAxisStyled,
        type: "datetime",
        ordinal: false,
        top: "0%",
        offset: 0,
        tickPixelInterval: 200,
        labels: {
          ...highchartAxisStyled.labels,
          rotation: 0,
          allowOverlap: false,
        },
        plotBands,
      },
      {
        ...highchartAxisStyled,
        lineColor: "transparent",
        top: "-100%",
        offset: -0,
        title: {
          ...highchartAxisStyled.title,
          text: pressureChartTitle,
          style: {
            ...highchartAxisStyled.title.style,
            fontSize: "1.125rem",
            fontWeight: "bold",
          },
          y: -48,
        },
      },
    ],
    yAxis: [
      {
        ...highchartAxisStyled,
        title: {
          ...highchartAxisStyled.title,
          text: "kg",
          align: "high",
          textAlign: "right",
          rotation: 0,
          offset: 10,
          y: -15,
        },
        gridLineColor: "#9ea1a2",
        minorGridLineColor: "#9ea1a2",
        opposite: false,
        labels: {
          ...highchartAxisStyled.labels,
          y: 5,
          enabled: true,
          step: 1,
          format: "{value:.2f}",
          style: {
            color: "#f5f5f5",
          },
        },
        showLastLabel: true,
      },
    ],
    legend: {
      ...highchartStyled.legend,
      enabled: false,
    },
    tooltip: {
      ...highchartStyled.tooltip,
      split: true,
      valueDecimals: 2,
    },
    series,
  });
}

function Highchart({
  style,
  data,
  minWeightLimit,
}: {
  style?: CSSProperties;
  data: DataItem[];
  minWeightLimit: number;
}) {
  const { formatMessage, timeZone } = useIntl();
  const { decimalSeparator } = useFormatter();

  const ref = React.useRef() as RefObject<HTMLDivElement>;

  const chartRef = React.useRef(undefined) as MutableRefObject<
    undefined | Highcharts.StockChart
  >;

  const { series, bands } = React.useMemo(() => {
    const { weight } = [...data]
      .sort((a: any, b: any) => a.date - b.date)
      .reduce(
        (acc, { date, weight }) => {
          acc.weight.push([date, weight ? weight : null]);
          return acc;
        },
        {
          weight: [] as any,
        }
      );

    let bands: any[] = [];
    let currentBand;
    for (let value of weight) {
      if (value[1] != null && value[1] < minWeightLimit) {
        currentBand =
          currentBand == null
            ? { from: value[0], to: value[0], color: "rgba(255, 118, 0, 0.54)" }
            : { ...(currentBand ?? {}), to: value[0] };
      } else if (currentBand != null) {
        bands.push(currentBand);
        currentBand = null;
      }
    }

    if (currentBand != null) {
      bands.push(currentBand);
    }

    return {
      bands,
      series: [
        {
          type: "line",
          name: formatMessage({ id: "fertilizer.info.output_weight" }),
          data: weight,
          zones: [{ value: 10, color: "#26791c" }],
          tooltip: {
            valueSuffix: " kg",
          },
          yAxis: 0,
          showInNavigator: true,
          navigatorOptions: {
            type: "areaspline",
            fillColor: "#26791c",
            // color: "rgba(0, 196, 255, 0.4)",
            lineWidth: 2,
            zones: undefined,
            marker: {
              enabled: false,
            },
            label: {
              style: {
                fontSize: "0.875rem",
                color: "red",
              },
            },
          },
        } as Highcharts.SeriesOptionsType,
      ],
    };
  }, [data, formatMessage, minWeightLimit]) as {
    bands: { from: number; to: number; color: string }[];
    series: Highcharts.SeriesOptionsType[] | undefined;
  };
  const rangeButtonsTitle = React.useMemo(
    () => ({
      "1_week": formatMessage({ id: "sensor.info.chart.1_week" }),
      "1_month": formatMessage({ id: "sensor.info.chart.1_month" }),
      "3_month": formatMessage({ id: "sensor.info.chart.3_month" }),
      "6_month": formatMessage({ id: "sensor.info.chart.6_month" }),
    }),
    [formatMessage]
  );

  React.useEffect(() => {
    const node = ref.current;

    if (node) {
      chartRef.current = displayHighchart(
        node,
        series,
        bands,
        rangeButtonsTitle,
        formatMessage({ id: "fertilizer.info.graph_title" }),
        {
          timezone: timeZone,
          decimalPoint: decimalSeparator,
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formatMessage, rangeButtonsTitle]);

  React.useEffect(() => {
    if (chartRef.current)
      chartRef.current.update({
        series,
        xAxis: {
          plotBands: bands,
        },
      });
  }, [series, bands, minWeightLimit]);

  return <div className="history-line-chart" style={style} ref={ref} />;
}

export default Highchart;
