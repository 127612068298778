import { CSSProperties } from "react";

import NumberInput from "../../../../components/inputs/number-input/NumberInput";
import IntegerInput from "../../../../components/inputs/number-input/IntegerInput";
function IntegerInputWithUnit({
  defaultValue,
  valueUnit,
  onChange,
  onBlur,
  disabled,
  className,
  min,
  max,
  style,
}: Readonly<{
  defaultValue: number | undefined;
  valueDelta?: number;
  valueUnit: string;
  onChange?: (value: number | undefined) => void;
  onBlur?: (value: number | undefined) => void;
  disabled?: boolean;
  className?: string;
  min?: number;
  max?: number;
  style?: CSSProperties;
}>) {
  return (
    <div className={["unit-input", className].filter((e) => e).join(" ")}>
      <div className="input-wrapper">
        <IntegerInput
          type="text"
          defaultValue={defaultValue}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          min={min}
          max={max}
        />
        {valueUnit && <div className="unit">{` ${valueUnit}`}</div>}
      </div>
    </div>
  );
}

function NumberInputWithUnit({
  defaultValue,
  valueUnit,
  onChange,
  onBlur,
  disabled,
  className,
  max,
  style,
}: Readonly<{
  defaultValue: number | undefined;
  valueDelta?: number;
  valueUnit: string;
  onChange?: (value: number | undefined) => void;
  onBlur?: (value: number | undefined) => void;
  disabled?: boolean;
  className?: string;
  max?: number;
  style?: CSSProperties;
}>) {
  return (
    <div className={["unit-input", className].filter((e) => e).join(" ")}>
      <div className="input-wrapper">
        <NumberInput
          type="text"
          defaultValue={defaultValue}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          max={max}
        />
        {valueUnit && <div className="unit">{` ${valueUnit}`}</div>}
      </div>
    </div>
  );
}

export { NumberInputWithUnit, IntegerInputWithUnit };
