import { useIntl } from "react-intl";
import PageInfo from "./PageInfo";

function WaterFilterPropertiesInfo() {
  const { formatMessage } = useIntl();

  return (
    <PageInfo>
      <div
        className="sensor-properties-info"
        dangerouslySetInnerHTML={{
          __html: formatMessage({ id: "waterfilter.properties.info" }),
        }}
      />
    </PageInfo>
  );
}

export default WaterFilterPropertiesInfo;
