import { usePlanStore } from "../../../../../store/PlanStore";

import { ReactComponent as WaterTapSVG } from "../../../../../assets/water-tap.svg";
import ElementWrapper from "../common/ElementWrapper";

function WaterTap({ id, x, y }: { id: number | string; x: number; y: number }) {
  return (
    <ElementWrapper id={id} x={x} y={y} size={15}>
      <WaterTapSVG width={15} height={15} />
    </ElementWrapper>
  );
}

function WaterTapElements() {
  const { data } = usePlanStore();

  const waterTaps =
    data?.elements.filter(
      (e) => e.type === "system-element" && e.systemType === "water-tap-point"
    ) ?? [];

  if (waterTaps.length === 0) return null;

  return (
    <>
      {waterTaps.map((waterTap) => (
        <WaterTap
          key={waterTap.id}
          id={waterTap.id}
          x={waterTap.x}
          y={waterTap.y}
        />
      ))}
    </>
  );
}

export default WaterTapElements;
