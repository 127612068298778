import { generatePath } from "react-router-dom";
import { urlDecorator } from "./http.utils";

const SENSOR_PROPERTIES_PATH = "/sensor/:sensor_id/properties";
const SENSOR_INFO_PATH = "/sensor/:sensor_id";
const WATER_FILTER_PROPERTIES_PATH =
  "/water-filter/:water_filter_id/properties";
const WATER_FILTER_INFO_PATH = "/water-filter/:water_filter_id";
const CONTROLLER_PROPERTIES_PATH = "/controller/:controllerId/properties";
const PIPELINE_PROPERTIES_PATH = "/pipeline/:pipelineId/properties";
const TRENCH_PROPERTIES_PATH = "/trench/:trenchId/properties";
const FERTILIZER_PROPERTIES_PATH = "/fertilizer/:fertilizer_id/properties";
const FERTILIZER_INFO_PATH = "/fertilizer/:fertilizer_id";

function getSensorPropertiesURL(sensor_id: number | string) {
  return generatePath(SENSOR_PROPERTIES_PATH, {
    sensor_id: sensor_id + "",
  });
}

function getSensorInfoURL(sensor_id: number | string) {
  return generatePath(SENSOR_INFO_PATH, {
    sensor_id: sensor_id + "",
  });
}

function getWaterFilterPropertiesURL(water_filter_id: number | string) {
  return generatePath(WATER_FILTER_PROPERTIES_PATH, {
    water_filter_id: water_filter_id + "",
  });
}

function getWaterFilterInfoURL(water_filter_id: number | string) {
  return generatePath(WATER_FILTER_INFO_PATH, {
    water_filter_id: water_filter_id + "",
  });
}

function getControllerPropertiesURL(controllerId: string) {
  return generatePath(CONTROLLER_PROPERTIES_PATH, {
    controllerId: controllerId + "",
  });
}

function getPipelinePropertiesURL(pipelineId: string) {
  return generatePath(PIPELINE_PROPERTIES_PATH, {
    pipelineId: pipelineId + "",
  });
}

function getTrenchPropertiesURL(trenchId: string) {
  return generatePath(TRENCH_PROPERTIES_PATH, {
    trenchId: trenchId + "",
  });
}

function getPlanerURL(planId?: string) {
  return planId
    ? urlDecorator(`$PLANER_URL?planId=${planId}`)
    : urlDecorator("$PLANER_URL");
}

function getFertilizerPropertiesURL(fertilizer_id: number | string) {
  return generatePath(FERTILIZER_PROPERTIES_PATH, {
    fertilizer_id: fertilizer_id + "",
  });
}

function getFertilizerInfoURL(fertilizer_id: number | string) {
  return generatePath(FERTILIZER_INFO_PATH, {
    fertilizer_id: fertilizer_id + "",
  });
}

export {
  SENSOR_PROPERTIES_PATH,
  SENSOR_INFO_PATH,
  WATER_FILTER_PROPERTIES_PATH,
  WATER_FILTER_INFO_PATH,
  CONTROLLER_PROPERTIES_PATH,
  PIPELINE_PROPERTIES_PATH,
  TRENCH_PROPERTIES_PATH,
  FERTILIZER_PROPERTIES_PATH,
  FERTILIZER_INFO_PATH,
  getSensorPropertiesURL,
  getSensorInfoURL,
  getWaterFilterPropertiesURL,
  getWaterFilterInfoURL,
  getFertilizerPropertiesURL,
  getFertilizerInfoURL,
  getPlanerURL,
  getControllerPropertiesURL,
  getPipelinePropertiesURL,
  getTrenchPropertiesURL,
};
