import React, { CSSProperties } from "react";
import { useIntl } from "react-intl";

import { usePlanStore } from "../../../../store/PlanStore";
import PropertiesWrapper from "./PropertiesWrapper";

import TextInput from "../../../../components/inputs/TextInput";

import PropertiesItemWithControl from "./PropertiesItemWithControl";
import PropertiesItemWithInput from "./PropertiesItemWithInput";

import { ReactComponent as FertilizerPropertiesSVG } from "../../../../assets/fertilizer-properties.svg";
import { ReactComponent as FertilizerOutWeightSVG } from "../../../../assets/fertilizer-properties-weight-icon.svg";

import useRedirect from "../../../../hooks/useRedirect";
import { getFertilizerInfoURL } from "../../../../utils/path.utils";
import FertilizerPropertiesInterface from "../../../../store/interface/FertilizerPropertiesInterface";
import calcApi from "../../../../service/calcApi";
import PropertiesWithCheckbox from "./PropertiesWithCheckbox";
import { debounce } from "lodash";
import { useUserStore } from "../../../../store/UserStore";

function FertilizerPropertiesElement({
  save,
  close,
  defaultName,
  defaultSerialNumber,
  defaultMinWeightLimit = 0.5,
  defaultMinWeightNotification,
}: Readonly<{
  save: Function;
  close: Function;
  defaultName?: string;
  defaultSerialNumber?: string;
  defaultMinWeightLimit?: number;
  defaultMinWeightNotification?: boolean;
}>) {
  const { formatMessage } = useIntl();
  const [name, changeName] = React.useState(defaultName ?? "");
  const [serial_number, changeSerialNumber] = React.useState(
    defaultSerialNumber ?? ""
  );
  const [min_weight_limit, changeMinWeightLimit] = React.useState(
    defaultMinWeightLimit
  );
  const [min_weight_notification, changeMinWeightNotification] = React.useState(
    defaultMinWeightNotification
  );

  const initialRef = React.useRef(false);

  // save after update
  React.useEffect(() => {
    if (initialRef.current) {
      save({
        name,
        serial_number,
        min_weight_limit,
        min_weight_notification,
      });
    }
    initialRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, serial_number, min_weight_limit, min_weight_notification]);

  return (
    <PropertiesWrapper
      elementTitle={formatMessage({ id: "fertilizer.properties.sub_title" })}
      ElementIcon={FertilizerPropertiesSVG}
      title={formatMessage({ id: "fertilizer.properties.title" })}
      submit={() => {
        save({
          name,
          serial_number,
          min_weight_limit,
          min_weight_notification,
        });
        close();
      }}
    >
      <PropertiesItemWithInput
        className="properties-item"
        title={formatMessage({ id: "fertilizer.properties.name" })}
      >
        <TextInput value={name} onChange={changeName} />
      </PropertiesItemWithInput>

      <PropertiesItemWithInput
        className="properties-item"
        title={formatMessage({ id: "fertilizer.properties.serial_number" })}
      >
        <TextInput
          value={serial_number}
          className="input-uppercase"
          onChange={(v: string) => changeSerialNumber(v.toUpperCase())}
        />
      </PropertiesItemWithInput>

      <div className="properties-separator" />

      <PropertiesItemWithControl
        key={["min_weight_limit", min_weight_limit].filter((e) => e).join("-")}
        className="properties-item"
        Icon={FertilizerOutWeightSVG}
        title={formatMessage({ id: "fertilizer.properties.min_weight_limit" })}
        value={min_weight_limit}
        valueDelta={0.1}
        valueUnit="kg"
        onChange={changeMinWeightLimit}
        style={
          {
            "--color": "#ff7600",
          } as CSSProperties
        }
      />

      <PropertiesWithCheckbox
        title={formatMessage({
          id: "fertilizer.properties.min_weight_notification",
        })}
        className="properties-item"
        value={min_weight_notification}
        onChange={changeMinWeightNotification}
      />

      <div className="properties-separator" />
    </PropertiesWrapper>
  );
}

function FertilizerProperties() {
  const userStore = useUserStore();
  const { planId, data, fertilizer, changeFertilizerProperties } =
    usePlanStore();
  const redirect = useRedirect();
  const fertilizerElement = data?.elements.find(
    (e) => e.type === "system-element" && e.systemType === "fertilizer"
  );

  const saveFertilizer = React.useCallback(
    async (id: string, properties: FertilizerPropertiesInterface) => {
      if (planId == null || data == null) {
        throw new Error(`plan data is null`);
      }

      if (fertilizerElement == null) throw new Error(`Sensor ${id} not found`);

      const sensorData = {
        ...properties,
        id: fertilizerElement.id,
      } as FertilizerPropertiesInterface;

      changeFertilizerProperties((list) => [
        ...(list?.filter((s) => s.id !== id) ?? []),
        sensorData,
      ]);

      const updatedFertilizer = await calcApi.saveFertilizer(
        fertilizerElement.id,
        planId,
        sensorData,
        userStore?.requestLogin
      );

      if (updatedFertilizer != null) {
        changeFertilizerProperties((list) => [
          ...(list?.filter((s) => s.id !== id) ?? []),
          updatedFertilizer,
        ]);
      }
    },
    [
      planId,
      data,
      fertilizerElement,
      changeFertilizerProperties,
      userStore?.requestLogin,
    ]
  );

  const save = React.useMemo(
    () =>
      debounce((data) => {
        if (fertilizer == null) return;
        saveFertilizer(fertilizer.id, data);
      }, 300),
    [saveFertilizer, fertilizer]
  );

  if (fertilizer == null) return null;

  return (
    <FertilizerPropertiesElement
      key={fertilizer.id}
      defaultName={fertilizer.name}
      defaultSerialNumber={fertilizer.serial_number}
      defaultMinWeightLimit={fertilizer.min_weight_limit}
      defaultMinWeightNotification={fertilizer.min_weight_notification}
      save={save}
      close={() => {
        redirect(getFertilizerInfoURL(fertilizer.id), true);
      }}
    />
  );
}

export default FertilizerProperties;
