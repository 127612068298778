import { useIntl } from "react-intl";

import Dropdown, {
  DropdownOptions,
} from "../../../../../../components/dropdown/Dropdown";
import Toggle from "../../../../../../components/toggle/Toggle";
import { ValvesSettingsCircuit } from "../../../../../../store/interface/HydrawiseSettingsInterface";

import { ReactComponent as ArrowRightSVG } from "../../../../../../assets/arrow-right.svg";
import NoData from "./NoData";
import {
  conditionTypes,
  defaultConditionType,
  defaultRootDepthType,
  defaultSoilTypeType,
  defaultSunExposureType,
  defaultVegetationType,
  soilTypes,
  sunExposureTypes,
  vegetationTypes,
} from "./ValvesSettingsTypes";
import { IntegerInputWithUnit } from "../../InputWithUnit";

function LivingEnvironment({
  disabled,
  data,
  onDataChange,
  relays,
}: Readonly<{
  disabled?: boolean;
  data: { id: string; data: ValvesSettingsCircuit; modified: boolean }[];
  onDataChange: Function;
  relays: DropdownOptions;
}>) {
  const { formatMessage } = useIntl();

  if (data == null || data.length === 0) return <NoData />;

  return (
    <div className="valves-settings-table scroll">
      <div className="grid-wrapper living-environment">
        <div className="grid-header">
          <div></div>
          <div>
            {formatMessage({
              id: "controller.properties.living_environment.columns.soil_condition.title",
            })}
          </div>
          <div>
            {formatMessage({
              id: "controller.properties.living_environment.columns.vegetation.title",
            })}
          </div>
          <div>
            {formatMessage({
              id: "controller.properties.living_environment.columns.root_depth.title",
            })}
          </div>
          <div>
            {formatMessage({
              id: "controller.properties.living_environment.columns.soil_type.title",
            })}
          </div>
          <div>
            {formatMessage({
              id: "controller.properties.living_environment.columns.sun_exposure.title",
            })}
          </div>
          <div>
            {formatMessage({
              id: "controller.properties.living_environment.columns.activate.title",
            })}
          </div>
        </div>
        <div className="grid-body">
          {data.map((vz, index) => {
            const row = vz.data;
            const id = vz.id;
            const modified = vz.modified;

            return (
              <div
                key={id}
                className={`grid-row ${modified ? "data-changed" : ""}`}
              >
                <div className="valve-assign">
                  <div
                    className="color-circle"
                    style={{ background: row.color.value }}
                  />
                  <div className="arrow">
                    <ArrowRightSVG width="100%" height="100%" />
                  </div>
                  <Dropdown
                    disabled={disabled}
                    value={row.relay_num?.value}
                    onChange={(value: number) =>
                      onDataChange(index, "relay_num", value)
                    }
                    data={[{ value: null, label: " " }, ...relays]}
                  />
                </div>
                <div className="condition">
                  <Dropdown
                    disabled={disabled}
                    data={conditionTypes.map((ct) => ({
                      ...ct,
                      label: formatMessage({ id: ct.label }),
                    }))}
                    value={
                      row.living_env_soil_condition_coef?.value ??
                      defaultConditionType
                    }
                    onChange={(value) =>
                      onDataChange(id, "living_env_soil_condition_coef", value)
                    }
                  />
                </div>
                <div className="vegetation">
                  <Dropdown
                    disabled={disabled}
                    data={vegetationTypes.map((vt) => ({
                      ...vt,
                      label: formatMessage({ id: vt.label }),
                    }))}
                    value={
                      row.living_env_vegetation_coef?.value ??
                      defaultVegetationType
                    }
                    onChange={(value) =>
                      onDataChange(id, "living_env_vegetation_coef", value)
                    }
                  />
                </div>
                <div className="root-depth">
                  <IntegerInputWithUnit
                    disabled={disabled}
                    defaultValue={
                      row.living_env_root_depth_in_cm?.value ??
                      defaultRootDepthType
                    }
                    onBlur={(value) =>
                      onDataChange(id, "living_env_root_depth_in_cm", value)
                    }
                    valueUnit={formatMessage({ id: "common.units.cm" })}
                    min={5}
                    max={200}
                  />
                </div>
                <div className="soil-type">
                  <div>
                    <Dropdown
                      disabled={disabled}
                      data={soilTypes.map((st) => ({
                        ...st,
                        label: formatMessage({ id: st.label }),
                      }))}
                      value={
                        row.living_env_soil_type_coef?.value ??
                        defaultSoilTypeType
                      }
                      onChange={(value) =>
                        onDataChange(id, "living_env_soil_type_coef", value)
                      }
                    />
                  </div>
                </div>
                <div className="sun-exposure">
                  <Dropdown
                    disabled={disabled}
                    data={sunExposureTypes.map((et) => ({
                      ...et,
                      label: formatMessage({ id: et.label }),
                    }))}
                    value={
                      row.living_env_sun_exposure_coef?.value ??
                      defaultSunExposureType
                    }
                    onChange={(value) =>
                      onDataChange(id, "living_env_sun_exposure_coef", value)
                    }
                  />
                </div>
                <div className="active">
                  <Toggle
                    disabled={disabled}
                    value={row.living_env_enabled?.value}
                    onChange={(value) =>
                      onDataChange(id, "living_env_enabled", value)
                    }
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default LivingEnvironment;
