import React from "react";
import { ReactComponent as ArrowRightSVG } from "../../../../../assets/arrow-right.svg";
import { ReactComponent as CloseSVG } from "../../../../../assets/close-dialog.svg";
import { ReactComponent as PopupArrowRightSVG } from "../../../../../assets/popup-arrow-rd.svg";
import Dropdown, {
  DropdownOptions,
} from "../../../../../components/dropdown/Dropdown";
import { usePlanStore } from "../../../../../store/PlanStore";
import { useUIContext } from "../../../../../store/uiStore/UIContext";
import {
  CircuitAssigned,
  ControllerPropertiesContext,
} from "./ControllerPropertiesContext";
import { HydrawiseSettingsInterface } from "../../../../../store/interface/HydrawiseSettingsInterface";
import { useIntl } from "react-intl";

function isDefaultAssigned(circuit: CircuitAssigned[0]) {
  if (circuit.sensor_id == null) return false;
  return circuit.default_sensor_ids.includes(circuit.sensor_id);
}

function AssignmentBlock({
  circuits,
  title,
  subtitle,
  className,
  sensors,
  relays,
  onChange,
}: {
  circuits: HydrawiseSettingsInterface["circuits"];
  title: string;
  subtitle: string;
  className?: string;
  sensors: DropdownOptions;
  relays: DropdownOptions;
  onChange: (circuit: CircuitAssigned[0]) => void;
}) {
  if (circuits.length === 0) return null;

  const default_changed = circuits.find(
    (c) => c.default_sensor_ids.length > 0 && !isDefaultAssigned(c)
  );

  return (
    <div
      className={[
        "assignment-block",
        default_changed ? "default_changed" : undefined,
        className,
      ]
        .filter((e) => e)
        .join(" ")}
    >
      <div className="title">{title}</div>
      <div className="subtitle">{subtitle}</div>
      <div className="circuits-list">
        {circuits
          .sort((a, b) => a.id.localeCompare(b.id))
          .map((circuit) => (
            <React.Fragment key={circuit.id}>
              <div
                className="item-color"
                style={{ background: circuit.color }}
              />
              <div className="arrow">
                <ArrowRightSVG width="100%" height="100%" />
              </div>
              <Dropdown
                value={circuit.relay_num}
                onChange={(value: number) => {
                  onChange({ ...circuit, relay_num: value });
                }}
                data={[{ value: undefined, label: " " }, ...relays]}
              />
              <div className="arrow">
                <ArrowRightSVG width="100%" height="100%" />
              </div>
              <Dropdown
                value={circuit.sensor_id}
                onChange={(value: string) => {
                  onChange({ ...circuit, sensor_id: value });
                }}
                data={[{ value: undefined, label: " " }, ...sensors]}
              />
            </React.Fragment>
          ))}
      </div>
    </div>
  );
}

function SensorAssignmentFormElement({
  circuitAssigned,
  changeCircuitAssigned,
  relays,
  sensors,
  close,
  save,
}: {
  circuitAssigned: CircuitAssigned;
  changeCircuitAssigned: (assignment: CircuitAssigned) => void;
  relays: DropdownOptions;
  sensors: DropdownOptions;
  close: Function;
  save: () => Promise<void>;
}) {
  const { formatMessage } = useIntl();
  const { rightSideRef } = useUIContext();

  const sensorAssignmentRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const rightSideNode = rightSideRef.current;
    const sensorAssignmentNode = sensorAssignmentRef.current;

    if (sensorAssignmentNode && rightSideNode) {
      const resizeListener = () => {
        sensorAssignmentNode.style.top = `${rightSideNode.offsetTop}px`;
        sensorAssignmentNode.style.left = `${rightSideNode.offsetLeft - 380}px`;
        sensorAssignmentNode.style.right = "auto";

        sensorAssignmentNode.style.height = `${rightSideNode.offsetHeight}px`;
      };
      resizeListener();

      window.addEventListener("resize", resizeListener);
      return () => {
        window.removeEventListener("resize", resizeListener);
      };
    }
  }, [rightSideRef]);

  return (
    <div className="sensor-assignment" ref={sensorAssignmentRef}>
      <div className="sensor-assignment-wrapper">
        <div className="sensor-assignment-header">
          <div style={{ flex: "auto" }} />
          <button
            className="close-button"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              close();
            }}
          >
            <CloseSVG width="100%" height="100%" />
          </button>
        </div>

        <div className="sensor-assignment-content scroll">
          <AssignmentBlock
            title={formatMessage({ id: "assignment.auto_assigned.title" })}
            subtitle={formatMessage({
              id: "assignment.auto_assigned.subtitle",
            })}
            circuits={circuitAssigned.filter(
              (e) => e.default_sensor_ids.length > 0
            )}
            onChange={(circuit) =>
              changeCircuitAssigned([
                ...circuitAssigned.filter((e) => e.id !== circuit.id),
                circuit,
              ])
            }
            sensors={sensors}
            relays={relays}
          />

          <AssignmentBlock
            title={formatMessage({ id: "assignment.manual.title" })}
            subtitle={formatMessage({ id: "assignment.manual.subtitle" })}
            circuits={circuitAssigned.filter(
              (e) => e.default_sensor_ids.length === 0
            )}
            onChange={(circuit) =>
              changeCircuitAssigned([
                ...circuitAssigned.filter((e) => e.id !== circuit.id),
                circuit,
              ])
            }
            sensors={sensors}
            relays={relays}
          />
        </div>

        <div className="sensor-assignment-footer">
          <button
            onClick={async (e) => {
              e.preventDefault();
              e.stopPropagation();

              await save();
              close();
            }}
          >
            {formatMessage({ id: "assignment.save" })}
          </button>
        </div>
      </div>
      <PopupArrowRightSVG
        style={{
          position: "absolute",
          width: "30px",
          right: "5px",
          bottom: "106px",
        }}
      />
    </div>
  );
}

function SensorAssignmentForm() {
  const { formatMessage } = useIntl();

  const {
    hasSensorAssignmentForm,
    toggleSensorAssignmentForm,
    saveHydrawiseSettings,
    activeController,
    circuitAssigned,
    changeCircuitAssigned,
    locationName,
    noIrrigationTimeStart,
    noIrrigationTimeStop,
    noIrrigationDays,
    noIrrigationTemperatureLimit,
    noIrrigationTemperatureEnabled,
    noIrrigationWindSpeedLimit,
    noIrrigationWindSpeedEnabled,
  } = React.useContext(ControllerPropertiesContext);

  const { sensorList, hydrawiseSettings } = usePlanStore();

  const sensors = React.useMemo(() => {
    return (
      sensorList?.map((s, i) => ({
        value: s.id,
        label: s.title,
      })) ?? []
    );
  }, [sensorList]);

  const relays = React.useMemo(() => {
    return (
      hydrawiseSettings?.relays?.map((s, i) => ({
        value: s.num,
        label: (
          <>
            {s.name}
            {"\n"}
            <span style={{ fontSize: "0.75em" }}>
              {formatMessage({ id: "assignment.valves.zone_number.prefix" })}{" "}
              {s.num}
            </span>
          </>
        ),
      })) ?? []
    );
  }, [formatMessage, hydrawiseSettings?.relays]);

  if (!hasSensorAssignmentForm) return null;

  return (
    <SensorAssignmentFormElement
      circuitAssigned={circuitAssigned}
      changeCircuitAssigned={changeCircuitAssigned}
      sensors={sensors}
      relays={relays}
      close={() => {
        changeCircuitAssigned(hydrawiseSettings?.circuits ?? []);
        toggleSensorAssignmentForm(false);
      }}
      save={async () => {
        saveHydrawiseSettings(
          activeController,
          circuitAssigned,
          locationName,
          noIrrigationTimeStart,
          noIrrigationTimeStop,
          noIrrigationDays,
          noIrrigationTemperatureLimit,
          noIrrigationTemperatureEnabled,
          noIrrigationWindSpeedLimit,
          noIrrigationWindSpeedEnabled
        );
      }}
    />
  );
}

export default SensorAssignmentForm;
