import { ReactComponent as ScalePlusSVG } from "../../../../assets/scale-plus.svg";
import { ReactComponent as ScaleMinusSVG } from "../../../../assets/scale-minus.svg";

import { useUIContext } from "../../../../store/uiStore/UIContext";
import useUIHelper from "../../../../store/uiStore/useUIHelper";

function ZoomTool() {
  const { grid, zoom } = useUIContext();
  const { currentCenter, zoomToPoint } = useUIHelper();

  return (
    <div className="zoom-info">
      <div className="zoom-text-wrapper">
        <div
          className="grid-size"
          style={grid?.size ? { width: grid.size * zoom } : undefined}
        >
          <div className="zoom-text">{`${grid?.value ?? 1} m`}</div>
        </div>
      </div>

      <div className="zoom-control">
        <button
          type="button"
          onClick={() => zoomToPoint(zoom + 0.1, currentCenter)}
        >
          <ScalePlusSVG />
        </button>
      </div>

      <div className="zoom-control">
        <button
          type="button"
          onClick={() => zoomToPoint(zoom - 0.1, currentCenter)}
        >
          <ScaleMinusSVG />
        </button>
      </div>
    </div>
  );
}

export default ZoomTool;
