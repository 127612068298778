import { v1 as uuid } from "uuid";
import WaterFilterInterface from "../interface/WaterFilterInterface";

function waterFilterFactory({
  id = uuid(),
  x,
  y,

  prefix,
  name,
  disabled = false,
  serial_number,
  pressure_drop_limit,
  inlet_pressure_limit,
  pressure_drop_notification,
  inlet_pressure_notification,

  //
  pressure1,
  pressure2,
  battery,
  status = "nok",
}: {
  id?: string;
  x: number;
  y: number;

  prefix: string;
  name?: string;
  disabled?: boolean;
  serial_number?: string;
  pressure_drop_limit?: number;
  inlet_pressure_limit?: number;
  pressure_drop_notification?: boolean;
  inlet_pressure_notification?: boolean;
  //
  pressure1?: number;
  pressure2?: number;
  battery?: number;
  status?: "ok" | "nok";
}): WaterFilterInterface {
  return {
    get id() {
      return id + "";
    },
    get x() {
      return x;
    },
    get y() {
      return y;
    },

    // properties
    prefix,
    name,

    get title() {
      return [prefix, name?.trim()].filter((e) => e).join(" - ");
    },

    disabled,
    serial_number,

    // config,
    pressure_drop_limit: pressure_drop_limit ? +pressure_drop_limit : undefined,
    inlet_pressure_limit: inlet_pressure_limit
      ? +inlet_pressure_limit
      : undefined,
    pressure_drop_notification: pressure_drop_notification ?? false,
    inlet_pressure_notification: inlet_pressure_notification ?? false,

    get is_fill_properties() {
      return this.serial_number == null || this.serial_number.trim() === "";
    },

    // not saved
    get pressure1() {
      return pressure1;
    },
    get pressure2() {
      return pressure2;
    },
    get pressure_drop() {
      return pressure1 == null || pressure2 == null
        ? undefined
        : pressure1 - pressure2;
    },
    get battery() {
      if (battery == null) return undefined;
      if (battery >= 3.5) return 100;
      if (battery >= 3.45 && battery < 3.5) return 80;
      if (battery >= 3.4 && battery < 3.45) return 60;
      if (battery >= 3.35 && battery < 3.4) return 40;
      if (battery >= 3.3 && battery < 3.35) return 15;

      return 0;
    },
    get status() {
      const statusValue = status?.toLowerCase();

      if (statusValue === "nok") {
        return "nok";
      }

      if (
        (this.inlet_pressure_limit ?? 0) > 0 &&
        this.pressure1 != null &&
        this.pressure1 < this.inlet_pressure_limit!
      ) {
        return "low_inlet_pressure";
      }

      if (
        (this.pressure_drop_limit ?? 0) > 0 &&
        this.pressure_drop != null &&
        this.pressure_drop > this.pressure_drop_limit!
      ) {
        return "pressure_drop";
      }

      return "ok";
    },
  };
}

export default waterFilterFactory;
