import { ReactComponent as InformSVG } from "../../../../assets/inform.svg";
import MultiSelectDropdown, {
  MultiSelectDropdownOptions,
} from "../../../../components/dropdown-with-multiselect/multiselect-dropdown";
import Link from "../../../../components/link/Link";

function PropertiesWithMultiSelectDropDown({
  selectedValues,
  onChange,
  data,
  title,
  className,
  disabled,
  informURL,
}: {
  selectedValues: number[];
  onChange: Function;
  data: MultiSelectDropdownOptions;
  title: string;
  className?: string;
  disabled?: boolean;
  informURL?: string;
}) {
  return (
    <div
      className={[className, "with-multiselect-dropdown"]
        .filter((e) => e)
        .join(" ")}
    >
      <div
        className={["title", informURL ? "inform" : undefined]
          .filter((e) => e)
          .join(" ")}
      >
        <span>{title}</span>
        {informURL && (
          <Link href={informURL} target="__blank" rel="noopener noreferrer">
            <InformSVG />
          </Link>
        )}
      </div>
      <MultiSelectDropdown
        data={data}
        selectedValues={selectedValues}
        onChange={onChange}
      />
    </div>
  );
}

export default PropertiesWithMultiSelectDropDown;
