import React from "react";

import { usePlanStore } from "../../../../store/PlanStore";

import PageInfo from "../PageInfo";
import FertilizerValues from "./FertilizerValues";
import HistoryLine from "./history/HistoryLine";

import { getFertilizerPropertiesURL } from "../../../../utils/path.utils";

import { ReactComponent as ArrowRightSVG } from "../../../../assets/arrow-right.svg";
import { ReactComponent as PencilSVG } from "../../../../assets/pencil.svg";
import Redirect from "../../../../components/redirect/Redirect";
import RedirectLink from "../../../../components/redirect/RedirectLink";

function FertilizerInfo() {
  const [showMore, changeShowMore] = React.useState(false);
  const { fertilizer, planId } = usePlanStore();

  if (fertilizer == null) return;
  if (fertilizer.is_fill_properties)
    return (
      <Redirect to={getFertilizerPropertiesURL(fertilizer.id)} replace={true} />
    );

  return (
    <PageInfo>
      <div
        className={["sensor-info", showMore ? "more" : undefined]
          .filter((e) => e)
          .join(" ")}
      >
        <FertilizerValues fertilizer={fertilizer} />
        <HistoryLine
          planId={planId}
          fertilizer={fertilizer}
          active={showMore}
        />

        <RedirectLink
          href={getFertilizerPropertiesURL(fertilizer.id)}
          className="edit-button"
        >
          <PencilSVG />
        </RedirectLink>

        <div
          className="show-more-wrap"
          onClick={() => changeShowMore(!showMore)}
        >
          <button type="button" className="show-more">
            <ArrowRightSVG
              style={{
                transform: showMore ? "rotate(-90deg)" : "rotate(90deg)",
              }}
            />
          </button>
        </div>
      </div>
    </PageInfo>
  );
}

export default FertilizerInfo;
