import ReactDOM from "react-dom";

import "./popup.scss";

function PopupWrapper({
  wrapperClassName = "",
  className = "",
  title,
  footer,
  children,
  close,
}: {
  wrapperClassName?: string;
  className?: string;
  title: string;
  footer?: JSX.Element;
  children: JSX.Element | JSX.Element[];
  close?: Function;
}) {
  const container = document.getElementById("dvs-modal");
  if (container == null) return null;

  return ReactDOM.createPortal(
    <div className={`window-popup ${wrapperClassName}`}>
      <div className={`window-popup-container ${className}`}>
        {close != null ? (
          <div className="window-popup-close" onClick={() => close()}>
            <i>&#215;</i>
          </div>
        ) : null}
        {title && <div className="window-popup-header">{title}</div>}
        <div className="window-popup-content">{children}</div>
        {footer && <div className="window-popup-footer">{footer}</div>}
      </div>
    </div>,
    container
  );
}

export default PopupWrapper;
