import { useIntl } from "react-intl";

function NoData() {
  const { formatMessage } = useIntl();
  return (
    <div className="no-data">
      {formatMessage({ id: "controller.properties.valves_settings.no_data" })}
    </div>
  );
}

export default NoData;
