import { ReactComponent as FertilizerOkSVG } from "../../../../assets/fertilizer-ok.svg";
import { ReactComponent as FertilizerLowOutWeightSVG } from "../../../../assets/fertilizer-low-out-weight.svg";
import { ReactComponent as FertilizerNoDataSVG } from "../../../../assets/fertilizer-no-data.svg";

const TEXT_BY_STATUS = {
  ok: {
    Icon: FertilizerOkSVG,
    text: { id: "fertilizer.status.ok" },
    color: "#c5d75d",
  },
  low_out_weight: {
    Icon: FertilizerLowOutWeightSVG,
    text: { id: "fertilizer.status.low_out_weight" },
    color: "#e3242b",
  },
  nok: {
    Icon: (props: any) => <FertilizerNoDataSVG {...props} />,
    text: { id: "fertilizer.status.nok" },
    color: "#c3c3c3",
  },
};

export default TEXT_BY_STATUS;
