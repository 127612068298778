import { HydrawiseSettingsResponseInterface } from "../../service/calcApi/types";
import { HydrawiseSettingsInterface } from "../interface/HydrawiseSettingsInterface";

function hydrawiseSettingsFactory(
  data: HydrawiseSettingsResponseInterface
): HydrawiseSettingsInterface {
  return {
    get hydrawise_api_key() {
      return data.hydrawise_api_key ? data.hydrawise_api_key + "" : undefined;
    },
    get controller_id() {
      return data.controller_id ? data.controller_id + "" : undefined;
    },
    get controller_type() {
      return data.controller_type;
    },
    get circuits() {
      return data.circuits.map((v, i) => {
        const assigned = data.assigned.find(
          (e) => e.circuit_id === v.circuit_id
        );

        return {
          id: v.circuit_id,
          color: v.color,
          relay_num: assigned?.relay_num,
          sensor_id: assigned?.sensor_id,
          default_sensor_ids: v.sensor_ids ?? [],
          valve_num: assigned?.valve_num,
          circuit_type: v.circuit_type,
          et_env_precipitation_in_lm2_per_h:
            assigned?.et_env_precipitation_in_lm2_per_h,
          living_env_soil_condition_coef:
            assigned?.living_env_soil_condition_coef,
          living_env_vegetation_coef: assigned?.living_env_vegetation_coef,
          living_env_root_depth_in_cm: assigned?.living_env_root_depth_in_cm,
          living_env_soil_type_coef: assigned?.living_env_soil_type_coef,
          living_env_sun_exposure_coef: assigned?.living_env_sun_exposure_coef,
          living_env_enabled: assigned?.living_env_enabled,
          et_env_irrigation_type: assigned?.et_env_irrigation_type,
          et_env_manual_adj_coef: assigned?.et_env_manual_adj_coef,
          et_env_manual_irrigation_time_in_min:
            assigned?.et_env_manual_irrigation_time_in_min,
          et_env_enabled: assigned?.et_env_enabled,
        };
      });
    },

    get controllers() {
      return data.controllers.map((c, i) => ({
        id: c.controller_id + "",
        name: c.name,
        serial_number: c.serial_number,
        get title() {
          return c.name ?? c.serial_number ?? `C${i}`;
        },
      }));
    },

    get relays() {
      return data.relays.map((c, i) => ({
        id: c.relay_id + "",
        name: c.relay_name,
        num: c.relay_num,
      }));
    },

    get location_name() {
      return data.location_name ?? "";
    },
    get no_irr_time_start() {
      return data.no_irr_time_start?.split(":")[0];
    },
    get no_irr_time_stop() {
      return data.no_irr_time_stop?.split(":")[0];
    },
    get no_irr_days() {
      return data.no_irr_days ?? [];
    },
    get no_irr_temperature_limit_in_c() {
      return data.no_irr_temperature_limit_in_c ?? 18;
    },
    get no_irr_wind_speed_limit_in_kmh() {
      return data.no_irr_wind_speed_limit_in_kmh ?? 30;
    },
    get no_irr_temperature_enabled() {
      return data.no_irr_temperature_enabled ?? false;
    },
    get no_irr_wind_speed_enabled() {
      return data.no_irr_wind_speed_enabled ?? false;
    },
  };
}

export default hydrawiseSettingsFactory;
