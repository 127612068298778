import React from "react";
import PlanImage from "./elements/PlanImage";
import SensorCoverage from "./elements/SensorCoverage";
import { useUIContext } from "../../../store/uiStore/UIContext";
import SensorElements from "./elements/SensorElements";
import ControllerElements from "./elements/system-elements/ControllerElements";
import ValveboxElements from "./elements/system-elements/ValveboxElements";
import WaterSupplyElements from "./elements/system-elements/WaterSupplyElements";
import WaterTapElements from "./elements/system-elements/WaterTapElements";
import FertilizerElements from "./elements/system-elements/FertilizerElements";
import WaterFiltersElements from "./elements/system-elements/WaterFiltersElements";
import WaterMeterElements from "./elements/system-elements/WaterMeterElements";
import AirCompressorElements from "./elements/system-elements/AirCompressorElements";
import Pipes from "./elements/pipes/Pipes";
import TrenchViewPopup from "./elements/pipes/TrenchViewPopup";

function Elements() {
  const { offset, containerSize, zoom } = useUIContext();

  const viewBoxByZoom = React.useMemo(
    () =>
      [-offset.x, -offset.y, containerSize.width, containerSize.height]
        .map((v) => v / zoom)
        .join(" "),
    [containerSize.height, containerSize.width, offset.x, offset.y, zoom]
  );

  if (!(containerSize.width > 0 && containerSize.height)) return null;

  return (
    <>
      <svg viewBox={viewBoxByZoom} className="plan">
        <PlanImage />
        <SensorCoverage />
      </svg>

      <svg viewBox={viewBoxByZoom} className="elements">
        <Pipes />
        <WaterFiltersElements />
        <WaterTapElements />
        <WaterMeterElements />
        <AirCompressorElements />
        <FertilizerElements />
        <SensorElements />
        <ControllerElements />
        <ValveboxElements />
        <WaterSupplyElements />
        <TrenchViewPopup />
      </svg>
    </>
  );
}

export default Elements;
