interface SoilTypeInterface {
  title: any;
  max_soil_moisture?: number;
  min_soil_moisture?: number;
  wilting_point?: number;
}

interface PlantTypeInterface {
  title: any;
}

const URL_CONSTANTS = {
  $CALC_BACKEND_URL: window.APP_CONFIG?.REACT_APP_CALC_BACKEND_URL,
  $SENSOR_BACKEND_URL: window.APP_CONFIG?.REACT_APP_SENSOR_BACKEND_URL,
  $SHOP_URL: window.APP_CONFIG?.REACT_APP_SHOP_URL,
  $PLANER_URL: window.APP_CONFIG?.REACT_APP_PLANER_URL,
};

const SOIL_TYPES = {
  sand: {
    title: { id: "soil_type.sand" },
    max_soil_moisture: 8,
    min_soil_moisture: 6,
    wilting_point: 5,
  } as SoilTypeInterface,
  "sandy-soil": {
    title: { id: "soil_type.sandy-soil" },
    max_soil_moisture: 10,
    min_soil_moisture: 8,
    wilting_point: 5,
  } as SoilTypeInterface,
  "humus-mixed-soil": {
    title: { id: "soil_type.humus-mixed-soil" },
    max_soil_moisture: 25,
    min_soil_moisture: 18,
    wilting_point: 15,
  } as SoilTypeInterface,
  "clay-mixed-soil": {
    title: { id: "soil_type.clay-mixed-soil" },
    max_soil_moisture: 35,
    min_soil_moisture: 25,
    wilting_point: 20,
  } as SoilTypeInterface,
  "loamy-soil": {
    title: { id: "soil_type.loamy-soil" },
    max_soil_moisture: 40,
    min_soil_moisture: 28,
    wilting_point: 25,
  } as SoilTypeInterface,
  peat: {
    title: { id: "soil_type.peat" },
  } as SoilTypeInterface,
};

const PLANT_TYPES = {
  lawn: { title: { id: "plant_type.lawn" } } as PlantTypeInterface,
  "flower-bed": {
    title: { id: "plant_type.flower-bed" },
  } as PlantTypeInterface,
  hedge: { title: { id: "plant_type.hedge" } } as PlantTypeInterface,
  tree: { title: { id: "plant_type.tree" } } as PlantTypeInterface,
  "raised-bed": {
    title: { id: "plant_type.raised-bed" },
  } as PlantTypeInterface,
  "balcony-boxes": {
    title: { id: "plant_type.balcony-boxes" },
  } as PlantTypeInterface,
  "vertical-greenery": {
    title: { id: "plant_type.vertical-greenery" },
  } as PlantTypeInterface,
};

export { SOIL_TYPES, PLANT_TYPES, URL_CONSTANTS };
