import React from "react";

function PropertiesItemWithInput({
  title,
  unit,
  className,
  Icon,
  children,
}: {
  title: string | React.ReactElement;
  unit?: string;
  children: JSX.Element;
  className: string;
  Icon?: React.ComponentType<any>;
}) {
  return (
    <div className={["with-input", className].filter((e) => e).join(" ")}>
      {Icon && (
        <div className="icon">
          <Icon />
        </div>
      )}
      <div className="title">{title}</div>
      <div className="setting-value">{children}</div>
      {unit && <div className="unit">{unit}</div>}
    </div>
  );
}

export default PropertiesItemWithInput;
