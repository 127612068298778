import { CSSProperties, useState, useCallback } from "react";
import NumberInput from "../../../../components/inputs/number-input/NumberInput";

import { ReactComponent as PlusSVG } from "../../../../assets/plus-small.svg";
import { ReactComponent as MinusSVG } from "../../../../assets/minus-small.svg";
import { uniqueId } from "lodash";

function PropertiesItemWithControl({
  Icon,
  title,
  value,
  valueDelta = 1,
  valueUnit,
  onChange: _onChange = () => {},
  disabled,
  className,
  max,
  style,
}: {
  Icon: React.ComponentType<any>;
  title: string;
  value: number | undefined;
  valueDelta?: number;
  valueUnit: string;
  onChange: Function;
  disabled?: boolean;
  className?: string;
  max?: number;
  style?: CSSProperties;
}) {
  const [key, changeKey] = useState(uniqueId());

  const onChange = useCallback(
    (v: number) => {
      _onChange(v);
      changeKey(uniqueId());
    },
    [_onChange]
  );

  return (
    <div
      className={["with-control", disabled ? "disabled" : undefined, className]
        .filter((e) => e)
        .join(" ")}
      style={style}
    >
      <div className="setting-info">
        <div className="setting-icon-wrap">
          <div className="setting-icon">
            <Icon />
          </div>
          <div className="setting-value">
            <NumberInput
              key={key}
              type="text"
              defaultValue={value}
              onChange={(val: number) => _onChange(val)}
              disabled={disabled}
              max={max}
            />
            {valueUnit && <div className="unit">{valueUnit}</div>}
          </div>
        </div>
        <div className="setting-title">{title}</div>
      </div>

      <div className="setting-control">
        <button
          type="button"
          onClick={() => {
            if (!disabled) {
              onChange((value ?? 0) + valueDelta);
            }
          }}
          className="setting-control-item"
        >
          <PlusSVG />
        </button>
        <div className="setting-control-item-separator" />
        <button
          type="button"
          onClick={() => {
            if (!disabled) {
              onChange((value ?? 0) - valueDelta);
            }
          }}
          className="setting-control-item"
        >
          <MinusSVG />
        </button>
      </div>
    </div>
  );
}

export default PropertiesItemWithControl;
