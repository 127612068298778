import { HashRouter as Router, Route, Routes } from "react-router-dom";
import { PlanProvider } from "../../store/PlanStore";
import { UIContextProvider } from "../../store/uiStore/UIContext";
import { UserProvider } from "../../store/UserStore";

import {
  CONTROLLER_PROPERTIES_PATH,
  SENSOR_INFO_PATH,
  SENSOR_PROPERTIES_PATH,
  PIPELINE_PROPERTIES_PATH,
  TRENCH_PROPERTIES_PATH,
  WATER_FILTER_INFO_PATH,
  WATER_FILTER_PROPERTIES_PATH,
  FERTILIZER_INFO_PATH,
  FERTILIZER_PROPERTIES_PATH,
} from "../../utils/path.utils";
import Header from "../layouts/header/Header";
import SensorInfo from "../layouts/page-info/sensor-info/SensorInfo";
import SensorListInfo from "../layouts/page-info/SensorListInfo";
import SensorPropertiesInfo from "../layouts/page-info/SensorPropertiesInfo";
import Plan from "../layouts/plan/Plan";
import SensorProperties from "../layouts/plan/properties/SensorProperties";
import ControllerProperties from "../layouts/plan/properties/controller/ControllerProperties";
import PipelineProperties from "../layouts/plan/properties/pipes/PipelineProperties";
//water drop sensor
import WaterFilterProperties from "../layouts/plan/properties/WaterFilterProperties";
import WaterFilterInfo from "../layouts/page-info/water-filter-info/WaterFilterInfo";
import WaterFilterPropertiesInfo from "../layouts/page-info/WaterFilterPropertiesInfo";
//fertilizer
import FertilizerProperties from "../layouts/plan/properties/FertilizerProperties";
import FertilizerInfo from "../layouts/page-info/fertilizer-info/FertilizerInfo";
import FertilizerPropertiesInfo from "../layouts/page-info/FertilizerPropertiesInfo";

function Main() {
  return (
    <Router>
      <UserProvider>
        <PlanProvider>
          <UIContextProvider>
            <Header />
            <Plan>
              <Routes>
                <Route path={SENSOR_INFO_PATH} element={<SensorInfo />} />
                <Route
                  path={SENSOR_PROPERTIES_PATH}
                  element={
                    <>
                      <SensorPropertiesInfo />
                      <SensorProperties />
                    </>
                  }
                />
                <Route
                  path={WATER_FILTER_INFO_PATH}
                  element={<WaterFilterInfo />}
                />
                <Route
                  path={WATER_FILTER_PROPERTIES_PATH}
                  element={
                    <>
                      <WaterFilterPropertiesInfo />
                      <WaterFilterProperties />
                    </>
                  }
                />
                <Route
                  path={FERTILIZER_INFO_PATH}
                  element={<FertilizerInfo />}
                />
                <Route
                  path={FERTILIZER_PROPERTIES_PATH}
                  element={
                    <>
                      <FertilizerPropertiesInfo />
                      <FertilizerProperties />
                    </>
                  }
                />
                <Route
                  path={CONTROLLER_PROPERTIES_PATH}
                  element={
                    <>
                      <SensorListInfo />
                      <ControllerProperties />
                    </>
                  }
                />
                <Route
                  path={PIPELINE_PROPERTIES_PATH}
                  element={
                    <>
                      <SensorListInfo />
                      <PipelineProperties />
                    </>
                  }
                />
                <Route
                  path={TRENCH_PROPERTIES_PATH}
                  element={<SensorListInfo />}
                />
                <Route path="/" element={<SensorListInfo />} />
              </Routes>
            </Plan>
          </UIContextProvider>
        </PlanProvider>
      </UserProvider>
    </Router>
  );
}

export default Main;
