import { v1 as uuid } from "uuid";
import PipelineInterface from "../interface/PipelineInterface";
import { pointInArea } from "@dvsproj/ipat-core/areaUtils";
import { ceilWithThreshold } from "@dvsproj/ipat-core/formatter";

function planPipelineFactory(
  pipeline: {
    id?: string | undefined;
    pointIds?: any[] | undefined;
    lines?: any[] | undefined;
    color?: string | undefined;
    type?: string | undefined;
  },
  elements: any[],
  areas: any[]
): PipelineInterface {
  const points = (pipeline?.pointIds ?? [])
    .reduce((unique, pointId) => {
      const e = elements.find((s) => s.id === pointId);
      if (e != null && unique.find(({ id }) => id === e.id) == null) {
        unique.push(e);
      }
      return unique;
    }, [])
    .filter((p) => p != null);

  const sprinklers = points
    .filter(({ pointType }) => {
      return (
        pointType === "sprinkler-point" ||
        pointType === "rzws-point" ||
        pointType === "raised-bed-point"
      );
    })
    .reduce((unique, p) => {
      const e = elements.find((s) => s.id === p.parentId);
      if (e != null && unique.find(({ id }) => id === e.id) == null) {
        unique.push(e);
      }
      return unique;
    }, []);

  const driplinePoint = points
    .filter(({ pointType }) => {
      return pointType === "dripline-point";
    })
    .map((p) => {
      return {
        point: p,
        area: areas.find((a) => a.quantity === "dripline" && pointInArea(p, a)),
      };
    })?.[0];
  const lineOptions = {
    color: pipeline.color ?? "#9EA1A2",
    strokeWidth: pipeline.type === "tubing" ? 1.5 : 3,
  };

  return {
    id: pipeline.id || "pipeline-" + uuid(),
    type: "pipeline",
    lineType: driplinePoint != null ? "dripline" : pipeline.type,
    pointIds: (pipeline.pointIds ?? []).filter((v, i, a) => a.indexOf(v) === i),
    get lines() {
      let result = (pipeline.lines ?? []).map(
        (line: {
          id: string;
          start: string;
          end: string;
          type: string;
          data: {
            volumetricFlowM?: number;
            edgeLengthM?: number;
          };
        }) => {
          const startPoint = points.find((p) => p.id === line.start);
          const endPoint = points.find((p) => p.id === line.end);
          const path =
            startPoint && endPoint
              ? "M " +
                startPoint.x +
                " " +
                startPoint.y +
                " L " +
                endPoint.x +
                " " +
                endPoint.y
              : undefined;
          return {
            id: line.id,
            startPoint,
            endPoint,
            path,
            color: lineOptions.color,
            length: line.data?.edgeLengthM,
            type: line.type,
          };
        }
      );
      return result;
    },
    get points() {
      return points;
    },
    get sprinklers() {
      return sprinklers;
    },
    get waterQuantity() {
      let result = 0;
      if (sprinklers && sprinklers.length > 0) {
        result = sprinklers.reduce((acc, point) => {
          return point && point.waterflow ? acc + point.waterflow : acc;
        }, 0);
      }
      return result;
    },
    get shapeLimit() {
      const waterSupply = elements
        ? elements.find(
            (element) =>
              element.type === "system-element" &&
              element.systemType === "water-supply"
          )
        : undefined;
      return waterSupply && waterSupply.waterQuantity
        ? waterSupply.waterQuantity * 1000
        : undefined;
    },
    get color() {
      const { color } = lineOptions;
      return color;
    },
    get lineOptions() {
      return lineOptions;
    },
    get totalLength() {
      return (pipeline.lines ?? []).reduce((acc, line) => {
        return line?.data?.edgeLengthM ? acc + line.data.edgeLengthM : acc;
      }, 0);
    },
    get flowerArea() {
      return driplinePoint?.area?.size;
    },
    get driplineValvesCount() {
      if (!(driplinePoint?.area?.size > 0)) {
        return 0;
      }

      const waterSupply = elements
        ? elements.find(
            (element) =>
              element.type === "system-element" &&
              element.systemType === "water-supply"
          )
        : undefined;

      // How much water in l/h does one square meter consume
      const areaConsumption = 15;

      // Number of valves in driplineArea
      return ceilWithThreshold(
        driplinePoint.area.size * areaConsumption,
        waterSupply.waterQuantity * 1000,
        5 //areaThreshold
      );
    },
  };
}

export default planPipelineFactory;
