import React from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { SOIL_TYPES } from "../../../config/config";
import useFormatter from "../../../hooks/useFormatter";
import { usePlanStore } from "../../../store/PlanStore";
import PageInfo from "./PageInfo";

function SensorPropertiesInfo() {
  const { formatMessage } = useIntl();
  const { formatMoisture } = useFormatter();
  const { sensorList } = usePlanStore();
  const { sensor_id } = useParams();

  const sensor = React.useMemo(() => {
    return sensorList?.find((s) => s.id === sensor_id);
  }, [sensorList, sensor_id]);

  const soilTypeInfo = React.useMemo(() => {
    const soil_type = sensor?.soil_type
      ? SOIL_TYPES[sensor.soil_type]
      : undefined;

    if (soil_type == null) return "";

    const { max_soil_moisture, min_soil_moisture, wilting_point } = soil_type;

    let result = formatMessage({ id: "sensor.properties.info.soil_type" });

    Object.entries({
      max_soil_moisture,
      min_soil_moisture,
      wilting_point,
    }).forEach(([key, value]) => {
      result = result.replace(
        `{${key}}`,
        value ? formatMoisture(value) : formatMoisture(10).replace(/\d/gi, "#")
      );
    });

    return result;
  }, [formatMessage, formatMoisture, sensor?.soil_type]);

  return (
    <PageInfo>
      <div
        className="sensor-properties-info"
        dangerouslySetInnerHTML={{
          __html: formatMessage(
            { id: "sensor.properties.info" },
            {
              soil_type: soilTypeInfo,
            }
          ),
        }}
      />
    </PageInfo>
  );
}

export default SensorPropertiesInfo;
