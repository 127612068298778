import React from "react";
import { getExtrimePoints } from "../../utils/plan.utils";
import { lineLength } from "../../utils/ui.utils";
import { useUIContext } from "./UIContext";

interface PlanHelperInterface {
  currentCenter: any;
  convertPagePointToPlanPoint: Function;
  displayTouch: Function;
  zoomToPoint: Function;
  zoomToElements: Function;
}

function useUIHelper(): PlanHelperInterface {
  const {
    containerRef,
    offset,
    changeOffset,
    containerSize,
    zoom,
    changeZoom,
  } = useUIContext();

  const displayTouch = React.useCallback(
    (point: any, fill = "red", radius = 10, className = "") => {
      const node = containerRef.current?.querySelector("svg");
      if (node) {
        node.innerHTML += `<circle class="${className}" transform="translate(${point.x} ${point.y})" x="0" y="0" r="${radius}" fill="${fill}"/>`;
      }
    },
    [containerRef]
  );

  const currentCenter = React.useMemo(
    () => ({
      x: (containerSize.width / 2 - offset.x) / zoom,
      y: (containerSize.height / 2 - offset.y) / zoom,
    }),
    [containerSize.height, containerSize.width, offset.x, offset.y, zoom]
  );

  const zoomToPoint = React.useCallback(
    (newZoom: number, point: { x: number; y: number }) => {
      const validationZoom = changeZoom(newZoom);
      if (validationZoom) {
        changeOffset({
          x: offset.x - point.x * (validationZoom - zoom),
          y: offset.y - point.y * (validationZoom - zoom),
        });
      }
    },
    [changeOffset, changeZoom, offset.x, offset.y, zoom]
  );

  const convertPagePointToPlanPoint = React.useCallback(
    ({ pageX, pageY }: { pageX: number; pageY: number }) => {
      const node = containerRef.current;

      return {
        x: (pageX - offset.x - (node?.offsetLeft ?? 0)) / zoom,
        y: (pageY - offset.y - (node?.offsetTop ?? 0)) / zoom,
      };
    },
    [containerRef, offset.x, offset.y, zoom]
  );

  const zoomToElements = React.useCallback(
    (elements: any[], padding: number = 100) => {
      const { minX, maxX, minY, maxY } = getExtrimePoints(elements);

      const lineX =
        lineLength({ x: minX, y: 0 }, { x: maxX, y: 0 }) + padding * 2;

      const lineY =
        lineLength({ x: 0, y: minY }, { x: 0, y: maxY }) + padding * 2;

      const newZoom = changeZoom(
        Math.min(containerSize.width / lineX, containerSize.height / lineY)
      );

      const elementCenter = {
        x: (minX + maxX) / 2,
        y: (minY + maxY) / 2,
      };

      changeOffset({
        x: -elementCenter.x * newZoom + containerSize.width / 2,
        y: -elementCenter.y * newZoom + containerSize.height / 2,
      });
    },
    [changeOffset, changeZoom, containerSize.height, containerSize.width]
  );

  return {
    currentCenter,
    convertPagePointToPlanPoint,
    displayTouch,
    zoomToPoint,
    zoomToElements,
  };
}

export default useUIHelper;
