function getValidIntegerString(
  value: string | undefined,
  max?: number
): string {
  if (value == null) return "";

  if (!value.match(/^\d*$/)) return "";

  if (max != null && +value >= max) {
    return `${max}`;
  }

  return value;
}

function getValidHoursString(value: string) {
  const hours = +value < 10 ? `0${+value}` : value;

  return hours;
}

// valid time format === HH:MM
const timeRegex = RegExp(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/);
function convertToValidTimeFormat(value: string | undefined) {
  if (value == null) {
    return undefined;
  }

  if (timeRegex.exec(value)) {
    return value;
  }

  if (value.length === 2) {
    return `${value}:00`;
  }
}

export { getValidIntegerString, getValidHoursString, convertToValidTimeFormat };
