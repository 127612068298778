import PopupWrapper from "./PopupWrapper";

function ErrorPopup({
  Icon,
  title,
  children,
}: {
  Icon?: React.ComponentType<any>;
  title: string;
  children: string | JSX.Element;
}) {
  return (
    <PopupWrapper className="error" title={title}>
      <>
        {Icon && (
          <div className="icon">
            <Icon />
          </div>
        )}
        <div className="text">{children}</div>
      </>
    </PopupWrapper>
  );
}

export default ErrorPopup;
