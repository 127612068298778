import React from "react";
import { useMatch } from "react-router-dom";
import useRedirect from "../../../../../hooks/useRedirect";

import { usePlanStore } from "../../../../../store/PlanStore";

import ElementWrapper from "../common/ElementWrapper";
import TrenchInterface from "../../../../../store/interface/TrenchInterface";
import {
  TRENCH_PROPERTIES_PATH,
  getPipelinePropertiesURL,
} from "../../../../../utils/path.utils";

function ConnectPipeSVG({
  color,
  hasArrows = false,
  stroke = "#f5f5f5",
}: {
  color: string;
  hasArrows?: boolean;
  stroke?: string;
}) {
  return (
    <svg
      id="CONECT_PIPE"
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="37"
      viewBox="0 0 36 36"
    >
      <g id="Ellipse_411" data-name="Ellipse 411" fill={color} stroke={stroke}>
        <circle stroke="none" cx="18" cy="18" r="18" />
        <circle fill="none" cx="18" cy="18" r="17.5" />
      </g>
      <path
        id="Vereinigungsmenge_21"
        data-name="Vereinigungsmenge 21"
        fill="#f5f5f5"
        stroke="rgba(0,0,0,0)"
        strokeMiterlimit="10"
        d="M23.7,9H2.938C1.265,9,0,7.066,0,4.5S1.265,0,2.938,0H23.855a.675.675,0,0,1,.265.06c1.56.168,2.713,2.021,2.713,4.469,0,2.565-1.264,4.5-2.938,4.5C23.829,9.029,23.768,9.005,23.7,9Zm.134-7.959a.425.425,0,0,1-.043.015,1.781,1.781,0,0,0-1.126,1.055c-.007.013-.014.031-.021.044a4.034,4.034,0,0,0-.191.444c-.023.066-.041.14-.064.211-.036.117-.075.233-.1.359s-.049.263-.069.4c-.013.084-.031.163-.042.25a6.168,6.168,0,0,0,0,1.435c0,.016.006.031.007.047a5.536,5.536,0,0,0,.11.617c0,.021.011.039.016.059a5.117,5.117,0,0,0,.158.524c.023.063.051.116.077.175.046.109.09.219.142.316a3.363,3.363,0,0,0,.2.32c.017.024.033.053.05.076A1.39,1.39,0,0,0,23.815,8a.6.6,0,0,1,.078.023h0c.832,0,1.763-1.5,1.763-3.5s-.931-3.5-1.763-3.5C23.876,1.029,23.854,1.039,23.835,1.041ZM1.174,4.5c0,2,.931,3.5,1.763,3.5H22c-.007-.009-.013-.023-.02-.031a4.1,4.1,0,0,1-.376-.591c-.019-.037-.04-.073-.059-.111a5.247,5.247,0,0,1-.263-.656c-.018-.057-.04-.111-.058-.17a6.556,6.556,0,0,1-.158-.715c-.012-.07-.03-.134-.04-.205a7.647,7.647,0,0,1-.068-.991,7.17,7.17,0,0,1,.074-1.012c.008-.057.022-.107.031-.164a6.233,6.233,0,0,1,.181-.791c.011-.033.022-.066.034-.1a5.055,5.055,0,0,1,.312-.758l.015-.029A3.864,3.864,0,0,1,22.052,1H2.938C2.106,1,1.174,2.5,1.174,4.5ZM3.737,7.014v-.5h15.89v.5Z"
        transform="translate(4.583 13.486)"
      />
      {hasArrows && (
        <g id="Omnidirect_Pipe" transform="translate(-624.5 -149.84)">
          <path
            id="FF"
            d="M904.351 520.415h9.4l-2.553-2"
            fill="none"
            stroke="#fff"
            transform="translate(-267.351 -364.874)"
          />
          <path
            id="REW"
            d="M904.351 520.415h9.4l-2.553-2"
            fill="none"
            stroke="#fff"
            transform="rotate(180 775.878 338.979)"
          />
        </g>
      )}
    </svg>
  );
}

function TrenchViewPopupElement({
  element,
  close = () => {},
  selectPipeline = () => {},
}: {
  element: TrenchInterface;
  close: Function;
  selectPipeline: Function;
}) {
  const { id, pipesForPopup: pipes, centerPoint } = element;

  if (centerPoint == null || pipes == null || pipes.length === 0) return null;

  const height = Math.ceil(pipes.length / 4) * 68;
  const width = pipes.length > 3 ? 200 : pipes.length * 45 + 20;
  const paddingY = height + 35;

  return (
    <ElementWrapper
      id={id}
      className="trench-view-popup"
      x={centerPoint.x}
      y={centerPoint.y}
      size={{
        w: width,
        h: height,
        cx: 30,
        cy: paddingY,
      }}
      fill="#fff"
      useBorder={false}
    >
      <g stroke="#3e4346" strokeWidth={1}>
        <rect
          x={0}
          y={0}
          rx={20}
          ry={20}
          width={width}
          height={height}
          stroke="#052334"
          transform="rotate(0 0 0)"
          strokeLinecap="square"
          strokeLinejoin="round"
          fill="#052334"
        />
        <g
          fill="#fff"
          stroke="#fff"
          className="popup-close clickable"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            close();
          }}
          transform={`translate(${
            pipes.length > 3 ? 180 : pipes.length * 45 + 5
          }, 30)`}
          style={{
            fontSize: 20,
            letterSpacing: 0.6,
          }}
          dominantBaseline="middle"
          textAnchor="middle"
        >
          <text>
            <tspan>&#215;</tspan>
          </text>
        </g>
        <g className={`popup-container`}>
          {pipes.map(({ ids, color }, i) => {
            return (
              <g
                transform={`translate(${10 + (i % 4) * 40} ${
                  10 + Math.floor(i / 4) * 40
                }) scale(0.75)`}
                key={`trenchpopup-key-${ids[0]}`}
                className={`trench-item clickable`}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  selectPipeline(ids[0]);
                }}
              >
                <ConnectPipeSVG
                  color={color ? color : "#006CFF"}
                  hasArrows={ids != null && ids.length > 1}
                />
              </g>
            );
          })}
        </g>
        <g
          className="popup-arrow"
          transform={`translate(25 ${height}) scale(1.2)`}
        >
          <polygon
            fill="#052334"
            stroke="#052334"
            points="0 0 10 0 5 20 0 0"
          ></polygon>
        </g>
      </g>
    </ElementWrapper>
  );
}

function TrenchViewPopup() {
  const { data } = usePlanStore();
  const redirect = useRedirect();

  const trenchPropertiesMatch = useMatch(TRENCH_PROPERTIES_PATH);
  const trenchId = trenchPropertiesMatch?.params?.trenchId;

  const trench = React.useMemo(
    () => data?.trenches?.find((t) => t.id === trenchId),
    [data?.trenches, trenchId]
  );

  if (trench == null) return null;

  return (
    <TrenchViewPopupElement
      key={trench.id}
      element={trench}
      close={() => {
        redirect("/", true);
      }}
      selectPipeline={(pipeId: string) => {
        redirect(getPipelinePropertiesURL(pipeId), true);
      }}
    />
  );
}

export default TrenchViewPopup;
