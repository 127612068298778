function TextInput({
  value,
  onChange,
  onBlur,
  disabled,
  ...props
}: {
  value: string | undefined;
  onChange: Function;
  onBlur?: Function;
  disabled?: boolean;
  [key: string]: any;
}) {
  return (
    <input
      {...props}
      type="text"
      disabled={disabled}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      onBlur={(e) => onBlur?.(e.target.value)}
    />
  );
}

export default TextInput;
