import { useIntl } from "react-intl";

import Dropdown, {
  DropdownOptions,
} from "../../../../../../components/dropdown/Dropdown";
import Link from "../../../../../../components/link/Link";
import Toggle from "../../../../../../components/toggle/Toggle";
import { ValvesSettingsCircuit } from "../../../../../../store/interface/HydrawiseSettingsInterface";
import { IntegerInputWithUnit, NumberInputWithUnit } from "../../InputWithUnit";

import { ReactComponent as ArrowRightSVG } from "../../../../../../assets/arrow-right.svg";
import { ReactComponent as InformSVG } from "../../../../../../assets/inform.svg";

import NoData from "./NoData";
import {
  defaultIrrigationType,
  defaultManualAdjustmentType,
  irrigationType,
  manualAdjustmentType,
  sprinklerType,
} from "./ValvesSettingsTypes";

function IrrigationSettings({
  disabled,
  data,
  onDataChange,
  relays,
}: Readonly<{
  disabled?: boolean;
  data: { id: string; data: ValvesSettingsCircuit; modified: boolean }[];
  onDataChange: Function;
  relays: DropdownOptions;
}>) {
  const { formatMessage } = useIntl();

  if (data == null || data.length === 0) return <NoData />;

  return (
    <div className="valves-settings-table scroll">
      <div className="grid-wrapper irrigation-settings">
        <div className="grid-header">
          <div></div>
          <div className="header centered">
            {formatMessage({
              id: "controller.properties.irrigation_settings.columns.sprinkler_type.title",
            })}
          </div>
          <div className="header centered">
            {formatMessage({
              id: "controller.properties.irrigation_settings.columns.precipitation.title",
            })}
          </div>
          <div className="header centered">
            {formatMessage({
              id: "controller.properties.irrigation_settings.columns.irrigation.title",
            })}
            <Link
              href={formatMessage({
                id: "controller.properties.irrigation_settings.columns.irrigation.inform.link",
              })}
              target="__blank"
              rel="noopener noreferrer"
            >
              <InformSVG />
            </Link>
          </div>
          <div className="header centered">
            {formatMessage({
              id: "controller.properties.irrigation_settings.columns.manual_adjustment.title",
            })}
          </div>
          <div className="header centered">
            {formatMessage({
              id: "controller.properties.irrigation_settings.columns.manual_irrigation_time.title",
            })}
            <Link
              href={formatMessage({
                id: "controller.properties.irrigation_settings.columns.manual_irrigation_time.inform.link",
              })}
              target="__blank"
              rel="noopener noreferrer"
            >
              <InformSVG />
            </Link>
          </div>
          <div className="header centered">
            {formatMessage({
              id: "controller.properties.irrigation_settings.columns.activate.title",
            })}
          </div>
        </div>
        <div className="grid-body">
          {data.map((vz) => {
            const row = vz.data;
            const id = vz.id;
            const modified = vz.modified;

            const circuitType = row?.circuit_type?.value;
            const sprinklerLabelId =
              circuitType && sprinklerType[circuitType]?.label
                ? sprinklerType[circuitType]?.label
                : "controller.properties.irrigation_settings.type.unknown";
            const sprinklerLabel = formatMessage({
              id: sprinklerLabelId,
            });

            return (
              <div
                key={id}
                className={`grid-row ${modified ? "data-changed" : ""}`}
              >
                <div className="valve-assign">
                  <div
                    className="color-circle"
                    style={{ background: row.color.value }}
                  />
                  <div className="arrow">
                    <ArrowRightSVG width="100%" height="100%" />
                  </div>
                  <Dropdown
                    disabled={disabled}
                    value={row.relay_num?.value}
                    onChange={(value: number) =>
                      onDataChange(id, "relay_num", value)
                    }
                    data={[{ value: null, label: " " }, ...relays]}
                  />
                </div>
                <div className="centered">{sprinklerLabel}</div>
                <div className="centered">
                  <NumberInputWithUnit
                    disabled={disabled}
                    className="centered"
                    onBlur={(value) =>
                      onDataChange(
                        id,
                        "et_env_precipitation_in_lm2_per_h",
                        value
                      )
                    }
                    defaultValue={row.et_env_precipitation_in_lm2_per_h?.value}
                    valueUnit={`[${formatMessage({
                      id: "common.units.lm2_per_h",
                    })}]`}
                    max={100}
                  />
                </div>
                <div className="centered">
                  <div>
                    <Dropdown
                      disabled={disabled}
                      data={irrigationType.map((it) => ({
                        value: it.value,
                        label: formatMessage({ id: it.label }),
                      }))}
                      value={
                        row.et_env_irrigation_type?.value ??
                        defaultIrrigationType
                      }
                      onChange={(value) =>
                        onDataChange(id, "et_env_irrigation_type", value)
                      }
                    />
                  </div>
                </div>
                <div className="centered">
                  <div>
                    <Dropdown
                      disabled={disabled}
                      data={manualAdjustmentType}
                      value={
                        row.et_env_manual_adj_coef?.value ??
                        defaultManualAdjustmentType
                      }
                      onChange={(value) =>
                        onDataChange(id, "et_env_manual_adj_coef", value)
                      }
                    />
                  </div>
                </div>
                <div className="centered">
                  <IntegerInputWithUnit
                    disabled={disabled}
                    onBlur={(value: any) => {
                      if (value == null || value === "") {
                        value = 0;
                      }

                      onDataChange(
                        id,
                        "et_env_manual_irrigation_time_in_min",
                        value
                      );
                    }}
                    defaultValue={
                      row.et_env_manual_irrigation_time_in_min?.value
                    }
                    valueUnit="min"
                  />
                </div>
                <div className="centered">
                  <Toggle
                    disabled={disabled}
                    value={row.et_env_enabled?.value}
                    onChange={(value) =>
                      onDataChange(id, "et_env_enabled", value)
                    }
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default IrrigationSettings;
