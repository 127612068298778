function convertToNumber(value: string, separator: string | undefined) {
  let tValue = value.replace(/^0(\d)/, "$1").trim();

  if (separator) {
    tValue = tValue.replace(separator, ".");
  }

  return !tValue ? undefined : +tValue;
}

function convertToString(
  value: number | undefined,
  separator: string | undefined,
  isInteger: boolean = false,
  precision: number = 1
) {
  const number = isInteger ? value : value?.toFixed(precision);
  const tValue = number ? number + "" : "";

  return separator ? tValue.replace(".", separator) : tValue;
}

function validateIntegerValue(value: string | undefined, isNegative: boolean) {
  let tValue = value != null ? value + "" : "";
  let rPattern = new RegExp(`[^\\d]+`);
  let nPattern = new RegExp(`[^-\\d]+`);

  tValue = tValue.replace(isNegative ? nPattern : rPattern, "");

  let pattern = isNegative ? new RegExp("-?\\d+") : new RegExp("\\d+");

  const valueMatch = tValue.match(pattern);
  if (valueMatch != null) tValue = valueMatch[0];

  return tValue;
}

function validateNumberValue(
  value: string | undefined,
  separator: string,
  isNegative: boolean,
  precision: number
) {
  let tValue = value != null ? (value + "").replace(".", separator) : "";
  const separatorPattern = separator === "." ? "\\." : separator;

  let rPattern = new RegExp(`[^\\d${separatorPattern}]+`);
  let nPattern = new RegExp(`[^-\\d${separatorPattern}]+`);

  tValue = tValue.replace(isNegative ? nPattern : rPattern, "");

  // precision
  let pattern = isNegative
    ? new RegExp("-?\\d+" + separatorPattern + "?\\d{0," + precision + "}")
    : new RegExp("\\d+" + separatorPattern + "?\\d{0," + precision + "}");

  const valueMatch = tValue.match(pattern);
  if (valueMatch != null) tValue = valueMatch[0];

  return tValue;
}

export {
  validateIntegerValue,
  validateNumberValue,
  convertToNumber,
  convertToString,
};
