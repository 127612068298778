import * as React from "react";

const iframeRedirect =
  window.self !== window.top
    ? (href) => {
        window.parent.postMessage(
          {
            action: "redirect",
            value: href,
          },
          "*"
        );
      }
    : undefined;

const iframeReplaceState =
  window.self !== window.top
    ? (href) => {
        window.parent.postMessage(
          {
            action: "replace_state",
            value: href,
          },
          "*"
        );
      }
    : undefined;

function redirectTo(href) {
  if (typeof iframeRedirect === "function") {
    iframeRedirect(href);
    return;
  }

  window.location.href = href;
}

function replaceState(url) {
  if (typeof iframeReplaceState === "function") {
    iframeReplaceState(url);
  }

  window.history.replaceState(null, "", url);
}

function downloadFileFromBase64(base64url, filename) {
  if (window.self !== window.top) {
    window.parent.postMessage(
      {
        action: "download",
        value: {
          base64url,
          filename,
        },
      },
      "*"
    );
  } else {
    const link = document.createElement("a");
    link.href = base64url;
    link.download = filename;
    link.click();
  }
}

function Link({
  href,
  onClick,
  children,
  ...props
}: {
  href: string;
  onClick?: any; // FIXME: fix type
  children?: React.ReactElement | React.ReactElement[];
  [key: string]: any;
}) {
  const clickHandler = React.useMemo(() => {
    if (typeof onClick === "function") return onClick;

    if (typeof iframeRedirect === "function") {
      return (e) => {
        e.preventDefault();
        if (e.stopPropogation) e.stopPropogation();

        iframeRedirect(href);
        return;
      };
    }

    return undefined;
  }, [href, onClick]);

  return (
    <a {...props} href={href} onClick={clickHandler}>
      {children}
    </a>
  );
}

export { Link as default, redirectTo, downloadFileFromBase64, replaceState };
