import React from "react";
import useRedirect from "../../hooks/useRedirect";

function Redirect({ to, replace = false }: { to: string; replace?: boolean }) {
  const redirect = useRedirect();

  React.useEffect(() => {
    redirect(to, replace);
  }, [to, redirect, replace]);

  return null;
}

export default Redirect;
