import React from "react";
import { useDraggable } from "react-draggable-hoc";
import { useUIContext } from "../../../store/uiStore/UIContext";

function Draggable({ children }: { children: JSX.Element | JSX.Element[] }) {
  const { offset, changeOffset, draggable, containerRef } = useUIContext();

  // const containerRef = React.useRef() as RefObject<HTMLDivElement>;

  const startPosition = React.useRef(offset);
  useDraggable(containerRef, {
    dragProps: draggable ? 1 : 0,
    disabled: !draggable,
    onDragStart: () => {
      startPosition.current = offset;
    },
    onDrag: ({ deltaX, deltaY }) => {
      changeOffset({
        x: startPosition.current.x + deltaX,
        y: startPosition.current.y + deltaY,
      });
    },
    onDrop: () => {
      // afterMove(offset);
    },
  });

  return (
    <div
      ref={containerRef}
      className={["plan-container", !!draggable ? "draggable" : undefined]
        .filter((e) => e)
        .join(" ")}
    >
      {children}
    </div>
  );
}

export default Draggable;
