import React, { RefObject } from "react";

import { cutLineFromSegments } from "@dvsproj/ipat-core/geometryUtils";
import Trench from "./Trench";
import TrenchInterface from "../../../../../store/interface/TrenchInterface";

function PipeLine({
  line,
  segments,
  lineOptions,
  selected,
  select,
}: {
  line: {
    id: number | string;
    path?: string;
    [key: string]: any;
  };
  segments?:
    | {
        start: { x: number; y: number };
        end: { x: number; y: number };
      }[]
    | undefined;
  lineOptions: {
    color?: string | undefined;
    strokeWidth?: number | undefined;
  };
  selected?: boolean;
  select?: Function | undefined;
}) {
  const ref = React.useRef() as RefObject<SVGGElement>;
  let path = line.path;
  if (segments && segments.length > 0) {
    path = segments.reduce((acc, curr) => {
      return (
        acc +
        ("M " +
          curr.start.x +
          " " +
          curr.start.y +
          " L " +
          curr.end.x +
          " " +
          curr.end.y +
          " ")
      );
    }, "");
    path += "Z";
  }

  if (path == null) return null;

  const strokeWidth = lineOptions.strokeWidth ?? 1;

  return (
    <g className="element pipe-element">
      <g
        ref={ref}
        id={`line-${line.id}`}
        className={typeof select === "function" ? "clickable" : undefined}
        onClick={
          typeof select === "function"
            ? (e) => {
                e.preventDefault();
                e.stopPropagation();
                select();
              }
            : undefined
        }
      >
        {(typeof select === "function" || selected) && (
          <path
            d={path}
            stroke={selected ? "#000" : "transparent"}
            strokeWidth={strokeWidth + 1}
            fill="none"
          />
        )}
        <path
          d={path}
          stroke={lineOptions.color}
          strokeWidth={strokeWidth}
          fill="none"
        />
      </g>
    </g>
  );
}

function PipeLines({
  lines,
  lineOptions,
  trenches,
  selected,
  select,
}: {
  lines?: {
    id: string | number;
    path?: string;
    [key: string]: any;
  }[];
  lineOptions: {
    color?: string | undefined;
    strokeWidth?: number | undefined;
  };
  trenches: TrenchInterface[];
  selected?: boolean;
  select?: Function | undefined;
}) {
  if (lines?.length === 0) return null;

  const trenchesThreshold = 10; //px

  const trenchesHasLineId = (lineId: string | number) => {
    const result = trenches?.filter(
      (t) => t.pipes?.map((el) => el.id).find((id) => id === lineId) != null
    );
    return result;
  };

  const visibleSegments = (
    line: { id: string | number; path?: string; [key: string]: any },
    lineTrenches: TrenchInterface[] | null
  ) => {
    if (line.startPoint == null || line.endPoint == null) return;

    let segments = [{ start: line.startPoint, end: line.endPoint }];
    if (lineTrenches) {
      let filteredTrenches = lineTrenches.filter(
        (el) => el.pipesForPopup.length > 1
      );
      for (let trench of filteredTrenches) {
        if (trench.isValid) {
          segments = cutLineFromSegments(segments, trench, trenchesThreshold);
        }
      }
    }
    return segments;
  };

  return (
    <>
      {lines!.map(
        (line: { id: string | number; path?: string; [key: string]: any }) => {
          let allTrenches = selected ? null : trenchesHasLineId(line.id);
          let segments = visibleSegments(line, allTrenches);

          return (
            <g key={`lines-${line.id}`}>
              {(segments ?? [])?.length > 0 ? (
                <PipeLine
                  key={`line-${line.id}`}
                  line={line}
                  lineOptions={lineOptions}
                  segments={segments}
                  selected={selected}
                  select={select}
                />
              ) : null}
              {allTrenches != null
                ? allTrenches
                    .filter(
                      (t) =>
                        t.pipes?.[0]?.id === line.id &&
                        t.pipesForPopup.length > 1
                    )
                    .map((trench) => {
                      return (
                        trench && (
                          <Trench
                            key={`trench-${trench.id}`}
                            element={trench}
                            lineVisible={true}
                            countVisible={false}
                          />
                        )
                      );
                    })
                : null}
            </g>
          );
        }
      )}
    </>
  );
}

export default PipeLines;
