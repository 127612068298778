import React, { RefObject } from "react";
import TooltipInterface from "./TooltipInterface";

const TooltipComponent = ({ tooltip }: { tooltip: TooltipInterface }) => {
  const tooltipRef = React.useRef() as RefObject<HTMLDivElement>;

  React.useEffect(() => {
    const node = tooltipRef.current;

    if (node) {
      const { position, type } = tooltip;

      // top
      // node.style.left = position.x + position.width / 2 + "px";
      // node.style.top = position.y - node.clientHeight + "px";

      switch (type) {
        case "bottom":
          node.style.left = position.x + position.width / 2 + "px";
          node.style.top = position.y + position.height + "px";
          break;
        case "top":
          node.style.left = position.x + position.width / 2 + "px";
          node.style.top = position.y - node.clientHeight + "px";
          break;
        case "right":
          node.style.left = position.x + position.width + "px";
          node.style.top =
            position.y + position.height / 2 - node.clientHeight / 2 + "px";
          break;
        case "left":
          node.style.left = position.x - node.clientWidth + "px";
          node.style.top =
            position.y + position.height / 2 - node.clientHeight / 2 + "px";
          break;
        default:
          break;
      }

      node.style.opacity = "1";
      node.style.visibility = "visible";
    }
  }, [tooltip]);

  return (
    <div ref={tooltipRef} className={`tooltip-wrap ${tooltip.type ?? "top"}`}>
      <div className="tooltip-content">
        <div
          className={`tooltip-text`}
          dangerouslySetInnerHTML={{
            __html: tooltip.text,
          }}
        ></div>
        <div className="tooltip-arrow">
          <span>▲</span>
        </div>
      </div>
    </div>
  );
};

export default TooltipComponent;
