import { ReactComponent as WaterFilterOkSVG } from "../../../../assets/water-filter-ok.svg";
import { ReactComponent as WaterFilterLowPressureSVG } from "../../../../assets/water-filter-low-pressure.svg";
import { ReactComponent as WaterFilterNoDataSVG } from "../../../../assets/water-filter-no-data.svg";
import { ReactComponent as WaterFilterPressureDropSVG } from "../../../../assets/water-filter-pressure-drop.svg";

const TEXT_BY_STATUS = {
  ok: {
    Icon: WaterFilterOkSVG,
    text: { id: "waterfilter.status.ok" },
    color: "#c5d75d",
  },
  low_inlet_pressure: {
    Icon: WaterFilterLowPressureSVG,
    text: { id: "waterfilter.status.inlet_pressure" },
    color: "#e3242b",
  },
  nok: {
    Icon: (props: any) => <WaterFilterNoDataSVG {...props} />,
    text: { id: "waterfilter.status.nok" },
    color: "#c3c3c3",
  },
  pressure_drop: {
    Icon: WaterFilterPressureDropSVG,
    text: { id: "waterfilter.status.pressure_drop" },
    color: "#ff7600",
  },
};

export default TEXT_BY_STATUS;
