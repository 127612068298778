import { v4 as uuid } from "uuid";
import AreaInterface from "../interface/AreaInterface";

import { sizeInMetersByPixel } from "@dvsproj/ipat-core/planUtils";
import {
  rotatePoint,
  polygonSquare,
  linesByPoints,
  convertBezierToPoligon,
  calculatePointsDirection,
} from "@dvsproj/ipat-core/geometryUtils";
import {
  generateElementPath,
  IRRIGATION_QUANTITY,
} from "@dvsproj/ipat-core/areaUtils";

function areaFactory(
  area: {
    id?: string | undefined;
    type?: string | undefined;
    areaType: string;
    quantity?: string | undefined;
    x?: number | undefined;
    y?: number | undefined;
    startAngle?: number | undefined;
    width?: number | undefined;
    height?: number | undefined;
    points?: any[] | undefined;
    crossability?: boolean | undefined;
    description?: string | undefined;
    enclosed?: boolean | undefined;
    disabled?: boolean | undefined;
  },
  scale: number
): AreaInterface {
  const canBeNonCrossible =
    IRRIGATION_QUANTITY[area.quantity].canBeNonCrossible;
  const isCircle = area.areaType === "circle";
  const isRectangle = area.areaType === "rectangle";
  const isFreeDrawing = !(isCircle || isRectangle);
  const lines =
    isFreeDrawing && area.points && area.points.length > 0
      ? linesByPoints(area.points, area.enclosed, true)
      : null;
  return {
    id: area.id ?? "area-" + uuid(),
    type: area.type ?? "area",
    areaType: area.areaType,
    quantity: area.quantity ?? "should",
    x: area.x,
    y: area.y,
    startAngle: area.startAngle ? area.startAngle % 360 : 0,
    width: area.width,
    height: area.height,
    points: area.points,
    description: area.description ?? "",
    enclosed: area.enclosed ?? true,
    disabled: area.disabled ?? false,

    get crossability() {
      return canBeNonCrossible
        ? area.crossability
        : IRRIGATION_QUANTITY[area.quantity].crossability;
    },
    get color() {
      return IRRIGATION_QUANTITY[area.quantity].color;
    },
    get extremePoints() {
      let acc: any[] = [];

      if (!isFreeDrawing) {
        acc.push(
          ...[
            rotatePoint(
              { x: area.x! - area.width! / 2, y: area.y! - area.height! / 2 },
              { x: area.x, y: area.y },
              area.startAngle
            ),
            rotatePoint(
              { x: area.x! + area.width! / 2, y: area.y! - area.height! / 2 },
              { x: area.x, y: area.y },
              area.startAngle
            ),
            rotatePoint(
              { x: area.x! + area.width! / 2, y: area.y! + area.height! / 2 },
              { x: area.x, y: area.y },
              area.startAngle
            ),
            rotatePoint(
              { x: area.x! - area.width! / 2, y: area.y! + area.height! / 2 },
              { x: area.x, y: area.y },
              area.startAngle
            ),
          ]
        );
      } else {
        acc.push(...(area.points ?? []).map((p) => ({ x: p.x, y: p.y })));
      }
      return acc;
    },
    get isCircle() {
      return area.areaType === "circle";
    },
    get isRectangle() {
      return area.areaType === "rectangle";
    },
    get isFreeDrawing() {
      return isFreeDrawing;
    },
    get lines() {
      return lines;
    },
    get path() {
      const path = generateElementPath(area);
      return path;
    },
    get pointsDirection() {
      if (!(area.points && area.points.length > 3)) {
        return -1;
      }

      let points = area.points.filter((p) => !p.isControlPoint);
      if (points && points.length > 0) {
        return -1;
      }

      const result = calculatePointsDirection(points);
      return result != null && result >= 0 ? -1 : 1;
    },
    get pointsCenter() {
      if (area.points && area.points.length > 3 && area.enclosed) {
        const p = area.points.filter((p) => !p.isControlPoint || !p.isDefault);
        //centroid of a polygon
        let sum: any = null;
        try {
          sum = p
            .slice()
            .concat([p[0]])
            .reduce(
              (acc, obj) => {
                const { x, y } = obj.isControlPoint
                  ? obj.bezierCurveCenterPoint
                  : obj;
                if (acc && acc.lpx && acc.lpy) {
                  const a = acc.lpx * y - x * acc.lpy;
                  return {
                    x: acc.x + (x + acc.lpx) * a,
                    y: acc.y + (y + acc.lpy) * a,
                    sa: acc.sa + a,
                    lpx: x,
                    lpy: y,
                  };
                } else return { ...acc, lpx: x, lpy: y };
              },
              { x: 0, y: 0, sa: 0, lpx: null, lpy: null }
            );
        } catch (e) {
          console.error(e);
        }
        return sum
          ? { x: sum.x / (6.0 * 0.5 * sum.sa), y: sum.y / (6.0 * 0.5 * sum.sa) }
          : null;
      }
      return undefined;
    },
    get isLawnArea() {
      return area.quantity != null && area.quantity === "should";
    },
    get isDriplineArea() {
      return area.quantity != null && area.quantity === "dripline";
    },
    //calculate area square
    get size() {
      let size = 0;
      if (isFreeDrawing) {
        const points = convertBezierToPoligon(area.points, 5).map(
          ({ x, y }) => {
            return {
              x: sizeInMetersByPixel(x, scale),
              y: sizeInMetersByPixel(y, scale),
            };
          }
        );
        size = polygonSquare(points);
      } else if (isRectangle) {
        size =
          sizeInMetersByPixel(area.width!, scale) *
          sizeInMetersByPixel(area.height!, scale);
      } else if (isCircle) {
        size =
          Math.PI *
          sizeInMetersByPixel(area.width! / 2, scale) *
          sizeInMetersByPixel(area.height! / 2, scale);
      }

      return size;
    },
    //calculate area perimeter in meter
    get perimeter() {
      const widthInMeter = sizeInMetersByPixel(area.width, scale);
      const heightInMeter = sizeInMetersByPixel(area.height, scale);

      let perimeter = 0;
      if (isFreeDrawing) {
        lines.forEach(
          ({ length }) => (perimeter += sizeInMetersByPixel(length, scale))
        );
      } else if (isRectangle) {
        perimeter = widthInMeter * 2 + heightInMeter * 2;
      } else if (isCircle) {
        perimeter =
          2 *
          Math.PI *
          Math.sqrt(
            (widthInMeter * widthInMeter + heightInMeter * heightInMeter) / 8
          );
      }

      return perimeter;
    },
  };
}

export default areaFactory;
