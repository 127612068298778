import { useIntl } from "react-intl";
import { useUIContext } from "../../../../store/uiStore/UIContext";

import ToolItem from "./ToolItem";

import { ReactComponent as HandSVG } from "../../../../assets/hand.svg";
import useRedirect from "../../../../hooks/useRedirect";

function HandTool() {
  const { formatMessage } = useIntl();
  const { handTool, changeHandTool } = useUIContext();
  const redirect = useRedirect();

  return (
    <ToolItem
      hasActive={handTool}
      Icon={HandSVG}
      onClick={() => {
        changeHandTool(!handTool);
        redirect("/", true);
      }}
      tooltip={formatMessage({ id: "tools.hand.tooltip" })}
    />
  );
}

export default HandTool;
