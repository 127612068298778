import { useTooltip } from "../../../../components/tooltip/Tooltip";

function ToolItem({
  hasActive,
  onClick,
  Icon,
  disabled = false,
  tooltip,
}: {
  hasActive: boolean;
  onClick?: Function;
  Icon: React.ComponentType<any>;
  disabled?: boolean;
  tooltip?: string;
}) {
  const { showTooltip, hideTooltip } = useTooltip();

  return (
    <button
      type="button"
      className={[
        "tool",
        hasActive ? "active" : undefined,
        disabled ? "disabled" : undefined,
      ]
        .filter((e) => e)
        .join(" ")}
      onClick={() => {
        if (typeof onClick === "function") {
          onClick();
        }
      }}
      onMouseEnter={tooltip ? (e) => showTooltip(e, tooltip) : undefined}
      onMouseLeave={hideTooltip}
    >
      <Icon />
    </button>
  );
}

export default ToolItem;
