/* eslint-disable react-hooks/exhaustive-deps */
import React, { RefObject } from "react";

function useAutoFocus(ref: RefObject<any> | undefined, ...deps: any[]) {
  React.useEffect(() => {
    if (ref) {
      let node = ref.current;
      if (node) {
        node.focus();
      }
    }
  }, deps);
}

export default useAutoFocus;
