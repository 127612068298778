const userMock = {
  username: "Max Musterman",
  email: "test@test.test",
  role: "user",
  showtutorial: 1,
  loginPageUrl: "$SHOP_URL",
  auth: true,
};

export default userMock;
