import React from "react";
import { useMatch } from "react-router-dom";
import { usePlanStore } from "../../../../../store/PlanStore";
import { useUIContext } from "../../../../../store/uiStore/UIContext";
import useRedirect from "../../../../../hooks/useRedirect";

import {
  getFertilizerInfoURL,
  getFertilizerPropertiesURL,
  FERTILIZER_INFO_PATH,
  FERTILIZER_PROPERTIES_PATH,
} from "../../../../../utils/path.utils";
import TEXT_BY_STATUS from "../../../page-info/fertilizer-info/FertilizerStatuses";

import { FertilizerStatus } from "../../../../../store/interface/FertilizerDataInterface";
import { ReactComponent as FertilizerSVG } from "../../../../../assets/fertilizer-sensor.svg";
import ElementWrapper from "../common/ElementWrapper";

function Fertilizer({
  id,
  x,
  y,
  selected,
  select,
  invalid,
  status,
}: {
  id: number | string;
  x: number;
  y: number;
  selected: boolean;
  select: Function | undefined;
  invalid: boolean | undefined;
  status: FertilizerStatus | undefined;
}) {
  const statusOptions =
    TEXT_BY_STATUS[status ?? "nok"] ?? TEXT_BY_STATUS["nok"];

  const sensorColor = React.useMemo(() => {
    if (invalid) return "#3e4346";

    return statusOptions.color;
  }, [invalid, statusOptions]);

  const colorFill = React.useMemo(() => {
    if (invalid) return "#000";
    return "#26791c";
  }, [invalid]);

  return (
    <ElementWrapper
      id={id}
      x={x}
      y={y}
      size={32}
      select={select}
      selected={selected}
      fill={sensorColor}
    >
      <g transform="translate(2, 2)">
        <FertilizerSVG width={28} height={28} fill={colorFill} />

        {!invalid && status === "nok" && (
          <g transform="translate(14 -3)">
            <g>
              <path
                d="M24.364,0A5.641,5.641,0,1,0,30,5.641,5.647,5.647,0,0,0,24.364,0"
                transform="translate(-17.725)"
                fill="#ff7600"
              />
              <path
                d="M26.226,7.155l-.409-2.836V2.473h1.625V4.319l-.372,2.836Zm-.382.707h1.6v1.6h-1.6Z"
                transform="translate(-20.111 -0.832)"
                fill="#f5f5f5"
              />
            </g>
          </g>
        )}
      </g>
    </ElementWrapper>
  );
}

function FertilizerElements() {
  const { fertilizer } = usePlanStore();
  const { handTool } = useUIContext();
  const redirect = useRedirect();

  const fertilizerInfoMatch = useMatch(FERTILIZER_INFO_PATH);
  const fertilizerPropertiesMatch = useMatch(FERTILIZER_PROPERTIES_PATH);

  const selected_fertilizer_id = React.useMemo(
    () =>
      fertilizerPropertiesMatch?.params?.fertilizer_id ??
      fertilizerInfoMatch?.params?.fertilizer_id,
    [
      fertilizerInfoMatch?.params?.fertilizer_id,
      fertilizerPropertiesMatch?.params?.fertilizer_id,
    ]
  );

  if (!fertilizer) return null;

  return (
    <Fertilizer
      key={fertilizer.id}
      id={fertilizer.id}
      x={fertilizer.x}
      y={fertilizer.y}
      selected={selected_fertilizer_id === fertilizer.id}
      select={
        !handTool
          ? () => {
              redirect(
                fertilizer.is_fill_properties
                  ? getFertilizerPropertiesURL(fertilizer.id)
                  : getFertilizerInfoURL(fertilizer.id),
                true
              );
            }
          : undefined
      }
      status={fertilizer.status}
      invalid={fertilizer.is_fill_properties}
    />
  );
}

export default FertilizerElements;
