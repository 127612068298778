import { radiansFromDegree, rotateVector } from "./geometry.utils";

/**
 * Calculates all corner points of the rectangle (even for ellipsis)
 *
 * @param {*} area
 * @param {boolean} withControlPoints - add isDefault:true control points into array
 */
function* rectangleCorners(area: any, withControlPoints: boolean = false) {
  if (area == null) return undefined;
  // precalculate values
  const w = area.width / 2;
  const h = area.height / 2;
  const a = radiansFromDegree(area.startAngle);
  const variations = withControlPoints
    ? [
        [-1, -1],
        [-1, 0],
        [-1, 1],
        [0, 1],
        [1, 1],
        [1, 0],
        [1, -1],
        [0, -1],
      ]
    : [
        [-1, -1],
        [-1, 1],
        [1, 1],
        [1, -1],
      ];
  for (let [i, j] of variations) {
    const [x, y] = rotateVector(i * w, j * h, a, -1);
    yield {
      x: area.x + x,
      y: area.y + y,
      type: i !== 0 && j !== 0 ? "point" : "control-point",
      isDefault: true,
    };
  }
}

export { rectangleCorners };
