class IframeApi {
  redirectTo(href: string, options: { replace: boolean }) {
    window.parent.postMessage(
      {
        action: options.replace ? "replace_state" : "redirect",
        value: href,
      },
      "*"
    );
  }

  downloadFileFromBase64(base64url: string, filename: string) {
    window.parent.postMessage(
      {
        action: "download",
        value: {
          base64url,
          filename,
        },
      },
      "*"
    );
  }
}

const iframeApi = new IframeApi();
export default iframeApi;
