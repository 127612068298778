import { rectangleCorners } from "./area.utils";
import { round } from "./ui.utils";

const svgTextCondensed = {
  transform: "scale(.85 1)",
  style: {
    fontStretch: "normal",
  },
};

function pixelSizeByZoom(px: number, scale: number) {
  return px * scale;
}

function convertMetersToPixel(distance: number, scale: number) {
  return distance * scale;
}

function convertPixelToMeters(
  px: number,
  scale: number,
  precision: number | undefined = 1
) {
  return round(px / scale, precision);
}

interface PointInterface {
  x: number;
  y: number;
}

function getExtrimePoints(elements: any[]): {
  minX: number;
  minY: number;
  maxX: number;
  maxY: number;
} {
  const updateFromPoint = (r: any, x: number, y: number) => {
    if (r.minX == null || r.minX > x) r.minX = x;
    if (r.maxX == null || r.maxX < x) r.maxX = x;
    if (r.minY == null || r.minY > y) r.minY = y;
    if (r.maxY == null || r.maxY < y) r.maxY = y;
  };

  return elements.reduce((r, element: any) => {
    switch (element.type) {
      case "area":
        if (["circle", "rectangle"].indexOf(element.areaType) < 0) {
          element.points.forEach((p: any) => updateFromPoint(r, p.x, p.y));
        } else {
          for (let { x, y } of rectangleCorners(element)) {
            updateFromPoint(r, x, y);
          }
        }
        break;
      case "sector":
      case "irrigationValveCable":
      case "tubing":
      case "pipeline":
        element.points.forEach((p: PointInterface) =>
          updateFromPoint(r, p.x, p.y)
        );
        break;
      case "sensor":
      case "sprinkler":
      case "system-element":
      case "pipeline-point":
        updateFromPoint(r, element.x, element.y);
        break;
      default:
        break;
    }
    return r;
  }, {});
}

/**
 *
 * @param scale How many pixels in one meter
 * @param zoom
 * @returns
 */
const generateGrid = (scale: number, zoom: number) => {
  const sizesInM = [0.1, 0.2, 0.5, 1, 2, 5, 10, 20, 25, 50, 100, 200, 400];

  const maxSizeInPx = 93;

  const size = Math.max(
    ...sizesInM.filter((v) => {
      const gridSize = convertMetersToPixel(v, scale);
      const gridSizeByZoom = pixelSizeByZoom(gridSize, zoom);

      return gridSizeByZoom < maxSizeInPx;
    })
  );

  return {
    size: convertMetersToPixel(size, scale),
    value: size,
  };
};

export {
  svgTextCondensed,
  pixelSizeByZoom,
  convertMetersToPixel,
  convertPixelToMeters,
  getExtrimePoints,
  generateGrid,
};
