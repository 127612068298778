import { usePlanStore } from "../../../../../store/PlanStore";

import { ReactComponent as ValveBoxSVG } from "../../../../../assets/valvebox.svg";
import ElementWrapper from "../common/ElementWrapper";

function ValveBox({ id, x, y }: { id: number | string; x: number; y: number }) {
  return (
    <ElementWrapper id={id} x={x} y={y} size={20}>
      <ValveBoxSVG width={20} height={20} />
    </ElementWrapper>
  );
}

function ValveboxElements() {
  const { data } = usePlanStore();

  const valveboxes =
    data?.elements.filter(
      (e) => e.type === "system-element" && e.systemType === "valve-box"
    ) ?? [];

  if (valveboxes.length === 0) return null;

  return (
    <>
      {valveboxes.map((valvebox) => (
        <ValveBox
          key={valvebox.id}
          id={valvebox.id}
          x={valvebox.x}
          y={valvebox.y}
        />
      ))}
    </>
  );
}

export default ValveboxElements;
