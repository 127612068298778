import React from "react";
import { useIntl } from "react-intl";

import { ReactComponent as AvatarSVG } from "../../../assets/avatar.svg";
import { ReactComponent as SmartGardenLogoSVG } from "../../../assets/smart-garden-logo.svg";
import { ReactComponent as ToPlanLogoSVG } from "../../../assets/to-plan-logo.svg";

import RedirectLink from "../../../components/redirect/RedirectLink";
import useFetch from "../../../hooks/useFetch";
import calcApi from "../../../service/calcApi";
import { usePlanStore } from "../../../store/PlanStore";
import { useUserStore } from "../../../store/UserStore";
import { urlDecorator } from "../../../utils/http.utils";
import { getPlanerURL } from "../../../utils/path.utils";
import Logo from "./Logo";

import "./header.scss";

function Header() {
  const { formatMessage, locale } = useIntl();

  const userStore = useUserStore();
  const { planId, data } = usePlanStore();

  const updateUserEmail = React.useCallback(() => {
    if (planId == null || userStore?.user?.email == null) return;

    calcApi.saveNotificationProperties(
      planId,
      userStore?.user?.email,
      locale,
      undefined,
      userStore.requestLogin
    );
  }, [locale, planId, userStore?.requestLogin, userStore?.user?.email]);

  useFetch(updateUserEmail);

  return (
    <header>
      <div className="container">
        <Logo key={data?.planName} planName={data?.planName ?? ""} />

        <div className="smart-garden header-col">
          <SmartGardenLogoSVG />
          <span>{formatMessage({ id: "header.garden" })}</span>
        </div>

        <div className="right-col">
          {data && (
            <RedirectLink
              className="to-plan header-col"
              target="planer"
              href={getPlanerURL(planId)}
            >
              <ToPlanLogoSVG />
              <div className="title">
                {formatMessage({ id: "header.plan" })}
              </div>
            </RedirectLink>
          )}

          <RedirectLink
            className="user header-col"
            href={urlDecorator(`$SHOP_URL/jtl.php`)}
          >
            <AvatarSVG />
            <div className="title">{userStore?.user?.username}</div>
          </RedirectLink>
        </div>
      </div>
    </header>
  );
}

export default Header;
