import { round } from "./ui.utils";

/**
 * Converts from degree to radians.
 *
 * @param {*} degree
 */
const radiansFromDegree = (degree: number) => {
  return (degree * Math.PI) / 180;
};

/**
 * Rotates a vector clockwise in the left-handed (canvas/svg) coordinate system.
 *
 * @param {*} x
 * @param {*} y
 * @param {*} a
 * @param {*} digits
 */
const rotateVector = (x: number, y: number, a: number, digits: number = 0) => {
  const r = [
    x * Math.cos(a) - y * Math.sin(a),
    x * Math.sin(a) + y * Math.cos(a),
  ];

  return digits > 0 ? r.map((e) => round(e, digits)) : r;
};

export { radiansFromDegree, rotateVector };
