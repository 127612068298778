import React, { CSSProperties, MutableRefObject, RefObject } from "react";

import * as Highcharts from "highcharts/highstock";
import { useIntl } from "react-intl";
import {
  highchartAxisStyled,
  highchartStyled,
} from "../../../../../utils/highchart.styled";
import useFormatter from "../../../../../hooks/useFormatter";

interface DataItem {
  date: number;
  moisture1: number;
  moisture2: number;
  temperature: number;
}

interface HighchartOptions {
  wilting_point: number;
  min_soil_moisture: number;
  max_soil_moisture: number;
}

function displayHighchart(
  containerId: Highcharts.HTMLDOMElement,
  series: any,
  rangeButtonsTitle: {
    "1_week": string;
    "1_month": string;
    "3_month": string;
    "6_month": string;
  },
  moistureChartTitle: string,
  temperatureChartTitle: string,
  moisturePlotBands: any,
  lang: any | undefined
) {
  Highcharts.setOptions({
    lang: {
      rangeSelectorFrom: "",
      rangeSelectorTo: ">",
      decimalPoint: lang?.decimalPoint,
    },
  });

  // create the chart
  return Highcharts.stockChart(
    containerId,
    {
      ...highchartStyled,
      accessibility: {
        ...highchartStyled.accessibility,
        enabled: false,
      },

      chart: {
        ...highchartStyled.chart,
        animation: true,
        marginLeft: 50,
        marginRight: 0,
      },

      time: {
        timezone: lang.timezone,
        useUTC: false,
      },

      rangeSelector: {
        ...highchartStyled.rangeSelector,
        buttons: [
          {
            type: "week",
            count: 1,
            text: rangeButtonsTitle["1_week"],
            dataGrouping: {
              forced: true,
              units: [
                ["minute", [1, 5, 10, 20, 30]],
                ["hour", [1, 2, 3, 4, 6, 8, 12]],
                ["day", [1]],
              ],
            },
          },
          {
            type: "month",
            count: 1,
            text: rangeButtonsTitle["1_month"],
            dataGrouping: {
              forced: true,
              units: [
                ["day", [1]],
                ["week", [1]],
              ],
            },
          },
          {
            type: "month",
            count: 3,
            text: rangeButtonsTitle["3_month"],
            dataGrouping: {
              forced: true,
              units: [["week", [1]]],
            },
          },
          {
            type: "month",
            count: 6,
            text: rangeButtonsTitle["6_month"],
            dataGrouping: {
              forced: true,
              units: [["month", [1]]],
            },
          },
        ],
        selected: 0,
        allButtonsEnabled: true,
        y: -6,
      },

      xAxis: [
        {
          ...highchartAxisStyled,
          type: "datetime",
          ordinal: false,
          top: "0%",
          offset: 0,
          tickPixelInterval: 200,
          labels: {
            ...highchartAxisStyled.labels,
            rotation: 0,
            allowOverlap: false,
          },
        },
        {
          ...highchartAxisStyled,
          type: "datetime",
          ordinal: false,
          linkedTo: 0,
          top: "-55%",
          offset: 0,
          tickPixelInterval: 200,
          labels: {
            ...highchartAxisStyled.labels,
            rotation: 0,
            allowOverlap: false,
          },
        },
        {
          ...highchartAxisStyled,
          lineColor: "transparent",
          top: "-100%",
          offset: -0,
          title: {
            ...highchartAxisStyled.title,
            text: moistureChartTitle,
            style: {
              ...highchartAxisStyled.title.style,
              fontSize: "1.125rem",
              fontWeight: "bold",
            },
            y: -32,
          },
        },
        {
          ...highchartAxisStyled,
          lineColor: "transparent",
          top: "-50%",
          offset: 14,
          title: {
            ...highchartAxisStyled.title,
            text: temperatureChartTitle,
            style: {
              ...highchartAxisStyled.title.style,
              fontSize: "1.125rem",
              fontWeight: "bold",
            },
          },
        },
      ],

      yAxis: [
        {
          ...highchartAxisStyled,
          opposite: false,
          height: "45%",
          title: {
            ...highchartAxisStyled.title,
            text: "%",
            align: "high",
            textAlign: "right",
            rotation: 0,
            offset: 14,
            y: -8,
          },
          offset: 0,
          plotBands: moisturePlotBands,
        },
        {
          ...highchartAxisStyled,
          opposite: false,
          top: "67%",
          height: "33%",
          title: {
            ...highchartAxisStyled.title,
            text: "°C",
            align: "high",
            textAlign: "right",
            rotation: 0,
            offset: 14,
            y: -8,
          },
          offset: 0,
        },
      ],

      legend: {
        ...highchartStyled.legend,
        enabled: false,
      },

      tooltip: {
        ...highchartStyled.tooltip,
        split: true,
        valueDecimals: 1,
      },

      series,
    },
    undefined
  );
}

function Highchart({
  style,
  data,
  options,
}: {
  style?: CSSProperties;
  data: DataItem[];
  options: HighchartOptions;
}) {
  const { formatMessage, timeZone } = useIntl();
  const { decimalSeparator } = useFormatter();

  const ref = React.useRef() as RefObject<HTMLDivElement>;

  const { max_soil_moisture, min_soil_moisture, wilting_point } = options;

  const chartRef = React.useRef(undefined) as MutableRefObject<
    undefined | Highcharts.StockChart
  >;

  const series = React.useMemo(() => {
    const { moisture1, moisture2, temperature } = [...data]
      .sort((a: any, b: any) => a.date - b.date)
      .reduce(
        (acc, { date, moisture1, moisture2, temperature }) => {
          acc.moisture1.push([date, moisture1]);
          acc.moisture2.push([date, moisture2]);
          acc.temperature.push([date, temperature]);

          return acc;
        },
        {
          moisture1: [] as any,
          moisture2: [] as any,
          temperature: [] as any,
        }
      );

    return [
      {
        type: "line",
        name: formatMessage({ id: "sensor.info.moisture1" }),
        data: moisture1,
        zones: [
          { value: wilting_point, color: "#ed1e79" },
          { value: min_soil_moisture, color: "#ff7600" },
          { value: max_soil_moisture, color: "#c5d75d" },
          { value: 100, color: "#00c4ff" },
        ],
        tooltip: {
          valueSuffix: "%",
        },
        yAxis: 0,
        showInNavigator: true,
        navigatorOptions: {
          type: "areaspline",
          fillColor: "rgba(0, 196, 255, 0.20)",
          color: "rgba(0, 196, 255, 0.4)",
          lineWidth: 2,
          zones: undefined,
          marker: {
            enabled: false,
          },
          label: {
            style: {
              fontSize: "0.875rem",
              color: "red",
            },
          },
        },
      } as Highcharts.SeriesOptionsType,
      {
        type: "line",
        name: formatMessage({ id: "sensor.info.moisture2" }),
        data: moisture2,
        zones: [
          { value: wilting_point, color: "rgba(237, 30, 121, 0.6)" },
          {
            value: min_soil_moisture,
            color: "rgba(255, 118, 0, 0.6)",
          },
          {
            value: max_soil_moisture,
            color: "rgba(38, 121, 28, 0.6)",
          },
          { value: 100, color: "rgba(0, 196, 255, 0.6)" },
        ],
        tooltip: {
          valueSuffix: "%",
        },
        yAxis: 0,
        showInNavigator: false,
      },
      {
        type: "spline",
        name: formatMessage({ id: "sensor.info.temperature" }),
        data: temperature,
        color: "#c3c3c3",
        tooltip: {
          valueSuffix: "°C",
        },
        yAxis: 1,
        showInNavigator: false,
      },
    ];
  }, [
    data,
    formatMessage,
    max_soil_moisture,
    min_soil_moisture,
    wilting_point,
  ]) as Highcharts.SeriesOptionsType[] | undefined;

  const moisturePlotBands = React.useMemo(
    () => [
      {
        color: "rgba(237, 30, 121, 0.49)",
        from: 0,
        to: wilting_point,
      },
      {
        color: "rgba(255, 118, 0, 0.3)",
        from: wilting_point,
        to: min_soil_moisture,
      },
      {
        color: "transparent",
        from: min_soil_moisture,
        to: max_soil_moisture,
      },
      {
        color: "rgba(0, 196, 255, 0.25)",
        from: max_soil_moisture,
        to: 100,
      },
    ],
    [max_soil_moisture, min_soil_moisture, wilting_point]
  );

  const rangeButtonsTitle = React.useMemo(
    () => ({
      "1_week": formatMessage({ id: "sensor.info.chart.1_week" }),
      "1_month": formatMessage({ id: "sensor.info.chart.1_month" }),
      "3_month": formatMessage({ id: "sensor.info.chart.3_month" }),
      "6_month": formatMessage({ id: "sensor.info.chart.6_month" }),
    }),
    [formatMessage]
  );

  React.useEffect(() => {
    const node = ref.current;

    if (node) {
      chartRef.current = displayHighchart(
        node,
        series,
        rangeButtonsTitle,
        formatMessage({ id: "sensor.info.moisture_chart_title" }),
        formatMessage({ id: "sensor.info.temperature_chart_title" }),
        moisturePlotBands,
        {
          timezone: timeZone,
          decimalPoint: decimalSeparator,
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formatMessage, rangeButtonsTitle, moisturePlotBands]);

  React.useEffect(() => {
    if (chartRef.current) chartRef.current.update({ series });
  }, [series]);

  return <div className="history-line-chart" style={style} ref={ref} />;
}

export default Highchart;
