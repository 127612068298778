const textColor = "#f5f5f5";
const splineColor = "#000";
const lineColor = "#707070";
const hoverTextColor = "#f5f5f5";
const tooltipBgColor = "#052334";

const highchartAxisStyled = {
  lineColor: lineColor,
  gridLineColor: lineColor,
  tickColor: textColor,
  labels: {
    style: {
      fontSize: "var(--font-size, 0.875rem)",
      color: textColor,
      fontWeight: "normal",
    },
  },
  title: {
    style: {
      fontSize: "var(--font-size, 0.875rem)",
      color: textColor,
      fontWeight: "normal",
    },
  },
};

const highchartStyled = {
  chart: {
    animation: false,
    backgroundColor: undefined,
    plotBackgroundColor: undefined,
    style: {
      fontFamily: "var(--font-text)",
    },
  },
  navigator: {
    maskFill: "rgba(230, 234, 203, 0.2)",
    handles: {
      backgroundColor: "#c5d75d",
      borderColor: "#f5f5f5",
    },
    xAxis: {
      labels: {
        style: {
          fontSize: "0.875rem",
          fontWeight: "normal",
          color: "#c3c3c3",
        },
      },
    },
    series: {
      dataGrouping: {
        enabled: false,
      },
    },
  },
  scrollbar: {
    barBackgroundColor: "#c5d75d",
    barBorderWidth: 0,
    trackBackgroundColor: "#c3c3c3",
    trackBorderWidth: 0,
    rifleColor: "transparent",
    buttonArrowColor: "#f3f3f3",
    buttonBackgroundColor: "transparent",
    buttonBorderWidth: 0,
    height: 10,
  },
  rangeSelector: {
    buttonTheme: {
      fill: "none",
      stroke: "none",
      width: 60,

      style: {
        background: "none",
        border: "none",
        fontFamily: "inherit",
        fontSize: "var(--font-size, 0.875rem)",
        color: textColor,
      },
      states: {
        hover: { fill: "none", stroke: "none" },
        select: {
          fill: "none",
          stroke: "none",
          style: {
            fontWeight: "bold",
            color: "#c5d75d",
          },
        },
      },
    },
    labelStyle: {
      fontFamily: "inherit",
      fontSize: "var(--font-size, 0.875rem)",
      color: textColor,
    },
    inputStyle: {
      fontFamily: "inherit",
      fontSize: "var(--font-size, 0.875rem)",
      color: textColor,
      backgroundColor: "#151517",
    },
  },
  plotOptions: {
    spline: {
      lineWidth: 2,
      color: splineColor,
      states: {
        inactive: {
          opacity: 1,
        },
      },
    },
    series: {
      animation: false,
      connectNulls: false,
      dataGrouping: {
        enabled: false,
      },
      states: {
        inactive: {
          opacity: 1,
        },
      },
      label: {
        style: {
          fontSize: "var(--font-size, 0.875rem)",
          color: textColor,
        },
      },
    },
  },
  title: {
    style: {
      fontSize: "1.125rem",
      color: textColor,
    },
  },
  legend: {
    enabled: true,
    itemStyle: {
      fontSize: "var(--font-size, 0.875rem)",
      color: textColor,
      fontWeight: "inherit",
    },
    itemHoverStyle: {
      color: hoverTextColor,
    },
  },
  tooltip: {
    outside: true,
    borderColor: tooltipBgColor,
    backgroundColor: tooltipBgColor,
    style: {
      fontSize: "var(--font-size, 0.875rem)",
      color: textColor,
      fontWeight: "normal",
    },
  },
  time: {
    useUTC: true,
  },
  credits: {
    style: {
      display: "none",
    },
  },
} as Highcharts.Options;

export { highchartStyled, highchartAxisStyled };
