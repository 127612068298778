import React, { RefObject } from "react";

import { useOuterClickEffect } from "../../../../../hooks/useOuterClickEffect";
import useRedirect from "../../../../../hooks/useRedirect";
import { useUIContext } from "../../../../../store/uiStore/UIContext";

function ElementWrapper({
  id,
  x,
  y,
  select,
  selected,
  size,
  className,
  fill = "#fff",
  useBorder = true,
  useCentered = true,
  children,
}: {
  id: number | string;
  x: number;
  y: number;
  size: number | { w: number; h: number; cx?: number; cy?: number };
  select?: Function;
  selected?: boolean;
  fill?: string;
  activeFill?: string;
  className?: string;
  useBorder?: boolean;
  useCentered?: boolean;
  children: JSX.Element | JSX.Element[];
}) {
  const { zoom, containerRef } = useUIContext();
  const ref = React.useRef() as RefObject<SVGGElement>;
  const redirect = useRedirect();

  const xSize = typeof size === "number" ? size : size.w;
  const ySize = typeof size === "number" ? size : size.h;
  const cx = typeof size !== "number" ? size.cx ?? 0 : xSize / 2;
  const cy = typeof size !== "number" ? size.cy ?? 0 : ySize / 2;

  const positionByZoom = React.useMemo(() => {
    const min = 0.2;
    const max = 2;

    const t_zoom = Math.max(Math.min(zoom, max), min);

    return {
      x: useCentered ? -cx / t_zoom : 0,
      y: useCentered ? -cy / t_zoom : 0,
      scale: 1 / t_zoom,
    };
  }, [zoom, useCentered, cx, cy]);

  useOuterClickEffect(containerRef.current, selected ? ref : undefined, () => {
    redirect("/", true);
  });

  return (
    <g
      className={`element controller-element ${className ?? ""}`}
      transform={`translate(${x}, ${y})`}
    >
      <g
        ref={ref}
        className={typeof select === "function" ? "clickable" : undefined}
        onClick={typeof select === "function" ? () => select(id) : undefined}
        transform={`translate(${positionByZoom.x} ${positionByZoom.y}) scale(${positionByZoom.scale})`}
      >
        {useBorder && (selected || typeof select === "function") && (
          <circle
            fill={fill}
            fillOpacity={0.6}
            stroke={selected ? "#000" : "transparent"}
            strokeOpacity={0.7}
            strokeWidth={1.5}
            cx={xSize / 2}
            cy={ySize / 2}
            r={(Math.max(xSize, ySize) / 2) * 1.5}
          />
        )}
        {children}
      </g>
    </g>
  );
}

export default ElementWrapper;
