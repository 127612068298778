type DropdownValueTypeWithDefault = {
  value: any;
  label: string;
  isDefault?: boolean;
};

const getDefaultValue = (dropdownValues: DropdownValueTypeWithDefault[]) => {
  return (
    dropdownValues.find((dv: any) => dv.isDefault === true)?.value ??
    dropdownValues[0].value
  );
};

const sprinklerType = {
  mp: {
    label:
      "controller.properties.irrigation_settings.dropdown.sprinkler_type.mp_rotator.label",
  },
  gd: {
    label:
      "controller.properties.irrigation_settings.dropdown.sprinkler_type.geardrive.label",
  },
  dripline: {
    label:
      "controller.properties.irrigation_settings.dropdown.sprinkler_type.dripline.label",
  },
  "raised-bed": {
    label:
      "controller.properties.irrigation_settings.dropdown.sprinkler_type.raised_bed.label",
  },
  rzws: {
    label:
      "controller.properties.irrigation_settings.dropdown.sprinkler_type.rzws.label",
  },
};

const irrigationType = [
  {
    value: "optimal",
    label:
      "controller.properties.irrigation_settings.dropdown.irrigation.optimal.label",
    isDefault: true,
  },
  {
    value: "vegetable",
    label:
      "controller.properties.irrigation_settings.dropdown.irrigation.vegetable.label",
  },
  {
    value: "mainainance", // FIXME: obsiously typo, fix this along with backend
    label:
      "controller.properties.irrigation_settings.dropdown.irrigation.maintenance.label",
  },
];
const defaultIrrigationType = getDefaultValue(irrigationType);

const manualAdjustmentType = [
  { value: -25, label: "-25 %" },
  { value: -20, label: "-20 %" },
  { value: -15, label: "-15 %" },
  { value: -10, label: "-10 %" },
  { value: -5, label: "-5 %" },
  { value: 0, label: "0 %", isDefault: true },
  { value: 5, label: "5 %" },
  { value: 10, label: "10 %" },
  { value: 15, label: "15 %" },
  { value: 20, label: "20 %" },
  { value: 25, label: "25 %" },
];
const defaultManualAdjustmentType = getDefaultValue(manualAdjustmentType);

const conditionTypes = [
  {
    value: 0.6,
    label:
      "controller.properties.living_environment.dropdown.soil_condition.dry.label",
  },
  {
    value: 1.0,
    label:
      "controller.properties.living_environment.dropdown.soil_condition.fresh.label",
    isDefault: true,
  },
  {
    value: 1.6,
    label:
      "controller.properties.living_environment.dropdown.soil_condition.moist.label",
  },
] as DropdownValueTypeWithDefault[];
const defaultConditionType = getDefaultValue(conditionTypes);

const vegetationTypes = [
  {
    value: 0.8,
    label:
      "controller.properties.living_environment.dropdown.vegetation.lawn.label",
    isDefault: true,
  },
  {
    value: 1.0,
    label:
      "controller.properties.living_environment.dropdown.vegetation.perennials.label",
  },
  {
    value: 1.3,
    label:
      "controller.properties.living_environment.dropdown.vegetation.large_shrubs.label",
  },
] as DropdownValueTypeWithDefault[];
const defaultVegetationType = getDefaultValue(Object.values(vegetationTypes));

const rootDepthTypes = [
  { value: 20, label: "20 cm" },
  { value: 30, label: "30 cm" },
  { value: 70, label: "70 cm" },
] as DropdownValueTypeWithDefault[];
const defaultRootDepthType = getDefaultValue(rootDepthTypes);

const soilTypes = [
  {
    value: 1.5,
    label:
      "controller.properties.living_environment.dropdown.soil_type.sand.label",
  },
  {
    value: 1.0,
    label:
      "controller.properties.living_environment.dropdown.soil_type.sandy_loam.label",
    isDefault: true,
  },
  {
    value: 0.8,
    label:
      "controller.properties.living_environment.dropdown.soil_type.loam.label",
  },
] as DropdownValueTypeWithDefault[];
const defaultSoilTypeType = getDefaultValue(soilTypes);

const sunExposureTypes = [
  {
    value: 0.7,
    label:
      "controller.properties.living_environment.dropdown.sun_exposure.shade.label",
  },
  {
    value: 1.0,
    label:
      "controller.properties.living_environment.dropdown.sun_exposure.partial_shade.label",
    isDefault: true,
  },
  {
    value: 1.3,
    label:
      "controller.properties.living_environment.dropdown.sun_exposure.full_sun.label",
  },
] as DropdownValueTypeWithDefault[];
const defaultSunExposureType = getDefaultValue(sunExposureTypes);

const livingEnvironmentDefalutValues = {
  living_env_soil_condition_coef: defaultConditionType,
  living_env_vegetation_coef: defaultVegetationType,
  living_env_root_depth_in_cm: defaultRootDepthType,
  living_env_soil_type_coef: defaultSoilTypeType,
  living_env_sun_exposure_coef: defaultSunExposureType,
};

const irrigationSettingsDefaultValues = {
  et_env_irrigation_type: defaultIrrigationType,
  et_env_manual_adj_coef: defaultManualAdjustmentType,
};

function injectCircuitsWithDefaults(assigned: any) {
  if (assigned == null) return;

  for (const circuit of assigned) {
    for (const key of Object.keys(livingEnvironmentDefalutValues)) {
      if (circuit[key] == null) {
        circuit[key] = livingEnvironmentDefalutValues[key];
      }
    }

    for (const key of Object.keys(irrigationSettingsDefaultValues)) {
      if (circuit[key] == null) {
        circuit[key] = irrigationSettingsDefaultValues[key];
      }
    }
  }
}

export {
  conditionTypes,
  defaultConditionType,
  defaultIrrigationType,
  defaultManualAdjustmentType,
  defaultRootDepthType,
  defaultSoilTypeType,
  defaultSunExposureType,
  defaultVegetationType,
  irrigationType,
  manualAdjustmentType,
  rootDepthTypes,
  soilTypes,
  sprinklerType,
  sunExposureTypes,
  vegetationTypes,
  injectCircuitsWithDefaults,
};
