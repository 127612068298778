import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import PopupWrapper from "./PopupWrapper";
import RedirectLink from "../../../components/redirect/RedirectLink";

import { urlDecorator } from "../../../utils/http.utils";

import { ReactComponent as AuthSVG } from "../../../assets/auth.svg";

function LoginPopup() {
  const { formatMessage } = useIntl();

  const loginUrl = React.useMemo(() => {
    return urlDecorator(formatMessage({ id: "login.link.url" }));
  }, [formatMessage]);

  return (
    <PopupWrapper
      title={formatMessage({ id: "login.title" })}
      className="login"
    >
      <div className="login-container">
        <div>
          <AuthSVG />
        </div>
        <div className="login-text">
          <FormattedMessage
            id="login.text"
            values={{
              link: (
                <RedirectLink href={loginUrl}>
                  {formatMessage({
                    id: "login.link.title",
                  })}
                </RedirectLink>
              ),
            }}
          />
        </div>
      </div>
    </PopupWrapper>
  );
}

export default LoginPopup;
