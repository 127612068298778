import React from "react";

function usePolling(callback: Function | undefined, interval: number) {
  const timerRef = React.useRef() as any;

  React.useEffect(() => {
    if (typeof callback === "function") {
      timerRef.current = setTimeout(async function poll() {
        try {
          await callback();
        } catch (e) {
          console.error("Polling error", e);
        }
        timerRef.current = setTimeout(poll, interval);
      }, 0);
    }

    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interval, callback]);
}

export default usePolling;
