import { v1 as uuid } from "uuid";
import FertilizerInterface from "../interface/FertilizerInterface";

function fertilizerFactory({
  id = uuid(),
  x,
  y,
  prefix,
  name,
  disabled = false,
  serial_number,
  min_weight_limit,
  min_weight_notification,
  weight,
  battery,
  status = "nok",
}: {
  id?: string;
  x: number;
  y: number;
  prefix: string;
  name?: string;
  disabled?: boolean;
  serial_number?: string;
  min_weight_limit?: number;
  min_weight_notification?: boolean;
  weight?: number;
  battery?: number;
  status?: "ok" | "nok";
}): FertilizerInterface {
  return {
    get id() {
      return id + "";
    },
    get x() {
      return x;
    },
    get y() {
      return y;
    },
    prefix,
    name,
    get title() {
      return [prefix, name?.trim()].filter((e) => e).join(" - ");
    },
    disabled,
    serial_number,
    min_weight_limit: min_weight_limit ? +min_weight_limit : undefined,
    min_weight_notification: min_weight_notification ?? false,
    get is_fill_properties() {
      return this.serial_number == null || this.serial_number.trim() === "";
    },
    get weight() {
      return weight;
    },
    get battery() {
      if (battery == null) return undefined;
      if (battery >= 3.5) return 100;
      if (battery >= 3.45 && battery < 3.5) return 80;
      if (battery >= 3.4 && battery < 3.45) return 60;
      if (battery >= 3.35 && battery < 3.4) return 40;
      if (battery >= 3.3 && battery < 3.35) return 15;

      return 0;
    },
    get status() {
      const statusValue = status?.toLowerCase();

      if (statusValue === "nok") {
        return "nok";
      }

      if (
        (this.min_weight_limit ?? 0) > 0 &&
        this.weight != null &&
        this.weight < this.min_weight_limit!
      ) {
        return "low_out_weight";
      }

      return "ok";
    },
  };
}

export default fertilizerFactory;
