import { createIntl, createIntlCache, RawIntlProvider } from "react-intl";
import localesConfig from "./texts";

function findLocaleCnf(locale: string): any {
  return localesConfig.find(({ locales }) =>
    locales.map((l) => l.toLowerCase()).includes(locale.toLowerCase())
  );
}

const intlStore = (
  locale: string | undefined,
  timezone: string | undefined
) => {
  const DEFAULT_LOCALE = "en-GB";
  const defaultLocaleCnf = findLocaleCnf(DEFAULT_LOCALE);
  const localeCnf = findLocaleCnf(locale ?? DEFAULT_LOCALE);

  const messages = {
    ...(defaultLocaleCnf?.messages || {}),
    ...(localeCnf?.messages || {}),
  };

  const cache = createIntlCache();
  return createIntl(
    {
      locale: localeCnf?.id || defaultLocaleCnf?.id,
      defaultLocale: defaultLocaleCnf?.id,
      timeZone: timezone,
      messages,
    },
    cache
  );
};

type IntlProps = {
  locale: string | undefined;
  timezone?: string;
  children?: JSX.Element;
};

const IntlProvider = ({ locale, timezone, children }: IntlProps) => {
  const intl = intlStore(locale, timezone);

  return <RawIntlProvider value={intl}>{children}</RawIntlProvider>;
};

export default IntlProvider;
