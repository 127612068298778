import React, { RefObject } from "react";
import { usePlanStore } from "../PlanStore";
import { generateGrid } from "../../utils/plan.utils";

interface UIContextInterface {
  rightSideRef: RefObject<HTMLDivElement>;
  pageInfoRef: RefObject<HTMLDivElement>;
  containerRef: RefObject<HTMLDivElement>;
  offset: { x: number; y: number };
  containerSize: { width: number; height: number };
  changeOffset: Function;
  draggable: boolean;
  changeDraggable: Function;
  zoom: number;
  changeZoom: Function;
  handTool: boolean;
  changeHandTool: Function;
  grid: { size: number; value: number };
  gridTool: boolean;
  changeGridTool: Function;
  valvesFormHasChanges: boolean;
  changeValvesFormHasChanges: Function;
}

const UIContext = React.createContext<UIContextInterface | any>({});

function useZoom(): [zoom: number, changeZoom: Function] {
  const [zoom, _changeZoom] = React.useState(1);
  const changeZoom = React.useCallback((v: number) => {
    if (v < 0.1) v = 0.1;
    if (v > 7) v = 7;

    _changeZoom(v);
    return v;
  }, []);

  return [zoom, changeZoom];
}

function useContainerSize(containerRef: RefObject<any>) {
  const [containerSize, _changeContainerSize] = React.useState({
    width: 0,
    height: 0,
  });

  React.useEffect(() => {
    const node = containerRef.current;
    if (node) {
      const listener = () => {
        _changeContainerSize({
          width: node.clientWidth,
          height: node.clientHeight,
        });
      };

      window.addEventListener("resize", listener);
      listener();

      return () => {
        window.removeEventListener("resize", listener);
      };
    }
  }, [_changeContainerSize, containerRef]);

  return containerSize;
}

function useGrid(planScale: number | undefined, zoom: number) {
  const [gridTool, changeGridTool] = React.useState(true);

  const grid = React.useMemo(
    () => generateGrid(planScale ?? 10, zoom),
    [planScale, zoom]
  );

  return {
    gridTool,
    changeGridTool,
    grid,
  };
}

function UIContextProvider({
  children,
}: {
  children: JSX.Element | JSX.Element[] | undefined;
}) {
  const plan = usePlanStore();

  const rightSideRef = React.useRef() as RefObject<HTMLDivElement>;
  const pageInfoRef = React.useRef() as RefObject<HTMLDivElement>;
  const containerRef = React.useRef() as RefObject<HTMLDivElement>;

  const containerSize = useContainerSize(containerRef);
  const [offset, changeOffset] = React.useState({ x: 0, y: 0 });
  const [handTool, changeHandTool] = React.useState(false);
  const [draggable, changeDraggable] = React.useState(true);
  const [zoom, changeZoom] = useZoom();
  const { grid, gridTool, changeGridTool } = useGrid(plan?.data?.scale, zoom);

  const [valvesFormHasChanges, changeValvesFormHasChanges] =
    React.useState(false);

  return (
    <UIContext.Provider
      value={{
        rightSideRef,
        pageInfoRef,
        containerRef,
        offset,
        changeOffset,
        containerSize,
        handTool,
        changeHandTool,
        grid,
        gridTool,
        changeGridTool,
        draggable,
        changeDraggable,
        zoom,
        changeZoom,
        valvesFormHasChanges,
        changeValvesFormHasChanges,
      }}
    >
      {children}
    </UIContext.Provider>
  );
}

function useUIContext(): UIContextInterface {
  return React.useContext(UIContext);
}

export type { UIContextInterface };

export { UIContextProvider, useUIContext };
