import Checkbox from "../../../../components/checkbox/Checkbox";

const PropertiesWithCheckbox = ({
  value,
  onChange,
  title,
  className,
  disabled,
}: {
  value: any;
  onChange: Function;
  title: string | JSX.Element;
  className?: string;
  disabled?: boolean;
}) => {
  return (
    <div className={[className, "with-checkbox"].filter((e) => e).join(" ")}>
      <div className="title">{title}</div>
      <Checkbox value={value} onChange={onChange} disabled={disabled} />
    </div>
  );
};

export default PropertiesWithCheckbox;
