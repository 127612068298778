import { useMatch } from "react-router-dom";

import useRedirect from "../../../../../hooks/useRedirect";
import { usePlanStore } from "../../../../../store/PlanStore";
import { HydrawiseSettingsInterface } from "../../../../../store/interface/HydrawiseSettingsInterface";
import { useUIContext } from "../../../../../store/uiStore/UIContext";
import {
  CONTROLLER_PROPERTIES_PATH,
  getControllerPropertiesURL,
} from "../../../../../utils/path.utils";
import ElementWrapper from "../common/ElementWrapper";

import InterruptControllerSignalSVG from "../../../../../assets/controller-signal.png";
import { ReactComponent as ControllerSVG } from "../../../../../assets/controller.svg";
import EtControllerSignalSVG from "../../../../../assets/et-controller-signal.png";

const getSignalIconElement = (
  settings: HydrawiseSettingsInterface | undefined
) => {
  if (settings?.controller_type == null) {
    return <></>;
  }

  const icon =
    settings?.controller_type === "plant-et"
      ? EtControllerSignalSVG
      : InterruptControllerSignalSVG;
  return (
    <g transform="translate(12 -6)">
      <image href={icon} width={12} height={12} />
    </g>
  );
};

function Controller({
  id,
  x,
  y,
  selected,
  select,
  iconElement,
}: {
  id: number | string;
  x: number;
  y: number;
  selected: boolean;
  select: Function | undefined;
  iconElement: JSX.Element;
}) {
  return (
    <ElementWrapper
      id={id}
      x={x}
      y={y}
      size={20}
      select={select}
      selected={selected}
    >
      <ControllerSVG width={20} height={20} />
      {iconElement}
    </ElementWrapper>
  );
}

function ControllerElements() {
  const { handTool } = useUIContext();
  const { data, hydrawiseSettings } = usePlanStore();
  const redirect = useRedirect();

  const controllerPropertiesMatch = useMatch(CONTROLLER_PROPERTIES_PATH);

  const selectedControllerId = controllerPropertiesMatch?.params?.controllerId;

  const controllers =
    data?.elements.filter(
      (e) => e.type === "system-element" && e.systemType === "controller"
    ) ?? [];

  if (controllers.length === 0) return null;

  return (
    <>
      {controllers.map((controller) => (
        <Controller
          key={controller.id}
          id={controller.id}
          x={controller.x}
          y={controller.y}
          iconElement={getSignalIconElement(hydrawiseSettings)}
          selected={selectedControllerId === controller.id}
          select={
            !handTool && selectedControllerId !== controller.id
              ? () => {
                  redirect(getControllerPropertiesURL(controller.id), true);
                }
              : undefined
          }
        />
      ))}
    </>
  );
}

export default ControllerElements;
