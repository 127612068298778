import Toggle from "../../../../components/toggle/Toggle";
import { useTooltip } from "../../../../components/tooltip/Tooltip";

const PropertiesWithToggle = ({
  value,
  onChange,
  title,
  tooltip,
  className,
  disabled,
  tooltipType = "left",
}: {
  value: any;
  onChange: Function;
  title: string;
  tooltip?: string;
  className?: string;
  disabled?: boolean;
  tooltipType?: string;
}) => {
  const { showTooltip, hideTooltip } = useTooltip();
  return (
    <div
      className={[className, "with-toggle"].filter((e) => e).join(" ")}
      onMouseEnter={
        tooltip ? (e) => showTooltip(e, tooltip, tooltipType) : undefined
      }
      onMouseLeave={hideTooltip}
    >
      <div className="title">{title}</div>
      <Toggle value={value} onChange={onChange} disabled={disabled} />
    </div>
  );
};

export default PropertiesWithToggle;
