import React from "react";
import SensorInterface from "../../../../../store/interface/SensorInterface";
import usePolling from "../../../../../hooks/usePolling";
import HistoryChart from "./HistoryChart";
import calcApi from "../../../../../service/calcApi";
import { useUserStore } from "../../../../../store/UserStore";

function HistoryLine({
  planId,
  sensor,
  active,
}: {
  planId: string | undefined;
  sensor: SensorInterface;
  active: boolean;
}) {
  const userStore = useUserStore();

  const [history, changeHistory] = React.useState<any>([]);

  const fetchSensorHistory = React.useMemo(
    () =>
      !sensor?.is_fill_properties && sensor?.serial_number != null
        ? async () => {
            if (sensor.serial_number == null) return undefined;

            const data = await calcApi.fetchSensorHistory(
              planId,
              sensor.serial_number,
              userStore?.requestLogin
            );
            changeHistory(
              (data ?? []).map((d) => ({
                ...d,
                date: d.date ? +new Date(d.date) : undefined,
              }))
            );
          }
        : undefined,
    [
      planId,
      sensor?.is_fill_properties,
      sensor.serial_number,
      userStore?.requestLogin,
    ]
  );

  usePolling(fetchSensorHistory, 60 * 1000);

  return (
    <div className="history-line">
      <div className="separator" />
      <HistoryChart
        style={{ minHeight: 450 }}
        data={history}
        options={{
          wilting_point: sensor.wilting_point ?? 0,
          min_soil_moisture: sensor.min_soil_moisture ?? 0,
          max_soil_moisture: sensor.max_soil_moisture ?? 100,
        }}
      />
    </div>
  );
}

export default HistoryLine;
