import { useIntl } from "react-intl";

import ToolItem from "./ToolItem";

import { ReactComponent as CenterSVG } from "../../../../assets/to-center.svg";
import { usePlanStore } from "../../../../store/PlanStore";
import useUIHelper from "../../../../store/uiStore/useUIHelper";

function CenterTool() {
  const { formatMessage } = useIntl();
  const plan = usePlanStore();
  const { zoomToElements } = useUIHelper();

  return (
    <ToolItem
      hasActive={false}
      Icon={CenterSVG}
      onClick={() => zoomToElements(plan.data?.elements ?? [], 100)}
      tooltip={formatMessage({ id: "tools.center.tooltip" })}
    />
  );
}

export default CenterTool;
