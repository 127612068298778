import { useIntl } from "react-intl";
import TEXT_BY_STATUS from "./FertilizerStatuses";

import useFormatter from "../../../../hooks/useFormatter";

import React from "react";
import { ReactComponent as FertilizerOutWeightSVG } from "../../../../assets/fertilizer-out-weight.svg";
import FertilizerInterface from "../../../../store/interface/FertilizerInterface";

function BatterySVG({
  value,
  disabled = false,
}: {
  value: number;
  disabled: boolean;
}) {
  const color = React.useMemo(() => {
    if (disabled) {
      return "#c3c3c3";
    } else if (value >= 50) {
      return "#c5d75d";
    } else if (value >= 30) {
      return "#ffe200";
    } else if (value >= 10) {
      return "#ff7600";
    } else {
      return "#ed1e79";
    }
  }, [disabled, value]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
    >
      <g fill={color}>
        <path d="M20,2.223A17.778,17.778,0,1,1,2.223,20,17.777,17.777,0,0,1,20,2.223M20,0A20,20,0,1,0,40,20,20,20,0,0,0,20,0" />
        <path
          d="M34.65,18.372H32.342v-2.9H8.025V29.686H32.342v-2.9H34.65ZM31.092,28.436H9.274V16.721H31.092Z"
          transform="translate(-1.337 -2.579)"
        />
        {!disabled &&
          Array.from({ length: Math.round(value / 20) }).map((_, i) => (
            <rect
              key={i}
              width="2.678"
              height="8.413"
              transform={`translate(${9.788 + i * 3.86} 15.794)`}
              fill={color}
            />
          ))}
      </g>
    </svg>
  );
}

function ValueInfo({
  Icon,
  value,
  info,
  className,
}: {
  Icon: React.ComponentType<any>;
  value?: string;
  info?: string;
  className?: string;
}) {
  return (
    <div className={["value-info", className].filter((e) => e).join(" ")}>
      <div className="row text">
        <div className="icon">
          <Icon />
        </div>
        {value != null && <div className="value">{value}</div>}
      </div>
      {info != null && <div className="row info">{info}</div>}
    </div>
  );
}

function FertilizerValues({ fertilizer }: { fertilizer: FertilizerInterface }) {
  const { formatMessage } = useIntl();
  const { formatBattery, formatWeight } = useFormatter();

  const { weight, status, battery } = fertilizer;

  const statusOptions =
    TEXT_BY_STATUS[status ?? "nok"] ?? TEXT_BY_STATUS["nok"];

  return (
    <div className="fertilizer-values sensor-info-values">
      <div className="sensor-name">
        {formatMessage({ id: "fertilizer.info.title" })}
      </div>
      <ValueInfo
        Icon={FertilizerOutWeightSVG}
        value={formatWeight(weight)}
        info={formatMessage({ id: "fertilizer.info.output_weight" })}
      />
      <div className="separator" />
      <ValueInfo Icon={statusOptions.Icon} />
      <div className="space" />
      <ValueInfo
        Icon={() => (
          <BatterySVG value={battery ?? 0} disabled={battery == null} />
        )}
        info={formatBattery(battery)}
        className="battery"
      />
      <div className="separator" />
      <div className="status">
        <div>{formatMessage({ id: "common.status" })}:</div>
        <div className="text" style={{ color: statusOptions.color }}>
          {formatMessage(statusOptions.text)}
        </div>
      </div>
    </div>
  );
}

export default FertilizerValues;
