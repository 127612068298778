import { usePlanStore } from "../../../../../store/PlanStore";

import { ReactComponent as WaterSupplySVG } from "../../../../../assets/water-supply.svg";
import ElementWrapper from "../common/ElementWrapper";

function WaterSupply({
  id,
  x,
  y,
}: {
  id: number | string;
  x: number;
  y: number;
}) {
  return (
    <ElementWrapper id={id} x={x} y={y} size={20}>
      <WaterSupplySVG width={20} height={20} />
    </ElementWrapper>
  );
}

function WaterSupplyElements() {
  const { data } = usePlanStore();

  const waterSupplies =
    data?.elements.filter(
      (e) => e.type === "system-element" && e.systemType === "water-supply"
    ) ?? [];

  if (waterSupplies.length === 0) return null;

  return (
    <>
      {waterSupplies.map((waterSupply) => (
        <WaterSupply
          key={waterSupply.id}
          id={waterSupply.id}
          x={waterSupply.x}
          y={waterSupply.y}
        />
      ))}
    </>
  );
}

export default WaterSupplyElements;
