import React from "react";
import useUIHelper from "../../../store/uiStore/useUIHelper";
import { lineLength } from "../../../utils/ui.utils";
import { useUIContext } from "../../../store/uiStore/UIContext";

function ZoomController() {
  const { zoom, containerRef } = useUIContext();
  const { convertPagePointToPlanPoint, zoomToPoint, currentCenter } =
    useUIHelper();

  const startZoomRef = React.useRef(zoom);
  const touchesRef = React.useRef<any[]>([]);

  const touchStartHandler = React.useCallback(
    (e: any) => {
      const touches = e.touches;
      let changedTouches: any[] = [];

      if (touches.length === 2) {
        for (const touch of touches) {
          changedTouches.push(convertPagePointToPlanPoint(touch));
        }
      }

      touchesRef.current = changedTouches;
      startZoomRef.current = zoom;
    },
    [convertPagePointToPlanPoint, zoom]
  );

  const touchMoveHandler = React.useCallback(
    (e: any) => {
      let changedTouches: any[] = [];
      for (const touch of e.touches) {
        changedTouches.push(convertPagePointToPlanPoint(touch));
      }

      const startTouches = touchesRef.current;

      if (startTouches.length === 2 && changedTouches.length === 2) {
        const startDistance = lineLength(startTouches[0], startTouches[1]);
        const endDistance = lineLength(changedTouches[0], changedTouches[1]);

        const currentZoom = endDistance / startDistance;

        zoomToPoint(startZoomRef.current * currentZoom, {
          x: (startTouches[0].x + startTouches[1].x) / 2,
          y: (startTouches[0].y + startTouches[1].y) / 2,
        });
      }

      e.preventDefault();
      e.stopPropogation();
    },
    [convertPagePointToPlanPoint, zoomToPoint]
  );

  const wheelHandler = React.useCallback(
    (e: any) => {
      e.preventDefault();

      const k = e.deltaY > 0 ? -1 : 1;
      zoomToPoint(zoom + k * 0.025, convertPagePointToPlanPoint(e));
    },
    [convertPagePointToPlanPoint, zoomToPoint, zoom]
  );

  const keyPressHandler = React.useCallback(
    (e: any) => {
      if (e.shiftKey) {
        switch (e.code) {
          case "NumpadSubtract":
          case "Minus":
            zoomToPoint(zoom - 0.05, currentCenter);
            break;
          case "NumpadAdd":
          case "Equal":
            zoomToPoint(zoom + 0.05, currentCenter);
            break;
          default:
            break;
        }
      }
    },
    [currentCenter, zoom, zoomToPoint]
  );

  React.useEffect(() => {
    const node = containerRef.current;

    if (node) {
      node.addEventListener("touchstart", touchStartHandler, {
        passive: false,
      });
      node.addEventListener("touchmove", touchMoveHandler, {
        passive: false,
      });
      node.addEventListener("wheel", wheelHandler, { passive: false });
      window.addEventListener("keypress", keyPressHandler);

      return () => {
        node.removeEventListener("touchstart", touchStartHandler);
        node.removeEventListener("touchmove", touchMoveHandler);
        node.removeEventListener("wheel", wheelHandler);
        window.removeEventListener("keypress", keyPressHandler);
      };
    }
  }, [
    containerRef,
    keyPressHandler,
    touchMoveHandler,
    touchStartHandler,
    wheelHandler,
  ]);

  return null;
}

export default ZoomController;
