import { usePlanStore } from "../../../../../store/PlanStore";
import { useUIContext } from "../../../../../store/uiStore/UIContext";
import PipelineInterface from "../../../../../store/interface/PipelineInterface";
import {
  PIPELINE_PROPERTIES_PATH,
  getPipelinePropertiesURL,
} from "../../../../../utils/path.utils";

import { useMatch } from "react-router-dom";
import useRedirect from "../../../../../hooks/useRedirect";

import PipeLines from "./PipeLines";
import PipePoints from "./PipePoints";
import Trenches from "./Trenches";

function PipeElements({
  id,
  pipeline,
  trenches,
  selected,
  select,
}: {
  id: number | string;
  pipeline: PipelineInterface;
  trenches: any[];
  selected?: boolean;
  select?: Function | undefined;
}) {
  const { lines, points, color, lineOptions } = pipeline;
  return (
    <g id={`pipeline-${id}`}>
      <PipeLines
        lines={lines}
        trenches={trenches}
        lineOptions={lineOptions}
        selected={selected}
        select={select}
      />
      <PipePoints
        points={points}
        color={color}
        selected={selected}
        select={select}
      />
    </g>
  );
}

function Pipes() {
  const { handTool } = useUIContext();
  const { data } = usePlanStore();
  const redirect = useRedirect();
  const pipelinePropertiesMatch = useMatch(PIPELINE_PROPERTIES_PATH);

  if ((data?.pipelines?.length ?? 0) === 0) return null;

  const selectedPipelineId = pipelinePropertiesMatch?.params?.pipelineId;

  return (
    <>
      {data?.pipelines
        ?.sort((a, b) => {
          if (
            selectedPipelineId &&
            (a.id === selectedPipelineId || b.id === selectedPipelineId)
          ) {
            return a.id === selectedPipelineId ? 1 : -1;
          }

          return 0;
        })
        .map((pipeline) => (
          <PipeElements
            key={pipeline.id}
            id={pipeline.id}
            pipeline={pipeline}
            trenches={data?.trenches}
            selected={selectedPipelineId === pipeline.id}
            select={
              !handTool && selectedPipelineId !== pipeline.id
                ? () => {
                    redirect(getPipelinePropertiesURL(pipeline.id), true);
                  }
                : undefined
            }
          />
        ))}
      <Trenches />
    </>
  );
}

export default Pipes;
