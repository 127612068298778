import { useUIContext } from "../../../store/uiStore/UIContext";
import "./page-info.scss";

function PageInfo({ children }: { children: JSX.Element }) {
  const { pageInfoRef } = useUIContext();

  return (
    <div className="page-info-wrapper container">
      <div className="page-info-content">
        <div className="page-info" ref={pageInfoRef}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default PageInfo;
