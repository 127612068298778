import React from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import useRedirect from "../../../../../hooks/useRedirect";

import { usePlanStore } from "../../../../../store/PlanStore";
import PropertiesWrapper from "../PropertiesWrapper";

import { ReactComponent as OveralLengthSVG } from "../../../../../assets/overal-length-icon.svg";
import { ReactComponent as FloverAreaSVG } from "../../../../../assets/floverarea-icon.svg";
import PropertiesProgressBar from "../PropertiesProgressBar";

const { toFixedPrecision } = require("@dvsproj/ipat-core/formatter");

function CircuitSVG({ color }: { color: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="37"
      viewBox="0 0 37 37"
    >
      <g id="Cirquit_Icon" transform="translate(0.5 0.5)">
        <circle
          id="Ellipse_434"
          data-name="Ellipse 434"
          fill={color}
          stroke="#f5f5f5"
          cx="18"
          cy="18"
          r="18"
        />
        <path
          id="Pfad_482"
          data-name="Pfad 482"
          fill="#fff"
          d="M1154.92,639.09a2.56,2.56,0,0,0-1.61.56l-5.65-4.36a2.643,2.643,0,1,0-5.13-.9,2.541,2.541,0,0,0,.12.78l-11.92,8.25a2.67,2.67,0,0,0-1.68-.6,2.64,2.64,0,1,0,0,5.28,2.665,2.665,0,0,0,1.78-.69l9.7,5.97a2.117,2.117,0,0,0-.04.44,2.64,2.64,0,1,0,5.28,0,2.512,2.512,0,0,0-.29-1.18l8.23-8.57a2.638,2.638,0,1,0,1.21-4.98Zm-11.39-4.7a1.645,1.645,0,0,1,3.29,0,1.941,1.941,0,0,1-.02.24,1.621,1.621,0,0,1-.89,1.22,1.53,1.53,0,0,1-.74.18,1.653,1.653,0,0,1-1.63-1.48A.859.859,0,0,1,1143.53,634.39Zm-14.48,12.71a1.64,1.64,0,1,1,0-3.28,1.549,1.549,0,0,1,.8.22,1.6,1.6,0,0,1,.82,1.25.47.47,0,0,1,.02.17.222.222,0,0,1-.01.08,1.594,1.594,0,0,1-.76,1.3A1.537,1.537,0,0,1,1129.05,647.1Zm15.72,6.72a1.639,1.639,0,0,1-3.27.16.807.807,0,0,1-.01-.16,1.611,1.611,0,0,1,.59-1.25,1.566,1.566,0,0,1,1.05-.39,1.53,1.53,0,0,1,.6.12,1.632,1.632,0,0,1,.99,1.13A1.736,1.736,0,0,1,1144.77,653.82Zm-.31-2.28a2.6,2.6,0,0,0-1.33-.36,2.628,2.628,0,0,0-1.93.85l-9.59-5.92a2.818,2.818,0,0,0,.08-.65,2.605,2.605,0,0,0-.12-.79l11.91-8.26a2.6,2.6,0,0,0,3.27.08l5.68,4.37a2.524,2.524,0,0,0-.15.87,2.606,2.606,0,0,0,.35,1.3Zm10.46-8.17a1.427,1.427,0,0,1-.46-.07,1.656,1.656,0,0,1-1.09-1.05,1.5,1.5,0,0,1-.09-.52.647.647,0,0,1,.02-.2,1.574,1.574,0,0,1,.84-1.23,1.5,1.5,0,0,1,.78-.21,1.64,1.64,0,1,1,0,3.28Z"
          transform="translate(-1124.016 -626.043)"
        />
      </g>
    </svg>
  );
}

function PipelineItem({
  ElementIcon,
  label,
  text,
  unit,
  textInfo,
}: {
  ElementIcon: React.ComponentType<any>;
  label: string;
  text: string;
  unit?: string;
  textInfo?: string;
}) {
  return (
    <div className="pipeline-properties-item icon">
      <div className="pipeline-properties-row">
        <div className="item-icon">
          <ElementIcon />
          <div className="small-text">{label}</div>
        </div>
        <div className="item-text-wrap">
          <div className="text-wrap">
            <span className="item-text">{text}</span>
            {unit && (
              <span
                className="small-text"
                dangerouslySetInnerHTML={{ __html: unit }}
              ></span>
            )}
          </div>
          {textInfo && (
            <div
              className="small-text text-info"
              dangerouslySetInnerHTML={{ __html: textInfo }}
            ></div>
          )}
        </div>
      </div>
    </div>
  );
}

function PipelinePropertiesElement({
  close,
  color,
  lineType,
  totalLength,
  shapeLimit,
  flowerArea,
  waterQuantity,
  driplineValvesCount,
}: {
  close: Function;
  color: string;
  lineType: string;
  shapeLimit: number;
  totalLength?: number;
  flowerArea?: number;
  waterQuantity?: number;
  driplineValvesCount?: number;
}) {
  const { formatMessage, formatNumber } = useIntl();

  let WaterComponent;
  let FloverArea;
  switch (lineType) {
    case "circuit":
      WaterComponent = (
        <>
          <div className="pipeline-properties-item">
            <PropertiesProgressBar
              value={
                ((toFixedPrecision(shapeLimit, 2) -
                  toFixedPrecision(waterQuantity, 2)) *
                  100) /
                shapeLimit
              }
            />
          </div>
          <div className="water-consumption">
            <div className="total">
              <div
                className="small-text"
                dangerouslySetInnerHTML={{
                  __html: formatMessage({
                    id: "pipeline.properties.circuit.waterTotalConsumption",
                  }),
                }}
              ></div>
              <div>
                <span className="item-text">
                  {formatNumber(toFixedPrecision(shapeLimit / 1000, 2), {
                    minimumFractionDigits: 2,
                  })}
                </span>
                <span className="small-text">
                  m<sup>3</sup>/h
                </span>
              </div>
            </div>
            {waterQuantity ? (
              <div className="remaining">
                <div className="small-text">
                  {formatMessage({
                    id: "pipeline.properties.circuit.waterRemaining",
                  })}
                </div>
                <div>
                  <span className="item-text">
                    {formatNumber(
                      toFixedPrecision((shapeLimit - waterQuantity) / 1000, 2),
                      {
                        minimumFractionDigits: 2,
                      }
                    )}
                  </span>
                  <span className="small-text">
                    m<sup>3</sup>/h
                  </span>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </>
      );
      break;
    case "dripline":
      FloverArea = (
        <>
          <PipelineItem
            ElementIcon={() => <FloverAreaSVG />}
            label={formatMessage({
              id: "pipeline.properties.circuit.floverArea",
            })}
            text={formatNumber(toFixedPrecision(flowerArea, 1))}
            unit="m<sup>2</sup>"
            textInfo={
              driplineValvesCount && driplineValvesCount > 1
                ? formatMessage({
                    id: "pipeline.properties.circuit.driplineValves",
                  }).replace("{valvesCount}", driplineValvesCount.toString())
                : undefined
            }
          />
        </>
      );
      break;
    default:
      break;
  }

  return (
    <PropertiesWrapper
      title={formatMessage({ id: "pipeline.properties.title" })}
      elementTitle={formatMessage({ id: "pipeline.properties.circuit.title" })}
      ElementIcon={() => <CircuitSVG color={color} />}
      submit={() => {
        close();
      }}
    >
      <div className="pipeline-properties">
        <PipelineItem
          ElementIcon={() => <OveralLengthSVG />}
          label={formatMessage({
            id: "pipeline.properties.circuit.overallLenght",
          })}
          text={formatNumber(toFixedPrecision(totalLength, 1))}
          unit="m"
        />
        {FloverArea}
        {WaterComponent}
      </div>
    </PropertiesWrapper>
  );
}

function PipelineProperties() {
  const { data } = usePlanStore();
  const { pipelineId } = useParams();
  const redirect = useRedirect();

  const pipeline = React.useMemo(
    () => data?.pipelines?.find((s) => s.id === pipelineId),
    [data?.pipelines, pipelineId]
  );

  if (pipeline == null) return null;

  return (
    <PipelinePropertiesElement
      key={pipeline.id}
      lineType={pipeline.lineType}
      color={pipeline.color}
      totalLength={pipeline.totalLength}
      shapeLimit={pipeline.shapeLimit}
      waterQuantity={pipeline.waterQuantity}
      flowerArea={pipeline.flowerArea}
      driplineValvesCount={pipeline.driplineValvesCount}
      close={() => {
        redirect("/", true);
      }}
    />
  );
}

export default PipelineProperties;
