import { usePlanStore } from "../../../../../store/PlanStore";

import React from "react";
import { useMatch } from "react-router-dom";
import { ReactComponent as WaterFilterSVG } from "../../../../../assets/water-filter-sensor.svg";
import useRedirect from "../../../../../hooks/useRedirect";
import { useUIContext } from "../../../../../store/uiStore/UIContext";
import {
  WATER_FILTER_INFO_PATH,
  WATER_FILTER_PROPERTIES_PATH,
  getWaterFilterInfoURL,
  getWaterFilterPropertiesURL,
} from "../../../../../utils/path.utils";
import ElementWrapper from "../common/ElementWrapper";
import { WaterFilterStatus } from "../../../../../store/interface/WaterFilterDataInterface";
import TEXT_BY_STATUS from "../../../page-info/water-filter-info/WaterFilterStatuses";

function WaterFilter({
  id,
  x,
  y,
  selected,
  select,
  invalid,
  status,
  type,
}: Readonly<{
  id: number | string;
  x: number;
  y: number;
  selected: boolean;
  select: Function | undefined;
  invalid: boolean | undefined;
  status: WaterFilterStatus | undefined;
  type: "combi-box" | "water-filter" | undefined;
}>) {
  const statusOptions =
    TEXT_BY_STATUS[status ?? "nok"] ?? TEXT_BY_STATUS["nok"];

  const sensorColor = React.useMemo(() => {
    if (invalid) return "#3e4346";

    return statusOptions.color;
  }, [invalid, statusOptions]);

  const colorFill = React.useMemo(() => {
    if (invalid) return "#000";

    if (type === "combi-box") return "#ff00b1";

    return "#00c4ff";
  }, [invalid, type]);

  return (
    <ElementWrapper
      id={id}
      x={x}
      y={y}
      size={32}
      select={select}
      selected={selected}
      fill={sensorColor}
    >
      <g transform="translate(2, 2)">
        <WaterFilterSVG width={28} height={28} fill={colorFill} />

        {!invalid && status === "nok" && (
          <g transform="translate(14 -3)">
            <g>
              <path
                d="M24.364,0A5.641,5.641,0,1,0,30,5.641,5.647,5.647,0,0,0,24.364,0"
                transform="translate(-17.725)"
                fill="#ff7600"
              />
              <path
                d="M26.226,7.155l-.409-2.836V2.473h1.625V4.319l-.372,2.836Zm-.382.707h1.6v1.6h-1.6Z"
                transform="translate(-20.111 -0.832)"
                fill="#f5f5f5"
              />
            </g>
          </g>
        )}
      </g>
    </ElementWrapper>
  );
}

function WaterFiltersElements() {
  const { handTool } = useUIContext();
  const { data, waterFilter } = usePlanStore();
  const redirect = useRedirect();

  const waterFilterElement = data?.elements.find(
    (e) =>
      e.type === "system-element" &&
      ["water-filter", "combi-box"].includes(e.systemType)
  );
  const waterFilterHasSensor =
    waterFilterElement?.waterPressureSensor === "sensor";
  const type = waterFilterElement?.systemType;

  const waterFilterInfoMatch = useMatch(WATER_FILTER_INFO_PATH);
  const waterFilterPropertiesMatch = useMatch(WATER_FILTER_PROPERTIES_PATH);

  const selected_water_filter_id = React.useMemo(
    () =>
      waterFilterPropertiesMatch?.params?.water_filter_id ??
      waterFilterInfoMatch?.params?.water_filter_id,
    [
      waterFilterInfoMatch?.params?.water_filter_id,
      waterFilterPropertiesMatch?.params?.water_filter_id,
    ]
  );

  if (waterFilter == null) return null;

  if (!waterFilterHasSensor) return null;

  return (
    <WaterFilter
      key={waterFilter.id}
      id={waterFilter.id}
      x={waterFilter.x}
      y={waterFilter.y}
      selected={selected_water_filter_id === waterFilter.id}
      select={
        !handTool
          ? () => {
              redirect(
                waterFilter.is_fill_properties
                  ? getWaterFilterPropertiesURL(waterFilter.id)
                  : getWaterFilterInfoURL(waterFilter.id),
                true
              );
            }
          : undefined
      }
      status={waterFilter.status}
      invalid={waterFilter.is_fill_properties}
      type={type}
    />
  );
}

export default WaterFiltersElements;
