import React from "react";
import { usePlanStore } from "../../../../../store/PlanStore";
import { useUIContext } from "../../../../../store/uiStore/UIContext";
import Trench from "./Trench";

import {
  TRENCH_PROPERTIES_PATH,
  getTrenchPropertiesURL,
} from "../../../../../utils/path.utils";

import { useMatch } from "react-router-dom";
import useRedirect from "../../../../../hooks/useRedirect";

function Trenches() {
  const { handTool } = useUIContext();
  const { data } = usePlanStore();
  const redirect = useRedirect();
  const trenchPropertiesMatch = useMatch(TRENCH_PROPERTIES_PATH);

  if ((data?.trenches?.length ?? 0) === 0) return null;

  const selectedTrenchId = trenchPropertiesMatch?.params?.trenchId;

  return (
    <>
      {data?.trenches.map((element) => (
        <Trench
          key={element.id}
          element={element}
          selected={selectedTrenchId === element.id}
          select={
            !handTool && selectedTrenchId !== element.id
              ? () => {
                  redirect(getTrenchPropertiesURL(element.id), true);
                }
              : undefined
          }
          lineVisible={false}
          countVisible={true}
        />
      ))}
    </>
  );
}

export default Trenches;
