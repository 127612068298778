import de from "./de-DE.json";
import fr from "./de-FR.json";
import en from "./en-GB.json";

const languages = [
  {
    id: "en-GB",
    locales: [
      "en",
      "en-GB",
      "en-US",
      "en-AU",
      "en-BZ",
      "en-CA",
      "en-CB",
      "en-IE",
      "en-JM",
      "en-NZ",
      "en-PH",
      "en-TT",
      "en-ZA",
      "en-ZW",
    ],
    messages: en,
  },
  {
    id: "de-DE",
    locales: ["de", "de-AT", "de-CH", "de-DE", "de-LI", "de-LU"],
    messages: de,
  },
  {
    id: "fr-FR",
    locales: ["fr", "fr-BE", "fr-CA", "fr-CH", "fr-FR", "fr-LU", "fr-MC"],
    messages: fr,
  },
];

export default languages;
