import ReactDOMServer from "react-dom/server";
import { useUIContext } from "../../../store/uiStore/UIContext";

const getGridLine = (gridSize: number) => {
  const svg = ReactDOMServer.renderToStaticMarkup(
    <svg
      width={gridSize}
      height={gridSize}
      viewBox={`0 0 ${gridSize} ${gridSize}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1={0}
        y1={0}
        x2={gridSize}
        y2={0}
        fill="none"
        stroke="#000"
        strokeWidth={1}
      />
      <line
        x1={0}
        y1={0}
        x2={0}
        y2={gridSize}
        fill="none"
        stroke="#000"
        strokeWidth={1}
      />
    </svg>
  );

  return "data:image/svg+xml;base64," + window.btoa(svg);
};

function Grid() {
  const { offset, zoom, grid, gridTool } = useUIContext();
  const gridSize = grid?.size ? grid.size * zoom : 0;

  if (!gridTool) return null;

  return (
    <div
      style={{
        zIndex: 2,
        background: `url(${getGridLine(gridSize)}) repeat repeat ${
          offset.x
        }px ${offset.y}px`,
        opacity: 0.3,
      }}
    />
  );
}

export default Grid;
