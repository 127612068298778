import React from "react";
import { SensorStatus } from "../../../../store/interface/SensorInterface";
import { usePlanStore } from "../../../../store/PlanStore";
import { useUIContext } from "../../../../store/uiStore/UIContext";
import {
  getSensorInfoURL,
  getSensorPropertiesURL,
  SENSOR_INFO_PATH,
  SENSOR_PROPERTIES_PATH,
} from "../../../../utils/path.utils";
import TEXT_BY_STATUS from "../../page-info/sensor-info/SensorStatuses";

import { useMatch } from "react-router-dom";
import { ReactComponent as SensorSVG } from "../../../../assets/sensor.svg";
import useRedirect from "../../../../hooks/useRedirect";
import ElementWrapper from "./common/ElementWrapper";

function Sensor({
  id,
  x,
  y,
  title,
  status,
  selected,
  select,
  invalid = true,
}: {
  id: number | string;
  x: number;
  y: number;
  title: string;
  status?: SensorStatus;
  selected: boolean;
  select: Function | undefined;
  invalid?: boolean;
}) {
  const statusOptions =
    TEXT_BY_STATUS[status ?? "nok"] ?? TEXT_BY_STATUS["nok"];

  const sensorColor = React.useMemo(() => {
    if (invalid) return "#3e4346";

    return status === "nok" ? "#c3c3c3" : "#7826ff";
  }, [invalid, status]);

  return (
    <ElementWrapper
      id={id}
      x={x}
      y={y}
      size={32}
      select={select}
      selected={selected}
      fill={statusOptions.color}
    >
      <g transform="translate(2, 2)">
        <SensorSVG width={28} height={28} fill={sensorColor} />

        {!invalid && status === "nok" && (
          <g transform="translate(14 -3)">
            <g>
              <path
                d="M24.364,0A5.641,5.641,0,1,0,30,5.641,5.647,5.647,0,0,0,24.364,0"
                transform="translate(-17.725)"
                fill="#ff7600"
              />
              <path
                d="M26.226,7.155l-.409-2.836V2.473h1.625V4.319l-.372,2.836Zm-.382.707h1.6v1.6h-1.6Z"
                transform="translate(-20.111 -0.832)"
                fill="#f5f5f5"
              />
            </g>
          </g>
        )}

        <g transform={`translate(35, 14)`}>
          <text
            x="0"
            y="0"
            className={`sensor-text`}
            textAnchor="left"
            alignmentBaseline="middle"
            dominantBaseline="middle"
            fontSize={12}
          >
            {title}
          </text>
        </g>
      </g>
    </ElementWrapper>
  );
}

function SensorElements() {
  const { handTool } = useUIContext();
  const { sensorList } = usePlanStore();
  const redirect = useRedirect();

  const sensorInfoMatch = useMatch(SENSOR_INFO_PATH);
  const sensorPropertiesMatch = useMatch(SENSOR_PROPERTIES_PATH);

  const selected_sensor_id = React.useMemo(
    () =>
      sensorPropertiesMatch?.params?.sensor_id ??
      sensorInfoMatch?.params?.sensor_id,
    [
      sensorInfoMatch?.params?.sensor_id,
      sensorPropertiesMatch?.params?.sensor_id,
    ]
  );

  if (sensorList == null) return null;

  return (
    <>
      {sensorList.map((sensor) => (
        <Sensor
          key={sensor.id}
          id={sensor.id}
          x={sensor.x}
          y={sensor.y}
          title={sensor.title}
          status={sensor.status}
          selected={selected_sensor_id === sensor.id}
          select={
            !handTool && selected_sensor_id !== sensor.id
              ? () => {
                  redirect(
                    sensor.is_fill_properties
                      ? getSensorPropertiesURL(sensor.id)
                      : getSensorInfoURL(sensor.id),
                    true
                  );
                }
              : undefined
          }
          invalid={sensor.is_fill_properties}
        />
      ))}
    </>
  );
}

export default SensorElements;
