import { useIntl } from "react-intl";
import PageInfo from "./PageInfo";

function SensorStartInfo() {
  const { formatMessage } = useIntl();

  return (
    <PageInfo>
      <div>{formatMessage({ id: "sensor.start.info" })}</div>
    </PageInfo>
  );
}

export default SensorStartInfo;
