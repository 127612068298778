import { ReactComponent as LogoSVG } from "../../../assets/logo.svg";
import { ReactComponent as LogoRundSVG } from "../../../assets/logo-rund.svg";

import RedirectLink from "../../../components/redirect/RedirectLink";

function Logo({ planName }: { planName: string }) {
  return (
    <div className="logo">
      <RedirectLink href="/" className="logo-wrapper">
        <LogoRundSVG />

        <div className="logo-content">
          <LogoSVG />

          <div className="plan-name">
            <span>{planName}</span>
          </div>
        </div>
      </RedirectLink>
    </div>
  );
}

export default Logo;
