import PlanInterface from "../interface/PlanInterface";
import pipelineFactory from "./pipelineFactory";
import areaFactory from "./areaFactory";
import trenchFactory from "./trenchFactory";
import { getIsolatePipelineCircuitsWithFlowAndDrops } from "@dvsproj/ipat-core/pipelineUtils";
import { trenchingPipelines } from "@dvsproj/ipat-core/trenchingUtils";

const defaultPlanName = `Plan_${new Date().toLocaleString()}`;
interface PlanFactoryProps {
  planName?: string;
  background?: any;
  elements: any[];
  scale: number;
  opacity: number;
  pipelines: any[];
  sprinklerSetType: string;
  unit: string;
}

function planFactory(data: PlanFactoryProps): PlanInterface {
  const {
    planName = defaultPlanName,
    background,
    elements,
    scale,
    opacity,
    sprinklerSetType,
    unit,
  } = data;

  const areas = elements
    .filter((e: any) => e.type === "area")
    .map((a: any) => areaFactory(a, scale));
  const circuits = getIsolatePipelineCircuitsWithFlowAndDrops(data);
  const pipelines = circuits.map((c: any) =>
    pipelineFactory(c, elements, areas)
  );

  const trenches = trenchingPipelines(
    pipelines,
    [], //hiddenPipeColors
    10 //trenchesThreshold
  )
    ?.filter((t) => t?.pipes?.length > 1)
    ?.map((t) => trenchFactory(t, pipelines));

  return {
    planName,
    elements,
    sprinklerSetType,
    unit,
    get background() {
      return background;
    },
    get scale() {
      return scale;
    },
    get opacity() {
      return opacity;
    },
    get pipelines() {
      return pipelines;
    },
    get areas() {
      return areas;
    },
    get trenches() {
      return trenches;
    },
  };
}

export default planFactory;
