import React from "react";
import PopupWrapper from "../PopupWrapper";
import { useIntl } from "react-intl";

type DialogContextType = {
  showAlert(text: string, title: string): Promise<unknown>;
  showDialog(text: string, title: string): Promise<unknown>;
};

type AlertConfig = {
  text: string;
  title: string;
  callback: Function;
};

type DialogConfig = {
  text: string;
  title: string;
  confirmCallback: Function;
  denyCallback: Function;
};

const AlertComponent = ({ title, text, callback }: AlertConfig) => {
  const { formatMessage } = useIntl();
  return (
    <PopupWrapper className="alert" title={title}>
      <div className="text">{text}</div>
      <div className="buttons">
        <button
          type="button"
          className="ok"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            callback();
          }}
        >
          {formatMessage({ id: "common.ok" })}
        </button>
      </div>
    </PopupWrapper>
  );
};

const DialogComponent = ({
  title,
  text,
  confirmCallback,
  denyCallback,
}: DialogConfig) => {
  const { formatMessage } = useIntl();
  return (
    <PopupWrapper className="dialog" title={title}>
      <div className="text">{text}</div>
      <div className="buttons">
        <button
          type="button"
          className="confirm"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            confirmCallback();
          }}
        >
          {formatMessage({ id: "common.ok" })}
        </button>
        <button
          type="button"
          className="deny"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            denyCallback();
          }}
        >
          {formatMessage({ id: "common.cancel" })}
        </button>
      </div>
    </PopupWrapper>
  );
};

const DialogContext = React.createContext<DialogContextType>({
  async showAlert(text: string, title: string) {},
  async showDialog(text, title) {},
});

function DialogContextProvider({ children }: { children: JSX.Element }) {
  const [alertConfig, setAlertConfig] = React.useState<
    AlertConfig | undefined
  >();

  const [dialogConfig, setDialogConfig] = React.useState<
    DialogConfig | undefined
  >();

  const showAlert = React.useCallback((text: string, title: string) => {
    return new Promise((resolve) => {
      setAlertConfig({
        text,
        title,
        callback: () => {
          resolve(undefined);
          setTimeout(() => setAlertConfig(undefined));
        },
      });
    });
  }, []);

  const showDialog = React.useCallback((text: string, title: string) => {
    return new Promise((resolve) => {
      setDialogConfig({
        text,
        title,
        confirmCallback: () => {
          resolve(true);
          setTimeout(() => setDialogConfig(undefined));
        },
        denyCallback: () => {
          resolve(false);
          setTimeout(() => setDialogConfig(undefined));
        },
      });
    });
  }, []);

  const actions = React.useMemo(
    () => ({
      showAlert,
      showDialog,
    }),
    [showAlert, showDialog]
  );

  return (
    <DialogContext.Provider value={actions}>
      {children}

      {alertConfig != null && <AlertComponent {...alertConfig} />}
      {dialogConfig != null && <DialogComponent {...dialogConfig} />}
    </DialogContext.Provider>
  );
}

export { DialogContextProvider, DialogContext };
