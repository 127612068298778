import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  ForwardedRef,
  RefObject,
} from "react";
import useFormatter from "../../../hooks/useFormatter";
import {
  convertToNumber,
  convertToString as _convertToString,
  validateNumberValue,
} from "./number.utils";

interface NumberInputProps {
  defaultValue: number | undefined;
  isInteger?: boolean;
  isNegative?: boolean;
  precision?: number;
  onChange: (value: number | undefined) => void;
  onBlur: (value: number | undefined) => void;
  max?: number;
  className?: string;
  [key: string]: any;
}

const NumberInput = forwardRef(
  (
    {
      defaultValue,
      isInteger = false,
      isNegative = false,
      precision = 1,
      onChange: _onChange = () => {},
      onBlur = () => {},
      max,
      className,
      ...props
    }: NumberInputProps,
    ref: ForwardedRef<any>
  ) => {
    const { decimalSeparator } = useFormatter();

    const convertToString = React.useCallback(
      (v: number | undefined) => {
        return _convertToString(v, decimalSeparator, isInteger, precision);
      },
      [decimalSeparator, isInteger, precision]
    );

    const [curValue, changeCurValue] = React.useState(
      convertToString(defaultValue)
    );

    const input = useRef() as RefObject<HTMLInputElement>;

    useImperativeHandle(ref, () => ({
      value: curValue,
      focus: () => input.current?.focus(),
      blur: () => input.current?.focus(),
    }));

    const onChange = React.useCallback(
      (e: any) => {
        const v = validateNumberValue(
          e.target.value,
          decimalSeparator,
          isNegative,
          precision
        );

        const intVal = convertToNumber(v, decimalSeparator);
        if (intVal != null && max != null && intVal > max) {
          changeCurValue(convertToString(max));
        } else {
          changeCurValue(v);
        }
      },
      [convertToString, isNegative, max, precision, decimalSeparator]
    );

    return (
      <input
        className={["number-input", className].filter((e) => e).join(" ")}
        type="text"
        value={curValue}
        onChange={(e) => {
          onChange(e);
          _onChange(convertToNumber(curValue, decimalSeparator));
        }}
        onBlur={() => {
          const tv = convertToNumber(curValue, decimalSeparator);
          _onChange(tv);
          changeCurValue(convertToString(tv));
          onBlur(tv);
        }}
        ref={input}
        {...props}
      />
    );
  }
);

export default NumberInput;
