import { ReactComponent as InformSVG } from "../../../../assets/inform.svg";
import Dropdown, {
  DropdownOptions,
} from "../../../../components/dropdown/Dropdown";
import Link from "../../../../components/link/Link";

function PropertiesWithDropdown({
  value,
  onChange,
  data,
  title,
  className,
  disabled,
  informURL,
}: {
  value: any;
  onChange: Function;
  data: DropdownOptions;
  title: string;
  className?: string;
  disabled?: boolean;
  informURL?: string;
}) {
  return (
    <div className={[className, "with-dropdown"].filter((e) => e).join(" ")}>
      <div
        className={["title", informURL ? "inform" : undefined]
          .filter((e) => e)
          .join(" ")}
      >
        <span>{title}</span>
        {informURL && (
          <Link href={informURL} target="__blank">
            <InformSVG />
          </Link>
        )}
      </div>
      <Dropdown
        value={value}
        onChange={onChange}
        data={data}
        disabled={disabled}
      />
    </div>
  );
}

export default PropertiesWithDropdown;
