import { getImageSize, getRandom, sleep } from "../utils/ui.utils";
import { get, urlDecorator } from "../utils/http.utils";
import WeatherDataInterface from "../store/interface/WeatherDataInterface";

const CORS_CONFIG = {
  credentials: "omit",
  mode: "cors",
};

class ResourceApi {
  session: string | undefined = "";
  csrf_token: string | undefined = "";

  constructor() {
    this.session = document.cookie
      .split("; ")
      .map((c) => c.split("="))
      .find(([k]) => k === "JTLSHOP")?.[1];
  }
  async _checkResponse(res: Response) {
    const { status, error_msg, response } = await res.json();

    if (status !== "ok" || error_msg?.length > 0) {
      throw new Error(error_msg ?? (await res.text()));
    }

    return response;
  }

  async fetchWeatherData(
    planId: number | string
  ): Promise<WeatherDataInterface> {
    console.debug("Fetch weather data");

    await sleep(300);

    return {
      date: Date.now(),
      temperature: getRandom(-5, 10),
      moisture: getRandom(30, 70),
      weather: "snow",
    };
  }

  async fetchPlan(planId: string): Promise<any> {
    console.debug("Fetch plan");

    const response = await get(
      urlDecorator(
        `$SHOP_URL/plugins/dvs_planer/frontend/api.php?action=getPlan&session=${this.session}`
      ),
      {
        ident: planId,
        csrf_token: this.csrf_token,
      }
    );

    const {
      data: dataStr,
      image,
      ident,
      name,
      status: planStatus,
    } = await this._checkResponse(response);

    const data = JSON.parse(dataStr);
    if (planStatus != null) data.status = planStatus;

    if (data) {
      if (image) {
        const background = data?.background;

        let imageSize = {
          width: background?.width,
          height: background?.height,
        };

        try {
          imageSize = await getImageSize(image);
        } catch (error) {
          console.error(error);
        } finally {
          data.background = {
            ...data.background,
            src: image,
            ...imageSize,
          };
        }
      }

      return {
        planName: name,
        planId: ident,
        ...data,
      };
    }

    return undefined;
  }

  async fetchUserInfo(): Promise<any> {
    console.debug("Fetch user");

    const response = await get(
      urlDecorator(
        `$SHOP_URL/plugins/dvs_planer/frontend/api.php?action=userinfo&session=${this.session}`
      ),
      undefined,
      CORS_CONFIG
    );

    const result = await this._checkResponse(response);
    this.csrf_token = result?.csrf_token;
    return result;
  }
}

const resourceApi = new ResourceApi();
export default resourceApi;
