import React from "react";

function PropertiesProgressBar({
  value,
  hasError,
}: {
  value: number;
  hasError?: boolean;
}) {
  let rightBorder = 5 - (100 - Math.round(value));
  rightBorder = rightBorder > 0 ? rightBorder : 0;
  if (value > 100) {
    rightBorder = 5;
  }
  return (
    <div className={`dvs-progress-bar ${hasError ? "error" : ""}`}>
      <div
        className="dvs-progress"
        style={{
          width: value + "%",
          borderRadius: `5px ${rightBorder}px ${rightBorder}px 5px`,
        }}
      />
    </div>
  );
}

export default PropertiesProgressBar;
