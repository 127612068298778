import React from "react";
import { useIntl } from "react-intl";
import SensorInterface from "../../../store/interface/SensorInterface";
import { usePlanStore } from "../../../store/PlanStore";
import PageInfo from "./PageInfo";
import TEXT_BY_STATUS from "./sensor-info/SensorStatuses";

import { ReactComponent as MoistureSVG } from "../../../assets/moisture.svg";
import { ReactComponent as SnowSVG } from "../../../assets/snow.svg";
import SensorStartInfo from "./SensorStartInfo";
import usePolling from "../../../hooks/usePolling";
import resourceApi from "../../../service/resourceApi";
import WeatherDataInterface from "../../../store/interface/WeatherDataInterface";
import useFormatter from "../../../hooks/useFormatter";

function WeatherItem({
  Icon,
  text,
  className,
}: {
  Icon?: React.ComponentType<any>;
  text?: string;
  className?: string;
}) {
  return (
    <div className={["weather-item", className].filter((e) => e).join(" ")}>
      {Icon && (
        <div className="icon">
          <Icon />
        </div>
      )}
      <span className="text">{text}</span>
    </div>
  );
}

function SensorInfoItem({ sensor }: { sensor: SensorInterface }) {
  const { formatMessage } = useIntl();
  const { Icon, text, color } =
    TEXT_BY_STATUS[sensor.status ?? "nok"] ?? TEXT_BY_STATUS["nok"];

  return (
    <div className="sensor-info-item">
      <div className="icon">
        <Icon style={undefined} />
      </div>
      <div className="text" style={{ color }}>
        <strong>{sensor.name}:</strong> {formatMessage(text)}
      </div>
    </div>
  );
}

// eslint-disable-next-line
function WeatherElement() {
  const { formatDate } = useIntl();
  const { formatTemperature, formatMoisture } = useFormatter();
  const { sensorList, planId } = usePlanStore();

  const [weatherData, changeWeatherData] = React.useState<
    WeatherDataInterface | undefined
  >(undefined);

  const fetchWeatherData = React.useCallback(
    () =>
      planId != null
        ? async () => {
            const data = await resourceApi.fetchWeatherData(planId);
            changeWeatherData(data);
          }
        : undefined,
    [planId]
  );

  usePolling(fetchWeatherData, 60 * 1000);

  if (weatherData == null || sensorList == null) return null;

  return (
    <PageInfo>
      <div className="sensor-list-info">
        <div className="weather-information-wrap">
          <div className="date">
            {formatDate(weatherData.date, {
              weekday: "short",
              day: "2-digit",
              month: "long",
              year: "numeric",
            })}
          </div>
          <div className="weather-information">
            <WeatherItem
              className="temperature"
              text={formatTemperature(weatherData.temperature)}
            />
            <div className="separator" />
            <WeatherItem
              className="moisture"
              Icon={MoistureSVG}
              text={formatMoisture(weatherData.moisture, 0)}
            />
            {weatherData.weather === "snow" && (
              <>
                <div className="separator" />
                <WeatherItem Icon={SnowSVG} />
              </>
            )}
          </div>
        </div>
        <div
          className={[
            "sensor-info-list",
            sensorList.length > 4 ? "grid-list" : undefined,
          ]
            .filter((e) => e)
            .join(" ")}
        >
          {sensorList.map((sensor, idx) => (
            <SensorInfoItem key={idx} sensor={sensor} />
          ))}
        </div>
      </div>
    </PageInfo>
  );
}

function SensorListInfo() {
  const { sensorList = [] } = usePlanStore();

  if (sensorList.filter((s) => s.is_fill_properties).length > 0) {
    return <SensorStartInfo />;
  }

  return null;
}

export default SensorListInfo;
