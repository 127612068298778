import React from "react";
import { ReactComponent as InformSVG } from "../../../../assets/inform.svg";
import Link from "../../../../components/link/Link";

function PropertiesItemWithMultilineInput({
  title,
  className,
  children,
  informURL,
}: {
  title: string | React.ReactElement;
  children: JSX.Element;
  className: string;
  informURL?: string;
}) {
  return (
    <div
      className={["with-multiline-input", className].filter((e) => e).join(" ")}
    >
      <div
        className={["title", informURL ? "inform" : undefined]
          .filter((e) => e)
          .join(" ")}
      >
        <span>{title}</span>
        {informURL && (
          <Link href={informURL} target="_blank" rel="noopener noreferrer">
            <InformSVG />
          </Link>
        )}
      </div>
      <div className="setting-value">{children}</div>
    </div>
  );
}

export default PropertiesItemWithMultilineInput;
