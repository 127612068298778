import { debounce } from "lodash";
import React, { RefObject } from "react";
import { useIntl } from "react-intl";

import { usePlanStore } from "../../../../../store/PlanStore";
import { useUIContext } from "../../../../../store/uiStore/UIContext";
import { sleep } from "../../../../../utils/ui.utils";
import TabsNavigation from "../TabsNavigationButtons";
import {
  CircuitAssigned,
  ControllerPropertiesContext,
} from "./ControllerPropertiesContext";
import IrrigationSettings from "./sensor-settings/IrrigationSettings";
import LivingEnvironment from "./sensor-settings/LivingEnvironment";

import { ReactComponent as CloseSVG } from "../../../../../assets/close-dialog.svg";
import { ReactComponent as PopupArrowRightSVG } from "../../../../../assets/popup-arrow-rd.svg";

const defaultTabs = [
  {
    value: "living_environment",
    labelFmt: (formatMessage) =>
      formatMessage({ id: "controller.properties.living_environment.title" }),
    Component: ({ disabled, data, onDataChange, changedData, relays }) => (
      <LivingEnvironment
        disabled={disabled}
        data={data}
        onDataChange={onDataChange}
        relays={relays}
      />
    ),
  },
  {
    value: "irrigation_settings",
    labelFmt: (formatMessage) =>
      formatMessage({ id: "controller.properties.irrigation_settings.title" }),
    Component: ({ disabled, data, onDataChange, relays }) => (
      <IrrigationSettings
        disabled={disabled}
        data={data}
        onDataChange={onDataChange}
        relays={relays}
      />
    ),
  },
];

const ValvesSettingsElement = ({
  disabled,
  activeTab,
  defaultCircuitsData,
  close,
  save,
}: Readonly<{
  disabled?: boolean;
  activeTab?: "living_environment" | "irrigation_settings";
  defaultCircuitsData: CircuitAssigned;
  close: Function;
  save: (value: CircuitAssigned) => Promise<void>;
}>) => {
  const { formatMessage } = useIntl();
  const { rightSideRef } = useUIContext();

  const { hydrawiseSettings } = usePlanStore();

  const overlayRef = React.useRef() as RefObject<HTMLDivElement>;
  const valvesSettingsRef = React.useRef() as RefObject<HTMLDivElement>;

  React.useEffect(() => {
    const rightSideNode = rightSideRef.current;
    const valvesSettingsNode = valvesSettingsRef.current;
    const bottomOffset = 87;

    if (valvesSettingsNode && rightSideNode) {
      const resizeListener = () => {
        valvesSettingsNode.style.top = `${rightSideNode.offsetTop}px`;
        valvesSettingsNode.style.right = "auto";

        valvesSettingsNode.style.height = `${
          rightSideNode.offsetHeight - bottomOffset
        }px`;
      };
      resizeListener();

      window.addEventListener("resize", resizeListener);
      return () => {
        window.removeEventListener("resize", resizeListener);
      };
    }
  }, [rightSideRef]);

  const tabs = React.useMemo(() => {
    return defaultTabs.map((t) => ({
      value: t.value,
      label: t.labelFmt(formatMessage),
      Component: t.Component,
    }));
  }, [formatMessage]);
  const [selectedTab, setSelectedTab] = React.useState(
    activeTab ?? tabs[0]?.value
  );

  const [, forceUpdate] = React.useReducer((x) => x + 1, 0);

  const circuitsRef = React.useRef(
    defaultCircuitsData.map((c) => {
      const data = Object.fromEntries(
        Object.entries(c).map(([k, o]) => [k, { defaultValue: o, value: o }])
      );
      delete data.id;

      return {
        id: c.id,
        data,
        modified: false,
      };
    })
  );

  const onDataChange = (id: string, key: string, value: any) => {
    const circuit = circuitsRef.current.find((c) => id === c.id);
    if (circuit == null) throw new Error("Invalid circuit id");

    const data = circuit.data[key];
    if (data == null) throw new Error("Invalid key");

    data.value = value;
    forceUpdate();

    if (data.defaultValue !== data.value) {
      circuit.modified = true;
    } else {
      circuit.modified = Object.values(circuit.data).some(
        (d) => d.defaultValue !== d.value
      );
    }
  };

  const thereAreChanges = circuitsRef.current.some((d) => d.modified);

  const relays = React.useMemo(() => {
    return (
      hydrawiseSettings?.relays?.map((s, i) => ({
        value: s.num,
        label: (
          <>
            {s.name}
            {"\n"}
            <span style={{ fontSize: "0.75em" }}>
              {formatMessage({ id: "assignment.valves.zone_number.prefix" })}{" "}
              {s.num}
            </span>
          </>
        ),
      })) ?? []
    );
  }, [formatMessage, hydrawiseSettings?.relays]);

  const [highlightStyle, setHighlightStyle] = React.useState("");
  const toggleHighlightStyle = debounce(async function () {
    setHighlightStyle("highlight");
    await sleep(1000);
    setHighlightStyle("");
  }, 200);

  // useOuterClick doesn't work for some reason if table state isn't changed
  React.useEffect(() => {
    const elementNode = valvesSettingsRef?.current;
    const overlayRefCopy = overlayRef.current;

    if (overlayRefCopy && elementNode) {
      const clickHandler = (e: any) => {
        if (!elementNode.contains(e.target)) {
          return thereAreChanges ? toggleHighlightStyle() : close();
        }
      };

      overlayRefCopy?.addEventListener("click", clickHandler, true);
      return () => {
        overlayRefCopy?.removeEventListener("click", clickHandler, true);
      };
    }
  });

  return (
    <div
      style={{
        zIndex: 3,
        position: "absolute",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(1, 0, 0, 0.5)",
      }}
      ref={overlayRef}
    >
      <div className="valves-settings" ref={valvesSettingsRef}>
        <div className="valves-settings-wrapper">
          <button
            className="close-settings-button"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              close();
            }}
          >
            <CloseSVG width="100%" height="100%" />
          </button>

          <TabsNavigation
            disabled={false}
            value={selectedTab}
            onChange={(tab) => {
              setSelectedTab(tab);
            }}
            data={tabs}
            tabComponentProps={{
              disabled,
              data: circuitsRef.current,
              onDataChange,
              changedData: [],
              relays,
            }}
          />

          <div className="valves-settings-dialog">
            <button
              className={highlightStyle}
              onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();

                close();
              }}
            >
              {formatMessage({
                id: "controller.properties.valves_settings.cancel",
              })}
            </button>

            <button
              disabled={
                circuitsRef.current == null ||
                circuitsRef.current.length === 0 ||
                disabled
              }
              className={[
                highlightStyle,
                thereAreChanges ? "on-changes-highlight" : "",
              ]
                .filter((style) => style != null)
                .join(" ")}
              onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();

                const circuits = circuitsRef.current.map((c) => {
                  const tranformedData = Object.fromEntries(
                    Object.entries(c.data).map(([k, o]) => [k, o.value])
                  );
                  tranformedData.id = c.id;

                  return tranformedData;
                }) as CircuitAssigned;

                await save(circuits);
              }}
            >
              {formatMessage({
                id: "controller.properties.valves_settings.save_configuration",
              })}
            </button>
          </div>
        </div>
      </div>
      <PopupArrowRightSVG
        style={{
          position: "absolute",
          width: "30px",
          right: "228px",
          bottom: "228px",
        }}
      />
    </div>
  );
};

function ValvesSettingsForm() {
  const {
    circuitAssigned,
    activeController,
    controllerEnabled,
    saveHydrawiseSettings,
    locationName,
    noIrrigationTimeStart,
    noIrrigationTimeStop,
    noIrrigationDays,
    noIrrigationTemperatureLimit,
    noIrrigationTemperatureEnabled,
    noIrrigationWindSpeedLimit,
    noIrrigationWindSpeedEnabled,
    hasValvesSettingsForm,
    toggleValvesSettings,
    changeActiveController,
  } = React.useContext(ControllerPropertiesContext);

  if (!hasValvesSettingsForm.value) return null;

  const circuitAssignedSorted = structuredClone(
    circuitAssigned.toSorted((a, b) => a.id.localeCompare(b.id))
  );

  return (
    <ValvesSettingsElement
      disabled={activeController === "plant-et" && controllerEnabled}
      activeTab={hasValvesSettingsForm.activeTab}
      defaultCircuitsData={circuitAssignedSorted}
      close={() => {
        toggleValvesSettings(false);

        if (
          hasValvesSettingsForm.activeTab === "irrigation_settings" &&
          !controllerEnabled
        ) {
          // user got here from activating et-controller
          changeActiveController(undefined);
        }
      }}
      save={async (value) => {
        toggleValvesSettings(false);

        await saveHydrawiseSettings(
          activeController,
          value,
          locationName,
          noIrrigationTimeStart,
          noIrrigationTimeStop,
          noIrrigationDays,
          noIrrigationTemperatureLimit,
          noIrrigationTemperatureEnabled,
          noIrrigationWindSpeedLimit,
          noIrrigationWindSpeedEnabled
        );
      }}
    />
  );
}

export default ValvesSettingsForm;
