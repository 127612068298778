import PopupWrapper from "./PopupWrapper";
import CalculatonGifURL from "../../../assets/calculation.gif";

function CalculationAnimate({ title, text }: { title: string; text?: string }) {
  return (
    <PopupWrapper title={title} className="calculation">
      <div>
        <div className="calculation-icon">
          <img alt="" width="75px" height="90px" src={CalculatonGifURL} />
        </div>
        <div className="calculation-text">{text}</div>
      </div>
    </PopupWrapper>
  );
}

export default CalculationAnimate;
