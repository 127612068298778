export function cookies(): {
  language: string;
} {
  return document.cookie.split(";").reduce((res: any, e: string) => {
    const item = e.split("=");
    res[item[0].trim()] = item[1];
    return res;
  }, {});
}

/**
 * Calculates length of the quadratic bezier curve
 *
 * @param {*} p0 start point {x, y}
 * @param {*} p1 bezier point
 * @param {*} p2 end point
 */
const quadraticBezierLength = (p0: any, p1: any, p2: any) => {
  const ax = p0.x - 2 * p1.x + p2.x;
  const ay = p0.y - 2 * p1.y + p2.y;
  const bx = 2 * p1.x - 2 * p0.x;
  const by = 2 * p1.y - 2 * p0.y;
  const A = 4 * (ax * ax + ay * ay);
  const B = 4 * (ax * bx + ay * by);
  const C = bx * bx + by * by;

  const Sabc = 2 * Math.sqrt(A + B + C);
  const A_2 = Math.sqrt(A);
  const A_32 = 2 * A * A_2;
  const C_2 = 2 * Math.sqrt(C);
  const BA = B / A_2;

  return (
    (A_32 * Sabc +
      A_2 * B * (Sabc - C_2) +
      (4 * C * A - B * B) * Math.log((2 * A_2 + BA + Sabc) / (BA + C_2))) /
    (4 * A_32)
  );
};

/**
 * Calculates length of the bezier curve.
 *
 * @param {*} p0 start point {x, y}
 * @param {*} p1 end point
 * @param {*} bp bezier point - optional
 */
function lineLength(p0: any, p1: any, bp?: any) {
  return bp != null && !bp.isDefault
    ? quadraticBezierLength(p0, bp, p1)
    : Math.sqrt((p0.x - p1.x) ** 2 + (p0.y - p1.y) ** 2);
}

function round(n: number, digits: number = 0) {
  return (Math.sign(n) * Math.round(Math.abs(n) * 10 ** digits)) / 10 ** digits;
}

/**
 * Helper function to ease of async/await usage.
 *
 * @param {*} ms
 */
function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}

function getImageSize(
  imageSrc: string
): Promise<{ width: number; height: number }> {
  return new Promise((resolve, reject) => {
    if (imageSrc == null) return reject(`imageSrc is empty`);

    const i = new Image();
    i.onload = () => {
      resolve({
        width: i.width,
        height: i.height,
      });
    };
    i.src = imageSrc;
  });
}

function getRandom(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

export { lineLength, round, sleep, getImageSize, getRandom };
