import { v1 as uuid } from "uuid";

import SensorInterface, {
  SensorSoilType,
  SensorPlantType,
} from "../interface/SensorInterface";

function sensorFactory({
  id = uuid(),
  x,
  y,

  prefix,
  name,
  disabled = false,
  serial_number,
  soil_type,
  plant_type,
  max_soil_moisture = 50,
  min_soil_moisture = 27,
  wilting_point = 23,
  upper_sensor,
  lower_sensor,
  lower_sensor_enabled = false,

  //
  moisture1,
  moisture2,
  temperature,
  battery,
  status = "nok",
}: {
  id?: string;
  x: number;
  y: number;
  moisture1?: number;
  moisture2?: number;
  temperature?: number;
  battery?: number;
  status?: "ok" | "nok";
  prefix: string;
  name?: string;
  disabled?: boolean;
  serial_number?: string;
  soil_type?: SensorSoilType;
  plant_type?: SensorPlantType;
  max_soil_moisture?: number;
  min_soil_moisture?: number;
  wilting_point?: number;
  upper_sensor?: number;
  lower_sensor?: number;
  lower_sensor_enabled?: boolean;
}): SensorInterface {
  return {
    get id() {
      return id + "";
    },
    get x() {
      return x;
    },
    get y() {
      return y;
    },

    // properties
    prefix,
    name,

    get title() {
      return [prefix, name?.trim()].filter((e) => e).join(" - ");
    },

    disabled,
    serial_number,

    // config,
    soil_type,
    plant_type,
    max_soil_moisture: +max_soil_moisture,
    min_soil_moisture: +min_soil_moisture,
    wilting_point: +wilting_point,
    upper_sensor: upper_sensor ? +upper_sensor : undefined,
    lower_sensor: lower_sensor ? +lower_sensor : undefined,
    lower_sensor_enabled,

    get is_fill_properties() {
      return this.serial_number == null || this.serial_number.trim() === "";
    },

    // not saved
    get moisture1() {
      return moisture1;
    },
    get moisture2() {
      return moisture2;
    },
    get temperature() {
      return temperature;
    },
    get battery() {
      if (battery == null) return undefined;
      if (battery >= 3.5) return 100;
      if (battery >= 3.45 && battery < 3.5) return 80;
      if (battery >= 3.4 && battery < 3.45) return 60;
      if (battery >= 3.35 && battery < 3.4) return 40;
      if (battery >= 3.3 && battery < 3.35) return 15;

      return 0;
    },
    get status() {
      const statusValue = status?.toLowerCase();

      if (statusValue === "nok") {
        return "nok";
      }

      if (
        this.max_soil_moisture > 0 &&
        [this.moisture1, this.moisture2].some(
          (el) => el != null && el > this.max_soil_moisture
        )
      ) {
        return "much-water";
      }

      if (
        this.min_soil_moisture > 0 &&
        [this.moisture1, this.moisture2].some(
          (el) => el != null && el < this.min_soil_moisture
        )
      ) {
        return "little-water";
      }

      return "ok";
    },
  };
}

export default sensorFactory;
