import React from "react";
import usePolling from "../../../../../hooks/usePolling";
import HistoryChart from "./HistoryChart";
import calcApi from "../../../../../service/calcApi";
import FertilizerInterface from "../../../../../store/interface/FertilizerInterface";
import { useUserStore } from "../../../../../store/UserStore";

function HistoryLine({
  planId,
  fertilizer,
  active,
}: {
  planId: string | undefined;
  fertilizer: FertilizerInterface;
  active: boolean;
}) {
  const userStore = useUserStore();
  const [history, changeHistory] = React.useState<any>([]);

  const fetchFertilizerHistory = React.useMemo(
    () =>
      !fertilizer?.is_fill_properties && fertilizer?.serial_number != null
        ? async () => {
            if (fertilizer.serial_number == null) return;

            const data = await calcApi.fetchFertilizerHistory(
              planId,
              fertilizer.serial_number,
              userStore?.requestLogin
            );
            changeHistory(
              (data ?? []).map((d) => ({
                ...d,
                date: d.date ? +new Date(d.date) : undefined,
              }))
            );
          }
        : undefined,
    [
      fertilizer?.is_fill_properties,
      fertilizer.serial_number,
      planId,
      userStore?.requestLogin,
    ]
  );

  usePolling(fetchFertilizerHistory, 10 * 60 * 1000);

  const minWeightLimit = React.useMemo(() => {
    return fertilizer.min_weight_limit ?? 0.5;
  }, [fertilizer?.min_weight_limit]);

  return (
    <div className="history-line">
      <div className="separator" style={{ borderColor: "transparent" }} />
      <HistoryChart
        style={{ minHeight: 450 }}
        data={history}
        minWeightLimit={minWeightLimit}
      />
    </div>
  );
}

export default HistoryLine;
