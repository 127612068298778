import useRedirect from "../../hooks/useRedirect";

function RedirectLink({
  href,
  children,
  replace = false,
  ...props
}: {
  href: string;
  replace?: boolean;
  children: string | JSX.Element | JSX.Element[];
  [key: string]: any;
}) {
  const redirect = useRedirect();

  return (
    <a
      {...props}
      href={href}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        redirect(href, replace);
      }}
    >
      {children}
    </a>
  );
}

export default RedirectLink;
