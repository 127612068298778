import React from "react";
import useFetch from "../../../../hooks/useFetch";
import calcApi from "../../../../service/calcApi";
import { usePlanStore } from "../../../../store/PlanStore";
import { sizeInPixelByMeters } from "@dvsproj/ipat-core/planUtils";
import { useUserStore } from "../../../../store/UserStore";

function SensorCoverage() {
  const userStore = useUserStore();
  const plan = usePlanStore();

  const [sensorCoverage, changeSensorCoverage] = React.useState<
    | {
        offsetX: number | undefined;
        offsetY: number | undefined;
        width: number | undefined;
        height: number | undefined;
        data: string | undefined;
        colors: string[] | undefined;
      }
    | undefined
  >();

  const fetchSensorCoverage = React.useMemo(
    () =>
      plan?.data != null
        ? async () => {
            const data = await calcApi.generateSensorCoverage(
              plan.planId,
              plan.data,
              userStore?.requestLogin
            );
            changeSensorCoverage(data);
          }
        : undefined,
    [plan.data, plan.planId, userStore?.requestLogin]
  );

  useFetch(fetchSensorCoverage);

  const converter = (val) =>
    val ? sizeInPixelByMeters(val / 10, plan?.data?.scale) : 0;

  return (
    <g
      transform={`translate(
      ${converter(sensorCoverage?.offsetX) ?? 0}, ${
        converter(sensorCoverage?.offsetY) ?? 0
      })`}
    >
      <image
        href={sensorCoverage?.data}
        width={converter(sensorCoverage?.width)}
        height={converter(sensorCoverage?.height)}
        x={0}
        y={0}
        opacity="0.7"
      />
    </g>
  );
}

export default SensorCoverage;
