import { useIntl } from "react-intl";
import { useUIContext } from "../../../../store/uiStore/UIContext";

import ToolItem from "./ToolItem";

import { ReactComponent as GridSVG } from "../../../../assets/grid.svg";

function GridTool() {
  const { formatMessage } = useIntl();
  const { gridTool, changeGridTool } = useUIContext();

  return (
    <ToolItem
      hasActive={gridTool}
      Icon={GridSVG}
      onClick={() => changeGridTool(!gridTool)}
      tooltip={formatMessage({ id: "tools.grid.tooltip" })}
    />
  );
}

export default GridTool;
