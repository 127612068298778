import React from "react";
import TooltipComponent from "./TooltipComponent";
import TooltipInterface from "./TooltipInterface";

interface TooltipContextInterface {
  setTooltip: Function;
}

const TooltipContext = React.createContext<TooltipContextInterface>({
  setTooltip: () => {},
});

function TooltipProvider({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) {
  const [tooltip, setTooltip] = React.useState<TooltipInterface | undefined>();

  return (
    <TooltipContext.Provider
      value={{
        setTooltip,
      }}
    >
      {children}
      {tooltip != null ? <TooltipComponent tooltip={tooltip} /> : null}
    </TooltipContext.Provider>
  );
}

function useTooltip() {
  const { setTooltip } = React.useContext(TooltipContext);

  const showTooltip = React.useCallback(
    (e: any, text: string, type: string = "top") => {
      const node = e?.target;
      if (node == null) return null;

      setTooltip({
        text,
        position: node.getBoundingClientRect(),
        type,
      });
    },
    [setTooltip]
  );

  const hideTooltip = React.useCallback(() => {
    setTooltip(undefined);
  }, [setTooltip]);

  return {
    showTooltip,
    hideTooltip,
  };
}

export { TooltipProvider, useTooltip };
