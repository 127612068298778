import React from "react";
import { useIntl } from "react-intl";

import { MultiSelectDropdownOptions } from "../../../../../components/dropdown-with-multiselect/multiselect-dropdown";
import TextInput from "../../../../../components/inputs/TextInput";
import NumberInput from "../../../../../components/inputs/number-input/NumberInput";
import {
  getValidHoursString,
  getValidIntegerString,
} from "../../../../../utils/format.utils";
import { DialogContext } from "../../../popup/dialog/DialogContext";
import PropertiesItemWithMultilineInput from "../PropertiesItemWithMultilineInput";
import PropertiesWithButton from "../PropertiesWithButton";
import PropertiesWithCheckbox from "../PropertiesWithCheckbox";
import PropertiesWithMultiSelectDropDown from "../PropertiesWithMultiSelectDropdown";
import PropertiesWithToggle from "../PropertiesWithToggle";
import { ControllerPropertiesContext } from "./ControllerPropertiesContext";

function EvapotranspirationForm() {
  const { formatMessage } = useIntl();

  const { showDialog } = React.useContext(DialogContext);

  const {
    activeController,
    changeActiveController,
    circuitAssigned,
    hasValvesSettingsForm,
    toggleValvesSettings,
    locationName,
    changeLocationName,
    noIrrigationTimeStart,
    changeNoIrrigationTimeStart,
    noIrrigationTimeStop,
    changeNoIrrigationTimeStop,
    noIrrigationDays,
    changeNoIrrigationDays,
    noIrrigationTemperatureLimit,
    changeNoIrrigationTemperatureLimit,
    noIrrigationTemperatureEnabled,
    changeNoIrrigationTemperatureEnabled,
    noIrrigationWindSpeedLimit,
    changeNoIrrigationWindSpeedLimit,
    noIrrigationWindSpeedEnabled,
    changeNoIrrigationWindSpeedEnabled,
    saveHydrawiseSettings,
  } = React.useContext(ControllerPropertiesContext);

  const days: MultiSelectDropdownOptions = [
    {
      value: 0,
      label: formatMessage({
        id: "controller.properties.evapotranspiration.no_irrigation_days.monday",
      }),
    },
    {
      value: 1,
      label: formatMessage({
        id: "controller.properties.evapotranspiration.no_irrigation_days.tuesday",
      }),
    },
    {
      value: 2,
      label: formatMessage({
        id: "controller.properties.evapotranspiration.no_irrigation_days.wednesday",
      }),
    },
    {
      value: 3,
      label: formatMessage({
        id: "controller.properties.evapotranspiration.no_irrigation_days.thursday",
      }),
    },
    {
      value: 4,
      label: formatMessage({
        id: "controller.properties.evapotranspiration.no_irrigation_days.friday",
      }),
    },
    {
      value: 5,
      label: formatMessage({
        id: "controller.properties.evapotranspiration.no_irrigation_days.saturday",
      }),
    },
    {
      value: 6,
      label: formatMessage({
        id: "controller.properties.evapotranspiration.no_irrigation_days.sunday",
      }),
    },
  ];

  const changeIrrigationTime = React.useCallback(
    (type: string, time: string) => {
      let startTime = type === "start" ? time : noIrrigationTimeStart;
      let stopTime = type === "stop" ? time : noIrrigationTimeStop;

      const modifiedStopTime = getValidHoursString(
        `${+startTime === 23 ? 0 : +startTime + 1}`
      );
      const modifiedStartTime = getValidHoursString(
        `${+stopTime === 0 ? 23 : +stopTime - 1}`
      );

      if (startTime === stopTime) {
        startTime = type === "start" ? startTime : modifiedStartTime;
        stopTime = type === "stop" ? stopTime : modifiedStopTime;
      }

      changeNoIrrigationTimeStart(startTime);
      changeNoIrrigationTimeStop(stopTime);
    },
    [
      noIrrigationTimeStart,
      noIrrigationTimeStop,
      changeNoIrrigationTimeStart,
      changeNoIrrigationTimeStop,
    ]
  );

  return (
    <>
      <PropertiesWithToggle
        disabled={false}
        className="properties-item"
        title={formatMessage({
          id: "controller.properties.interrupt.activate",
        })}
        value={activeController === "plant-et"}
        onChange={async (value) => {
          if (activeController === "interrupt") {
            const result = await showDialog(
              formatMessage({
                id: "controller.properties.controller_activation.popup.text",
              }),
              formatMessage({
                id: "controller.properties.controller_activation.popup.title",
              })
            );

            if (!result) return;
          }

          const etControllerEnabled = value ? "plant-et" : undefined;
          changeActiveController(etControllerEnabled);

          if (!etControllerEnabled) {
            await saveHydrawiseSettings(
              etControllerEnabled,
              circuitAssigned,
              locationName,
              noIrrigationTimeStart,
              noIrrigationTimeStop,
              noIrrigationDays,
              noIrrigationTemperatureLimit,
              noIrrigationTemperatureEnabled,
              noIrrigationWindSpeedLimit,
              noIrrigationWindSpeedEnabled
            );
          }
        }}
      />

      <PropertiesItemWithMultilineInput
        className="properties-item"
        title={formatMessage({
          id: "controller.properties.evapotranspiration.garden_location",
        })}
      >
        <TextInput value={locationName} onChange={changeLocationName} />
      </PropertiesItemWithMultilineInput>

      <PropertiesItemWithMultilineInput
        className="properties-item"
        title={formatMessage({
          id: "controller.properties.evapotranspiration.daily_times_without",
        })}
      >
        <div className="properties-text-with-input">
          <div>
            {formatMessage({
              id: "controller.properties.evapotranspiration.hours.from",
            })}
          </div>
          <TextInput
            value={noIrrigationTimeStart}
            onChange={(value) => {
              const hours = getValidIntegerString(value, 23);

              changeNoIrrigationTimeStart(hours);
            }}
            onBlur={(value) => {
              const hours = getValidHoursString(value);

              changeIrrigationTime("start", hours);
            }}
            maxLength={2}
            placeholder="HH"
            style={{
              maxWidth: "19px",
            }}
          />
          <div>
            {formatMessage({
              id: "controller.properties.evapotranspiration.hours.to",
            })}
          </div>
          <TextInput
            value={noIrrigationTimeStop}
            onChange={(value) => {
              const hours = getValidIntegerString(value, 23);

              changeNoIrrigationTimeStop(hours);
            }}
            onBlur={(value) => {
              const hours = getValidHoursString(value);

              changeIrrigationTime("stop", hours);
            }}
            maxLength={2}
            placeholder="HH"
            style={{
              maxWidth: "19px",
            }}
          />
        </div>
      </PropertiesItemWithMultilineInput>

      <PropertiesWithMultiSelectDropDown
        className="properties-item"
        title={formatMessage({
          id: "controller.properties.evapotranspiration.days_no_irrigation",
        })}
        selectedValues={noIrrigationDays}
        data={days}
        onChange={(values) => {
          changeNoIrrigationDays([...values]);
        }}
      />

      <PropertiesItemWithMultilineInput
        className="properties-item "
        informURL={formatMessage({
          id: "controller.properties.evapotranspiration.valves_settings.inform.link",
        })}
        title={formatMessage({
          id: "controller.properties.evapotranspiration.valves_settings",
        })}
      >
        <PropertiesWithButton
          className="properties-item"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            toggleValvesSettings(true);
          }}
          title={""}
          buttonTitle={formatMessage({
            id: hasValvesSettingsForm.value
              ? "controller.properties.evapotranspiration.close_list"
              : "controller.properties.evapotranspiration.open_list",
          })}
        ></PropertiesWithButton>
      </PropertiesItemWithMultilineInput>

      <PropertiesWithCheckbox
        title={
          <div className="with-inline-input">
            {formatMessage({
              id: "controller.properties.evapotranspiration.no_irrigation_under",
            })}
            :
            <NumberInput
              defaultValue={noIrrigationTemperatureLimit}
              onChange={changeNoIrrigationTemperatureLimit}
              isInteger={true}
              maxLength={2}
            />
            {`°C`}
          </div>
        }
        className="properties-item"
        value={noIrrigationTemperatureEnabled}
        onChange={changeNoIrrigationTemperatureEnabled}
      />

      <PropertiesWithCheckbox
        title={
          <div className="with-inline-input">
            {formatMessage({
              id: "controller.properties.evapotranspiration.no_irrigation_over",
            })}
            :
            <NumberInput
              defaultValue={noIrrigationWindSpeedLimit}
              onChange={changeNoIrrigationWindSpeedLimit}
              isInteger={true}
              maxLength={2}
            />
            {`km/h ${formatMessage({
              id: "controller.properties.evapotranspiration.wind",
            })}`}
          </div>
        }
        className="properties-item"
        value={noIrrigationWindSpeedEnabled}
        onChange={changeNoIrrigationWindSpeedEnabled}
      />
    </>
  );
}

export default EvapotranspirationForm;
