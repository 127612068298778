import { ReactComponent as CloseDisabledSVG } from "../../../../assets/close-disabled.svg";
import { ReactComponent as UnlockSVG } from "../../../../assets/unlock.svg";
import { ReactComponent as LockSVG } from "../../../../assets/lock.svg";
import { ReactComponent as CloseSVG } from "../../../../assets/close.svg";
import { ReactComponent as SaveSVG } from "../../../../assets/save.svg";

import "./properties.scss";
import React, { RefObject } from "react";
import useAutoFocus from "../../../../hooks/useAutoFocus";
import { useUIContext } from "../../../../store/uiStore/UIContext";

function PropertiesWrapper({
  children,
  title,
  ElementIcon,
  elementTitle,
  disabled,
  changeDisabled,
  remove,
  submit,
}: {
  children?: JSX.Element | JSX.Element[];
  title: string | undefined;
  ElementIcon: React.ComponentType<any>;
  elementTitle: string;
  disabled?: boolean;
  changeDisabled?: Function;
  remove?: Function;
  submit?: Function;
}) {
  const { rightSideRef, pageInfoRef } = useUIContext();
  const ref = React.useRef() as RefObject<HTMLFormElement>;

  useAutoFocus(ref);

  React.useEffect(() => {
    const pageInfoNode = pageInfoRef.current;
    const rightSideNode = rightSideRef.current;

    if (pageInfoNode && rightSideNode) {
      const resizeListener = () => {
        const rightSideTop = pageInfoNode.clientHeight + 21;

        rightSideNode.style.top = `${rightSideTop}px`;
        rightSideNode.style.maxHeight = `calc(100% - ${rightSideTop + 48}px)`;
      };
      resizeListener();

      window.addEventListener("resize", resizeListener);
      return () => {
        window.removeEventListener("resize", resizeListener);
      };
    }
  }, [pageInfoRef, rightSideRef]);

  return (
    <div className={`right-side`} ref={rightSideRef}>
      <form
        ref={ref}
        tabIndex={1}
        onSubmit={(e) => {
          e.preventDefault();
          if (typeof submit === "function") submit();
        }}
      >
        <div className="properties-header">
          {title && <strong className="properties-title">{title}</strong>}
          {ElementIcon && (
            <div className="properties-element">
              <div className="element-icon">
                <ElementIcon />
              </div>
              <div className="element-title">{elementTitle}</div>
            </div>
          )}
        </div>

        <div className="properties-separator" />

        <div className="right-side-content properties scroll">
          {(typeof changeDisabled === "function" ||
            typeof remove === "function") && (
            <>
              <div className="properties-menu">
                <button
                  className="tool"
                  type="button"
                  onClick={
                    typeof changeDisabled === "function"
                      ? () => changeDisabled(!disabled)
                      : undefined
                  }
                >
                  {disabled ? <LockSVG /> : <UnlockSVG />}
                </button>
                <button
                  className="tool"
                  type="button"
                  onClick={
                    typeof remove === "function" ? () => remove() : undefined
                  }
                >
                  {disabled ? <CloseDisabledSVG /> : <CloseSVG />}
                </button>
              </div>
              <div className="properties-separator" />
            </>
          )}
          <div className="properties-content">{children}</div>
        </div>

        <div className="right-side-footer">
          <div className="right-side-icon">
            <button type="submit">
              <SaveSVG />
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default PropertiesWrapper;
