import React from "react";
import { useIntl } from "react-intl";

function roundToZero(v: number) {
  const roundedValue = Math.round(v);
  return roundedValue === -0 ? 0 : roundedValue;
}

function useFormatter() {
  const { formatNumber, formatNumberToParts, formatMessage } = useIntl();

  const pressureUnit = formatMessage({ id: "common.units.bar" });
  const weightUnit = formatMessage({ id: "common.units.kg" });

  const decimalSeparator = React.useMemo(() => {
    const parts = formatNumberToParts(9999.99);
    return parts?.find((i) => i?.type === "decimal")?.value ?? ".";
  }, [formatNumberToParts]);

  const formatMoisture = React.useCallback(
    (v: number | undefined, digits: number = 1) => {
      if (v == null) return "--";

      return `${formatNumber(v, {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
      })}%`;
    },
    [formatNumber]
  );

  const formatTemperature = React.useCallback(
    (v: number | undefined) => {
      if (v == null) return "--";

      return `${formatNumber(roundToZero(v))}°C`;
    },
    [formatNumber]
  );

  const formatBattery = React.useCallback(
    (v: number | undefined) => {
      if (v == null) return "--";

      return `${formatNumber(roundToZero(v))}%`;
    },
    [formatNumber]
  );

  const formatPressure = React.useCallback(
    (v: number | undefined, digits: number = 1) => {
      if (v == null) return "--";

      return `${formatNumber(roundToZero(v), {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
      })} ${pressureUnit}`;
    },
    [formatNumber, pressureUnit]
  );

  const formatWeight = React.useCallback(
    (v: number | undefined, digits: number = 1) => {
      if (v == null) return "--";

      return `${formatNumber(v, {
        minimumFractionDigits: 0,
        maximumFractionDigits: digits,
      })} ${weightUnit}`;
    },
    [formatNumber, weightUnit]
  );

  return {
    formatMoisture,
    formatTemperature,
    formatBattery,
    formatPressure,
    formatWeight,
    decimalSeparator,
  };
}

export default useFormatter;
