import React from "react";

import { svgTextCondensed } from "@dvsproj/ipat-core/planUtils";
import TrenchInterface from "../../../../../store/interface/TrenchInterface";
import ElementWrapper from "../common/ElementWrapper";

function Trench({
  element,
  selected,
  select,
  lineVisible,
  countVisible,
  trenchLine = false,
  trenchBorder = false,
}: {
  element: TrenchInterface;
  selected?: boolean;
  select?: Function | undefined;
  trenchLine?: boolean | undefined;
  lineVisible?: boolean | undefined;
  countVisible?: boolean | undefined;
  trenchBorder?: boolean | undefined;
}) {
  const { id, path, pipes, centerPoint } = element;
  if (path == null) return null;

  const rectSize = 20;
  const fontSize = 10;
  return (
    <g
      id={id}
      className={`element trench ${
        typeof select === "function" ? "clickable" : undefined
      }`}
      onClick={
        typeof select === "function"
          ? (e) => {
              e.preventDefault();
              e.stopPropagation();
              select();
            }
          : undefined
      }
      fill="#fff"
      stroke="#3e4346"
      strokeWidth={1}
    >
      {lineVisible && (
        <path
          d={path}
          stroke="#000"
          strokeWidth={4}
          fill="none"
          className="active"
        />
      )}
      {trenchLine && (
        <path
          d={path}
          stroke="#eeeeee"
          strokeWidth={4}
          fill="none"
          className="active"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
      {trenchBorder && (
        <path
          d={path}
          stroke="#3e4245"
          strokeWidth={5}
          fill="none"
          className="active"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
      {countVisible && centerPoint != null && (
        <ElementWrapper
          id={`count-block-${id}`}
          fill={selected ? "#c5d75d" : "#fff"}
          x={centerPoint.x}
          y={centerPoint.y}
          size={rectSize}
          select={select}
          //selected={selected}
          className="trench"
          useBorder={false}
        >
          <rect
            stroke="#151517"
            x={0}
            y={0}
            width={rectSize}
            height={rectSize}
          ></rect>
          <text
            stroke="#151517"
            fill="#151517"
            x={rectSize / 2 - (pipes ? pipes.length + "" : "0").length * 3}
            y={rectSize - (rectSize - fontSize) / 2}
            className={`trench-info`}
            textAnchor="start"
            {...svgTextCondensed}
          >
            {pipes ? pipes.length : 0}
          </text>
        </ElementWrapper>
      )}
    </g>
  );
}

export default Trench;
