import React from "react";
import { useNavigate } from "react-router-dom";
import iframeApi from "../service/iframeApi";

function useRedirect() {
  const navigate = useNavigate();

  const redirect = React.useCallback(
    (to: string, replace: boolean = false, parentRedirect: boolean = false) => {
      if (window.self !== window.top && parentRedirect) {
        iframeApi.redirectTo(to, { replace });
      } else {
        if (/^https?/.test(to)) {
          window.location.href = to;
        } else {
          navigate(to, { replace });
        }
      }
    },
    [navigate]
  );

  return redirect;
}

export default useRedirect;
