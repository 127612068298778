import { v4 as uuid } from "uuid";

import { lineLength } from "@dvsproj/ipat-core/geometryUtils";
import TrenchInterface from "../interface/TrenchInterface";
import PipelineInterface from "../interface/PipelineInterface";

function trenchFactory(
  {
    start,
    end,
    pipes = [],
  }: {
    start: { x: number; y: number };
    end: { x: number; y: number };
    pipes: any[];
  },
  pipelines: PipelineInterface[]
): TrenchInterface {
  const isValid = start != null && end != null && lineLength(start, end) > 0;
  const pipesCount = pipes && pipes.length > 0 ? pipes.length : 0;
  return {
    id: "trench-" + uuid(),
    start,
    end,
    pipes,
    get isValid() {
      return isValid;
    },
    get centerPoint() {
      return isValid
        ? {
            x: (start.x + end.x) / 2,
            y: (start.y + end.y) / 2,
          }
        : null;
    },
    get pipesCount() {
      return pipesCount;
    },
    get colors() {
      return isValid && pipesCount > 0
        ? pipes.map((el) => (el.color ? el.color : "#9EA1A2"))
        : [];
    },
    get pipesForPopup() {
      const obj =
        isValid && pipesCount > 0
          ? pipes
              .map((el) => {
                const circuit = pipelines.find(
                  (p) =>
                    p.lines != null &&
                    p.lines.find((l) => l.id === el.id) != null
                );
                return {
                  id: el.id,
                  circuitId: circuit?.id,
                  color: el.color ?? "#9EA1A2",
                };
              })
              .reduce((acc, cur) => {
                (acc[cur.color] = acc[cur.color] || []).push(cur.circuitId);
                return acc;
              }, {})
          : {};
      return Object.keys(obj).map((key) => {
        return { color: key, ids: obj[key] };
      });
    },
    get path() {
      if (!isValid) return undefined;
      return "M " + start.x + " " + start.y + " L " + end.x + " " + end.y;
    },
    get length() {
      return lineLength(start, end, null);
    },
  };
}

export default trenchFactory;
