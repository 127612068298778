import { ReactComponent as SensorOkSVG } from "../../../../assets/sensor-ok.svg";
import { ReactComponent as SensorMuchWaterSVG } from "../../../../assets/sensor-much-water.svg";
import { ReactComponent as SensorNoDataSVG } from "../../../../assets/sensor-no-data.svg";
import { ReactComponent as SensorLittleWaterSVG } from "../../../../assets/sensor-little-water.svg";

const TEXT_BY_STATUS = {
  ok: {
    Icon: SensorOkSVG,
    text: { id: "sensor.status.ok" },
    color: "#c5d75d",
  },
  "much-water": {
    Icon: SensorMuchWaterSVG,
    text: { id: "sensor.status.much-water" },
    color: "#00c4ff",
  },
  nok: {
    Icon: (props: any) => (
      <SensorNoDataSVG style={{ transform: "translateY(-6px)" }} {...props} />
    ),
    text: { id: "sensor.status.nok" },
    color: "#c3c3c3",
  },
  "little-water": {
    Icon: SensorLittleWaterSVG,
    text: { id: "sensor.status.little-water" },
    color: "#ff7600",
  },
};

export default TEXT_BY_STATUS;
