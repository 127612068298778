import { ReactComponent as AirCompressorSVG } from "../../../../../assets/air-compressor.svg";
import { ReactComponent as CombiBoxSVG } from "../../../../../assets/combi-box.svg";
import { ReactComponent as WaterFilterSVG } from "../../../../../assets/water-filter.svg";
import { ReactComponent as WaterSupplySVG } from "../../../../../assets/water-supply.svg";
import { ReactComponent as WaterMeterSVG } from "../../../../../assets/water-meter.svg";
import { ReactComponent as ValveBoxSVG } from "../../../../../assets/valvebox.svg";
import { ReactComponent as WaterTapSVG } from "../../../../../assets/water-tap.svg";
import { ReactComponent as FertilizerSVG } from "../../../../../assets/fertilizer.svg";
import { ReactComponent as RzwsSVG } from "../../../../../assets/tree-point.svg";
import { ReactComponent as RaisedBedSVG } from "../../../../../assets/raised-bed.svg";
import { ReactComponent as DriplinePointSVG } from "../../../../../assets/dripline_connection_point.svg";

import ElementWrapper from "../common/ElementWrapper";

function DefaultPoint({
  color,
  width,
}: {
  color?: string | undefined;
  width?: number | string | undefined;
}) {
  const size = width ? +width : 20;
  return (
    <g fill={color} stroke="none" strokeWidth={0} className={`pipe-point-elem`}>
      <circle
        cx={size / 2}
        cy={size / 2}
        r={5.5}
        stroke="none"
        strokeWidth={0}
      />
    </g>
  );
}

function SprinklerPoint({
  color,
  width,
}: {
  color?: string | undefined;
  width?: number | string | undefined;
}) {
  const size = width ? +width : 20;
  return (
    <g fill={color} stroke="none" strokeWidth={0} className={`pipe-point-elem`}>
      <circle
        className="pipe-point-bg-elem"
        cx={size / 2}
        cy={size / 2}
        r={8}
        stroke="none"
        strokeWidth={0}
        fill="#000"
      />
      <circle cx={size / 2} cy={size / 2} r={7} stroke="none" strokeWidth={0} />
      <circle
        cx={size / 2}
        cy={size / 2}
        r={1}
        fill={"#fff"}
        stroke="none"
        strokeWidth={0}
      />
    </g>
  );
}

function PipePoint({
  id,
  x,
  y,
  pointType,
  color,
  selected,
  select,
}: {
  id: number | string;
  x: number;
  y: number;
  pointType: string;
  color?: string | undefined;
  selected?: boolean;
  select?: Function | undefined;
}) {
  let ElementIcon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { color?: string | undefined }
  > = DefaultPoint;
  let size = 20;
  switch (pointType) {
    case "start-point":
    case "valve-box":
      ElementIcon = ValveBoxSVG;
      break;
    case "water-supply":
      ElementIcon = WaterSupplySVG;
      break;
    case "water-tap-point":
      ElementIcon = WaterTapSVG;
      size = 16;
      break;
    case "fertilizer":
      ElementIcon = FertilizerSVG;
      break;
    case "air-compressor":
      ElementIcon = AirCompressorSVG;
      break;
    case "water-filter":
      ElementIcon = WaterFilterSVG;
      break;
    case "combi-box":
      ElementIcon = CombiBoxSVG;
      break;
    case "water-meter":
      ElementIcon = WaterMeterSVG;
      break;
    case "dripline-point":
      ElementIcon = DriplinePointSVG;
      size = 16;
      break;
    case "rzws-point":
      ElementIcon = RzwsSVG;
      break;
    case "raised-bed-point":
      ElementIcon = RaisedBedSVG;
      break;
    case "sprinkler-point":
      ElementIcon = SprinklerPoint;
      size = 16;
      break;
    default:
      ElementIcon = DefaultPoint;
      size = 9;
      break;
  }
  return (
    <ElementWrapper
      id={id}
      x={x}
      y={y}
      size={size}
      select={select}
      selected={selected}
      useBorder={false}
    >
      <ElementIcon width={size} height={size} color={color} />
    </ElementWrapper>
  );
}

function PipePoints({
  points,
  color,
  selected,
  select,
}: {
  points?: {
    id: number | string;
    x: number;
    y: number;
    pointType: string;
  }[];
  color?: string | undefined;
  selected?: boolean;
  select?: Function | undefined;
}) {
  if (points?.length === 0) return null;

  return (
    <>
      {points!.map((point) => (
        <PipePoint
          key={point.id}
          id={point.id}
          x={point.x}
          y={point.y}
          pointType={point.pointType}
          color={color}
          selected={selected}
          select={select}
        />
      ))}
    </>
  );
}

export default PipePoints;
