import React from "react";
import { TooltipProvider } from "./components/tooltip/Tooltip";
import IntlProvider from "./locales/IntlProvider";
import Main from "./pages/main/Main";

import "./styles/app.scss";
import { cookies } from "./utils/ui.utils";
import { DialogContextProvider } from "./pages/layouts/popup/dialog/DialogContext";

function App() {
  const { language } = cookies();
  const locale = language?.replace("_", "-") ?? window?.navigator?.language;
  return (
    <TooltipProvider>
      <IntlProvider locale={locale}>
        <DialogContextProvider>
          <Main />
        </DialogContextProvider>
      </IntlProvider>
    </TooltipProvider>
  );
}

export default App;
