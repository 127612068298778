import { usePlanStore } from "../../../../../store/PlanStore";

import { ReactComponent as AirCompressorSVG } from "../../../../../assets/air-compressor.svg";
import ElementWrapper from "../common/ElementWrapper";

function AirCompressor({
  id,
  x,
  y,
}: {
  id: number | string;
  x: number;
  y: number;
}) {
  return (
    <ElementWrapper id={id} x={x} y={y} size={20}>
      <AirCompressorSVG width={20} height={20} />
    </ElementWrapper>
  );
}

function AirCompressorElements() {
  const { data } = usePlanStore();

  const waterTaps =
    data?.elements.filter(
      (e) => e.type === "system-element" && e.systemType === "air-compressor"
    ) ?? [];

  if (waterTaps.length === 0) return null;

  return (
    <>
      {waterTaps.map((waterTap) => (
        <AirCompressor
          key={waterTap.id}
          id={waterTap.id}
          x={waterTap.x}
          y={waterTap.y}
        />
      ))}
    </>
  );
}

export default AirCompressorElements;
