import { RefObject, useEffect } from "react";

/**
 * React hook to execute a callback function on click outside of the container
 *
 * @param {*} ref - ref to container element. Should be a DOM Node.
 * @param {*} callback -
 */
export const useOuterClickEffect = (
  containerNode: any | undefined,
  ref: RefObject<any> | undefined,
  callback: Function
) => {
  useEffect(() => {
    const elementNode = ref?.current;

    if (containerNode && elementNode) {
      const clickHandler = (e: any) => {
        if (!elementNode.contains(e.target)) {
          callback(e);
        }
      };

      containerNode.addEventListener("click", clickHandler, true);
      return () => {
        containerNode.removeEventListener("click", clickHandler, true);
      };
    }
  });
};
